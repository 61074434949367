import React from 'react'
import styled from '@emotion/styled'

import Row from '../../common/Row'
const Container = styled(Row)`
    margin:-12px -16px -12px -40px;
    padding:12px 16px 12px 40px;
    background-color:${props=>props.theme.darkbluegrey};
    color:${props=>props.theme.white};
    border-radius: 4px 4px 0px 0px;
`
const ChangeClusterHeader = ({label}) => {
    return (
        <Container>{label}</Container>
    )
}
export default ChangeClusterHeader