import React from 'react'
import styled from '@emotion/styled'

import PopulationGraph from '../../containers/PopulationGraph'
import Subtitle from '../../common/Subtitle'

const Container = styled('div')`
  padding: 0.75rem;
  margin: 0 1rem;
  border: 1px solid #e0e0e0;
  background-color:${props => props.theme.white};
`

const PopulationForecast = () => {

  return (
    <Container>
      <Subtitle>
        Population forecast
      </Subtitle>
        <PopulationGraph legendWidth={100} direction={'row'} margin={{ left: 60, bottom: 30, right: 30 }} width={360} height={200} orientation="vertical"/>
    </Container>
  )
}

export default PopulationForecast