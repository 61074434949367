import React, { Component } from 'react'
import MapToolbox from '../../containers/MapToolbox'
import Map from '../../containers/Map'
import ModelContainer from '../../containers/ModelContainer'
import { Redirect } from 'react-router-dom'

class Dashboard extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: this.props.isAuthenticated,
      userHasAuthenticated: this.props.userHasAuthenticated,
      handleLogout: this.props.handleLogout,
    };
  }

  componentWillMount() {
    this.setState({ isAuthenticated: localStorage.getItem("isAuthenticated") === "true" });
  }

  render() {

    const { isAuthenticated } = this.state;

    if (isAuthenticated) {
      return (
        <>
          <MapToolbox/>
          <Map/>
          <ModelContainer/>
        </>
      )
    } else {
      return <Redirect to={"/login"}></Redirect>;
    }
  }
}

export default Dashboard
