import React from 'react'
import styled from '@emotion/styled'
import { Tooltip } from 'antd'
import IconButtonMini from '../../../common/IconButtonMini'
import PopulationGraph from '../../../containers/PopulationGraph'
import Subtitle from '../../../common/Subtitle'

const Container = styled('div')`
  border-radius: 4px;
  padding: 0.75rem;
  margin: 1rem 0;
  background-color: ${props => props.theme.white};
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.25);
`
const ChartContainer = styled('div')`
  background: #f8f8f8;
  border: 1px solid #e0e0e0;
  padding: 1rem;
  margin-top: 1rem;
`
const PopulationForecast = () => {
  return (
    <Container>
      <Subtitle>
        Population forecast
        <Tooltip title={'Source: WA Tomorrow'}>
          <IconButtonMini shape={'circle'} icon="info-circle" size={'small'} />
        </Tooltip>
      </Subtitle>
      <ChartContainer>
        <PopulationGraph legendWidth={200} direction={'column'} margin={{ left: 60, bottom: 30, right: 30 }} width={300} height={250} orientation="horizontal" />
      </ChartContainer>
    </Container>
  )
}

export default PopulationForecast
