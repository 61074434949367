import React, { PureComponent } from 'react'
import styled from '@emotion/styled'

import ContextLegend from './ContextLegend'
import TTLegend from '../../containers/Legend'
import PatronageLegend from '../PatronageLegend'

const Container = styled('div')`
  padding: 1rem;
`
class Legend extends PureComponent {

    render() {
        const { legendData, modelName, contextLegend, showContextLayer, showEmploymentLayer } = this.props
        return (
            <Container>
                <PatronageLegend/>
                { legendData && modelName !== 'traveltime' && showEmploymentLayer && <ContextLegend data={legendData} /> }
                { contextLegend.items !== null && showContextLayer && <ContextLegend data={contextLegend} /> }
                { modelName === 'traveltime' && <TTLegend /> }
            </Container>
        )
    }
}
export default Legend
