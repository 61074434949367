import React from 'react'
import styled from '@emotion/styled'
import { NavLink } from 'react-router-dom'

import statisicalImg from '../../assets/statisical.png'
import scenariosImg from '../../assets/scenarios.png'
import cbaImg from '../../assets/cba.png'

const Container = styled('div')`
  padding: 1rem 2rem 0.5rem 2rem;
  background-color: ${props => props.theme.lightgrey};
`
const PanelContainer = styled('div')`
  text-align: center;
  margin: 1rem;
`
const DescriptiveText = styled('span')`
  text-decoration: underline;
  font-size: 14px;
  font-weight: 600;
  color: ${ props => props.theme.black};
`
const ExtraDescriptiveText = styled(DescriptiveText)`
  font-style: italic;
`
const LogoImg = styled('img')`
  height: 140px;
  text-align: center;
  margin: 1rem auto;
  box-shadow: 0px 2px 4px 0px ${props => props.theme.slightlydarkergrey};
`
const AboutText = styled('div')`
  font-size: 0.75rem;
`
const FixCarousel = styled('div')`
  display: flex;
`
const modes = [
  { 
    title: 'Metropolitan Analysis',
    desc: 'In order to model employment numbers, the design parameters and ' + 
      'patronage numbers for the new stations, a detailed analysis of all of ' +
      'the railway stations in the metropolitan area was undertaken, making ' +
      'use of five detailed analytical models and broader GIS analysis.',
    text: 'Explore statistical models', 
    src: statisicalImg, 
    link: '/', 
    alt: 'statisical' 
  },
  { 
    title: 'Scenario Builder',
    desc: 'The system allows a user to test numerous future development ' +
      'options by choosing which existing Perth station you would like a ' +
      'future station to emulate. The required design parameters to achieve ' +
      'this are listed, as well as the future population and patronage ' +
      'numbers. The scenario builder also allows for detailed employment ' +
      'modelling.',
    text: 'Build future scenarios', 
    src: scenariosImg, 
    link: '/planner', 
    alt: 'scenarios' 
  },
  { 
    title: 'Cost-Benefit Analysis Tool',
    desc: 'This tool quantifies the anticipated economic, environmental and ' +
      'health impacts of either a travel behaviour change project or an ' +
      'active transport infrastructure investment.',
    text: 'Explore Cost Benefit analysis', 
    src: cbaImg, 
    link: '/cba', 
    alt: 'cba' 
  }
]

const AboutGetStarted = ({ close }) => {
  return (
    <Container>
      <FixCarousel>
        {
          modes.map(mode => {
            const { title, desc, text, src, link, alt } = mode
            return (
              <PanelContainer key={text}>
                <NavLink exact to={link}>
                  <DescriptiveText>{title}:{' '}</DescriptiveText>
                </NavLink>
                <br />
                <NavLink exact to={link}>
                  <ExtraDescriptiveText>{text}</ExtraDescriptiveText>
                </NavLink>
                <LogoImg src={src} alt={alt} />
                <AboutText>{desc}</AboutText>
              </PanelContainer>
            )
          })
        }
      </FixCarousel>
    </Container>
  )
}

export default AboutGetStarted