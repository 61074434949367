import {
    MapToolBox
} from '../constants/actionTypes'

const {
    SET_MODE
} = MapToolBox

export const initialState = {
    modes: ['Employment', 'Context'],
    selectedMode: 'Employment'
}

export default function (state = initialState, {
    type,
    payload
}) {
    switch (type) {
        case SET_MODE:
            {
                return {
                    ...state,
                    selectedMode: payload    
                }
            }
        default:
            return state
    }
}