import { Hubs } from '../constants/actionTypes'
import { queryHubStops } from '../queries/search'
import { queryHubPatronage } from '../queries/hubs'
import datamodel from '../data/datamodel'

const { SELECT_HUB, ADD_STOPS, LOAD_HUBS } = Hubs

export const loadAllHubPatronage = () => {
  return dispatch => {
    queryHubPatronage()
      .then(res => res.json())
      .then(data => dispatch(loadHubs(linkDataModel(data))))
      .catch(err => console.log(err))
  }
}
export const loadAllHubData = payload => {
  return dispatch => {
    dispatch(selectHub(payload))
    queryHubStops({
      search: payload,
      withstops: true,
    })
      .then(res => res.json())
      .then(geo => dispatch(addStops(geo)))
      .catch(err => console.log(err))
  }
}
export const selectHub = payload => {
  return dispatch => {
    dispatch(chooseHub(payload))
  }
}
export const addStops = payload => ({
  type: ADD_STOPS,
  payload,
})
export const chooseHub = payload => ({
  type: SELECT_HUB,
  payload,
})
export const loadHubs = payload => ({
  type: LOAD_HUBS,
  payload,
})

function linkDataModel(patronageData) {
  return datamodel
    .map(f => {
      const patronage = patronageData.find(d => d.ID === f.ID)
      return {
        ...f,
        patronage: patronage ? +patronage.Stays : null,
      }
    })
    .sort((a, b) => b.patronage - a.patronage)
}
