import React, { Component } from "react";
import styled from '@emotion/styled';
import ReactMapboxGl from 'react-mapbox-gl';

import { Attribution } from '../Map/CommonComponents';

import mapStyle from '../../config/map';

const ReactMap = ReactMapboxGl({
  accessToken: null,
  maxZoom: 15
})

const AuthenticationContainer = styled('div')`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
`

const AuthenticationInnerContainer = styled('div')`
  max-width: 25rem;
  margin: 2rem;
  z-index: 2;
`

const AuthenticationMapContainer = styled('div')`
  z-index: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`

const AuthenticationMapCover = styled('div')`
  z-index: 1;
  position: absolute;
  left: 0;
  top 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
`

export class AuthenticationMap extends Component {
    render() {
        return (
          <>
            <AuthenticationMapContainer>
              <ReactMap style={mapStyle} center={[115.82184, -31.99749]}
                zoom={[10]} containerStyle={{ height: '100%', width: '100%' }} 
                movingMethod="flyTo" onStyleLoad={map => {map.flyTo(
                { center: [115.82184, -31.99749], zoom: 10, 
                speed: 0.1 });}} />
            </AuthenticationMapContainer>
            <AuthenticationMapCover />
            <Attribution />
          </>
        )
    }
}

export default AuthenticationContainer;
export { AuthenticationContainer, AuthenticationInnerContainer }