import React from 'react'
import styled from '@emotion/styled'
import * as isEqual from 'lodash.isequal'

import Row from '../../common/Row'
import Column from '../../common/Column'
import Subtitle from '../../common/Subtitle'
import Heading from '../../common/Heading'

const Container = styled('div')`
    padding: 2rem;
    width: 300px;
    height: 250px;
    margin-bottom: 1rem;
    background-color:${props => props.theme.white};
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.19);
    border-radius: 4px;
`
const SubtitleContainer = styled(Subtitle)`
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #354052;
    margin-bottom: 2rem;
`
const RowContainer = styled(Row)`
    margin-bottom: 0.5rem;
`
const LabelContainer = styled(Subtitle)`
    flex-grow: 1;
`
const ValueContainer = styled(Heading)`
    color:${ props => props.theme[props.color] };
`
const ResultRow = ({label, value, color, prefix, suffix}) => {
    return (
        <RowContainer>
            <LabelContainer>{label}</LabelContainer>
            <ValueContainer color={color}>{prefix}{value}{suffix}</ValueContainer>
        </RowContainer>
    )
}
const Results = ({currentParams, submittedParams, results}) => {
    return (
        <Container>
            <SubtitleContainer>Results</SubtitleContainer>
            {results && isEqual(currentParams, submittedParams) && <Column>
                <ResultRow label={'Total cost ($2018)'} prefix={'$'} suffix={'m'} value={(results.costs/1000000).toFixed(2)} color={'topaz'} />
                <ResultRow label={'Total benefits ($2018)'} prefix={'$'} suffix={'m'} value={(results.benefits/1000000).toFixed(2)} color={'topaz'} />
                <ResultRow label={'BCR'} prefix={''} suffix={''} value={results.bcr.toFixed(2)} color={'black'} />
                <ResultRow label={'NPV ($2018)'} prefix={'$'} suffix={'m'} value={(results.npv/1000000).toFixed(2)} color={'black'} />
            </Column>}
        </Container>
    )
}
export default Results