import React from 'react'
import styled from '@emotion/styled'
import { Radio } from 'antd'
import RadioButton from '../../common/RadioButton'
import MiniTitle from '../../common/MiniTitle'

const Container = styled('div')`
  padding: 1rem 0;
`
const RadioGroupContainer = styled(Radio.Group)`
  display: flex;
`
const LocationComparisonAreas = ({ onChange, options, selected }) => {
  const change = e =>
    onChange({ key: 'selectedComparisonArea', value: e.target.value })
  return (
    <Container>
      <MiniTitle>Location quotient comparison area</MiniTitle>
      <RadioGroupContainer value={selected} onChange={change}>
        {options.map(f => (
          <RadioButton key={f} value={f}>
            {f}
          </RadioButton>
        ))}
      </RadioGroupContainer>
    </Container>
  )
}

export default LocationComparisonAreas
