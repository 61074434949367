import { connect } from 'react-redux'
import ModelContainer from '../components/ModelContainer'

import { addModel, toggleShow } from '../actions/modelcontainer'
import {
  selectorShowAddBtn,
  selectorShowContainer,
} from '../selectors/modelContainer-selector'
export default connect(
  state => {
    return {
      show: selectorShowContainer(state),
      models: state.modelcontainer.models,
      showAddBtn: selectorShowAddBtn(state),
    }
  },
  {
    addModel,
    toggleShow,
  }
)(ModelContainer)
