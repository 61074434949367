import { Accessibility } from '../constants/actionTypes'
const {
  SET_SA2_TRAVELTIME,
  SET_COMPARISON_DATA,
  UPDATE_PARAMS,
  SET_METRIC_STAGING,
  SET_DIRECTION_STAGING,
  SET_TIME_STAGING,
  SET_ACCESS_DATA,
  SET_MODE_STAGING,
  SET_PERIOD_STAGING,
} = Accessibility

export const initialState = {
  sa2traveltime: null,
  comparisonData: null,
  stagingDirection: 'inbound',
  direction: null,
  stagingTime: 120,
  time: null,
  stagingMetric: 'Jobs',
  metric: null,
  showLayer: true,
  ttSelectedHub: null,
  stagingMode: 'pt',
  travel_mode: null,
  stagingPeriod: 'am',
  period: null,
}

export default function(state = initialState, { type, payload }) {
  switch (type) {
    case SET_SA2_TRAVELTIME: {
      const { selectedHub } = payload
      return {
        ...state,
        ttSelectedHub: selectedHub,
      }
    }
    case SET_COMPARISON_DATA: {
      return {
        ...state,
        comparisonData: payload,
      }
    }
    case SET_ACCESS_DATA: {
      return {
        ...state,
        accessData: payload,
        loading: false,
      }
    }
    case SET_METRIC_STAGING: {
      return {
        ...state,
        stagingMetric: payload,
      }
    }
    case SET_MODE_STAGING: {
      if (payload === 'pt') {
        return {
          ...state,
          stagingPeriod: 'am',
          stagingMode: payload,
        }
      } else {
        return {
          ...state,
          stagingMode: payload,
        }
      }
    }

    case SET_PERIOD_STAGING: {
      return {
        ...state,
        stagingPeriod: payload,
      }
    }
    case SET_DIRECTION_STAGING: {
      return {
        ...state,
        stagingDirection: payload,
      }
    }
    case SET_TIME_STAGING: {
      return {
        ...state,
        stagingTime: payload,
      }
    }
    case UPDATE_PARAMS: {
      const {
        stagingTime,
        stagingDirection,
        stagingMetric,
        stagingMode,
        stagingPeriod,
      } = state
      return {
        ...state,
        time: stagingTime,
        direction: stagingDirection,
        metric: stagingMetric,
        travel_mode: stagingMode,
        period: stagingPeriod,
      }
    }
    default:
      return state
  }
}
