import { MapToolBox } from '../constants/actionTypes'

const {
    SET_MODE
} = MapToolBox

export const setMode= payload => ({
  type: SET_MODE,
  payload,
})
