import { connect } from 'react-redux'
import StopsLayer from '../components/Layers/StopsLayer'
import { selectorStops } from '../selectors/stopslayer-selector'
export default connect(
  state => {
    return {
      data: selectorStops(state.hubs),
    }
  },
  {}
)(StopsLayer)
