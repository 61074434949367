import { connect } from 'react-redux'
import Search from '../components/Search'

import {
  handleSearch,
  getResults,
  getResultsFailure,
  getResultsSuccess,
} from '../actions/search'
import { loadAllHubData } from '../actions/hub'
import { selectorSearchterm } from '../selectors/search-selector'

export default connect(
  state => {
    return {
      allresults: state.search.allresults,
      fetching: state.search.fetching,
      searchterm: selectorSearchterm(state),
      // searchterm: state.search.searchterm? state.search.searchterm : state.hubs.selectedHub.SmartRider_StopName,
      hub: state.search.hub ? state.search.hub : state.hubs.selectedHub,
    }
  },
  {
    handleSearch,
    getResults,
    getResultsSuccess,
    getResultsFailure,
    selectHub: loadAllHubData,
  }
)(Search)
