import React, { Component } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import Logo from './Logo'
import Navigation from './Navigation'

import Row from '../../common/Row'

const HeaderContainer = styled('header')`
  position: absolute;
  top: 0;
  left: 0;
  height: 59px;
  width: 100%;
  z-index: 10;
  background-color: ${props => props.theme.darkbluegrey};
`
const Container = styled(Row)`
  padding: 0 1rem 0 2rem;
  height: 100%;
  width: 100%;
`
class Header extends Component {

  toggleAbout = () => this.props.toggleAbout()
  toggleMenu = () => this.props.toggleMenu()

  render() {
    const { about, menu } = this.props

    const childProps = {
      isAuthenticated: this.props.childProps.isAuthenticated,
      userHasAuthenticated: this.props.childProps.userHasAuthenticated,
      handleLogout: this.props.childProps.handleLogout
    };

    return (
      <HeaderContainer>
        <Container>
            <Logo />
            <Navigation toggleMenu={this.toggleMenu} 
              toggleAbout={this.toggleAbout} 
              about={about}
              menu={menu}
              childProps={childProps}/>
        </Container>
      </HeaderContainer>
    )
  }
}


Header.propTypes = {
  toggleMenu: PropTypes.func.isRequired,
  toggleAbout: PropTypes.func.isRequired,
  about: PropTypes.bool.isRequired,
  menu: PropTypes.bool.isRequired,
}


export default Header
