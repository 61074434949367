import { connect } from 'react-redux'
import YourMove from '../components/YourMove'
import { setYourMoveParams } from '../actions/costbenefitanalysis'
export default connect(
  state => {
    return {
      currentParams: state.costbenefitanalysis.yourMove   
    }
  },
  { setYourMoveParams }
)(YourMove)
