import params from '../config/indicators'
const chartRadius = 110
const createRadialData = ({
    node_params,
    place_params,
    nodeColors,
    placeColors
}) => {
    const chartRadius = 110

    const nodeAngle = toRad(180 / params.node.length)
    const nodeData = params.node.map((f, i) => ({
        angle0: i * nodeAngle,
        angle: (i + 1) * nodeAngle,
        radius: node_params[f.id] * chartRadius,
        radius0: 0,
        color: nodeColors.color,
        stroke: nodeColors.stroke,
        name: f.name,
        type: 'node',
    }))

    const placeAngle = toRad(180 / params.place.length)
    const placeData = params.place.map((f, i) => ({
        angle0: i * placeAngle + toRad(180),
        angle: (i + 1) * placeAngle + toRad(180),
        radius: place_params[f.id] * chartRadius,
        radius0: 0,
        color: placeColors.color,
        stroke: placeColors.stroke,
        name: f.name,
        type: 'place',
    }))

    return [...nodeData, ...placeData]
}
const createGuides = ({
    radialData
}) => {
    const outerRing = [{
        angle0: 0,
        angle: toRad(360),
        color: 'transparent',
        radius: chartRadius,
    }, ]
    const segments = radialData.map(f => {
        return {
            angle0: f.angle0,
            angle: f.angle,
            radius: chartRadius,
            color: 'transparent',
        }
    })
    return {
        outerRing,
        segments
    }
}
const createNodePlaceLabels = ({
    radialData
}) => {
    const labelsNode = createLabels(
        radialData.filter(f => f.type === 'node'),
        90
    )
    const labelsPlace = createLabels(
        radialData.filter(f => f.type === 'place'),
        -90
    )
    return {
        labelsNode,
        labelsPlace
    }
}

function createLabels(data, offset) {
    return data.map(f => {
        const angle = (f.angle - f.angle0) / 2 + f.angle0
        const x = Math.sin(angle) * 1
        const y = Math.cos(angle) * 1
        const degrees = toDegrees(angle)
        const rotation = degrees - offset //degrees > 90 && degrees < 270? degrees + 180 : degrees
        return {
            label: f.name[0],
            x,
            y,
            rotation,
        }
    })
}

function toRad(deg) {
    return (deg * Math.PI) / 180
}

function toDegrees(radians) {
    return (radians * 180) / Math.PI
}
export default {
    createRadialData,
    createGuides,
    createNodePlaceLabels
}