import { createSelector } from 'reselect'
import { selectorClusterType } from './nodeplace-selector'
import indicatorChartUtil from '../utils/indicatorchart'
import legendConfig from '../config/legend'

const { legendBehaviours, legendNodePlace } = legendConfig

const allHubsSelector = state => state.hubs.data
const lgaSelector = state => state.scenarioplanner.lgaCode
const originSelector = state => state.scenarioplanner.originCluster
const targetSelector = state => state.scenarioplanner.targetCluser
const nodeplaceSelector = state => state.nodeplace.results
const behaviourDataSelector = state => state.behaviour.data
const currentPopSelector = state =>
  state.population.medium[state.scenarioplanner.lga]
const structurePlanSelector = state => state.scenarioplanner.structureplans
const structurePlanPopSelector = state => state.scenarioplanner.structurePlanPop
const occupancySelector = state => state.scenarioplanner.occupancyRate
const bufferSelector = state => state.scenarioplanner.stnBuffers
const targetStationSelector = state => state.scenarioplanner.targetStation
const existingStationSelector = state => state.scenarioplanner.existingStation
const workingPopSelector = state => state.scenarioplanner.workingPop

// create the cluster list based on the selected LGA
export const selectorClusters = createSelector(
  allHubsSelector,
  lgaSelector,
  (data, LGA) => {
    if (!data || data.length === 0) return null
    return data
      .filter(f => f.LGA === LGA && f.hasOwnProperty('NodePlace_Station'))
      .sort((a, b) => a.NodePlace_HubID > b.NodePlace_HubID && -1)
  }
)

// auto select a cluster for target and origin
const selectorOrigin = createSelector(
  selectorClusters,
  originSelector,
  (clusters, origin) => {
    if (!clusters) return null
    if (origin) {
      return clusters.find(f => f.NodePlace_HubID === origin.id)
    } else {
      return clusters[0]
    }
  }
)
const selectorTarget = createSelector(
  allHubsSelector,
  targetSelector,
  selectorOrigin,
  targetStationSelector,
  (hubs, target, origin, targetStation) => {
    if (!hubs) return null
    if (
      !(targetStation && origin && targetStation[origin.NodePlace_Station]) &&
      target
    ) {
      return hubs.find(f => f.NodePlace_HubID === target.id)
    } else if (
      origin &&
      targetStation &&
      targetStation[origin.NodePlace_Station] &&
      target &&
      target.id === origin.NodePlace_HubID
    ) {
      return hubs.find(
        f => f.NodePlace_HubID === targetStation[origin.NodePlace_Station].id
      )
    } else if (target) {
      return hubs.find(f => f.NodePlace_HubID === target.id)
    } else {
      return origin
    }
  }
)
// set up all the data that needs to be sent for each origin and target cluster
const getClusterType = (data, id) => data.find(f => f && +f.id === id)
export const selectorOriginTargetCluster = createSelector(
  selectorOrigin,
  selectorTarget,
  selectorClusterType,
  (originRaw, targetRaw, clusterType) => {
    if (
      !originRaw ||
      !targetRaw ||
      !clusterType ||
      !clusterType.hasOwnProperty('data') ||
      clusterType.data.length === 0
    )
      return null
    const origin = getClusterType(clusterType.data, originRaw.NodePlace_HubID)
    const target = getClusterType(clusterType.data, targetRaw.NodePlace_HubID)
    return {
      origin,
      target,
      clusterType,
    }
  }
)
const getNodePlace = (data, id) => data.find(f => +f.ID === id)
const createRadialData = ({ nodeplace, color, stroke }) => {
  const { node_params, place_params } = nodeplace
  const nodeColors = {
    color,
    stroke,
  }
  const placeColors = {
    color,
    stroke,
  }
  return indicatorChartUtil.createRadialData({
    node_params,
    place_params,
    nodeColors,
    placeColors,
  })
}
export const selectorIndicator = createSelector(
  selectorOrigin,
  selectorTarget,
  nodeplaceSelector,
  (originRaw, targetRaw, nodePlaceAll) => {
    if (!originRaw || !targetRaw || !nodePlaceAll || nodePlaceAll.length === 0)
      return null
    const origin = getNodePlace(nodePlaceAll, originRaw.NodePlace_HubID)
    const originColor = legendNodePlace[origin['place_type']].color
    const originRadial = createRadialData({
      nodeplace: origin,
      color: originColor,
      stroke: '#333',
    })
    const originLegend = {
      title: originRaw.NodePlace_Station,
      color: originColor,
    }

    const target = getNodePlace(nodePlaceAll, targetRaw.NodePlace_HubID)
    const targetColor = legendNodePlace[target['place_type']].color
    const targetRadial = createRadialData({
      nodeplace: target,
      color: targetColor,
      stroke: targetColor,
    })
    const targetLegend = {
      title: targetRaw.NodePlace_Station,
      color: targetColor,
    }

    const guides = indicatorChartUtil.createGuides({
      radialData: originRadial,
    })
    const labels = indicatorChartUtil.createNodePlaceLabels({
      radialData: originRadial,
    })
    const legend = [originLegend, targetLegend]

    return {
      radialData: [...targetRadial, ...originRadial],
      ...guides,
      ...labels,
      legend,
    }
  }
)
const createStayActivities = data => {
  return legendBehaviours.map((item, index) => {
    const x = data && data[item.name] ? +data[item.name] : 0
    return {
      y: ((index + 1) * 180) / legendBehaviours.length,
      x,
      label: item.alias,
      ...item,
      cluster: item.alias,
    }
  })
}
const getStays = (data, id) => data.find(f => id && f.SmartRider_HubID === id)
const getColor = (data, id) => {
  const nodePlace = getNodePlace(data, id)
  if(!nodePlace) return '#999'
  const placeType = nodePlace.place_type
  return legendNodePlace[placeType] ? legendNodePlace[placeType].color : '#999'
}
const findMax = (a, b) => (a > b ? a : b)
const findDiff = (a, b) => b - a
export const selectorActivitiesComparison = createSelector(
  selectorOrigin,
  selectorTarget,
  behaviourDataSelector,
  nodeplaceSelector,
  (originRaw, targetRaw, stays, nodePlaceAll) => {
    if (!originRaw || !targetRaw || !stays || !nodePlaceAll) return null
    const originStays = getStays(stays, originRaw.SmartRider_HubID)
    const origin = originStays ? originStays.activities[0] : null
    const originColor = getColor(nodePlaceAll, originRaw.NodePlace_HubID)
    const originData = createStayActivities(origin)
    const originLegend = {
      title: originRaw.NodePlace_Station,
      color: originColor,
    }

    const targetStays = getStays(stays, targetRaw.SmartRider_HubID)
    const target = targetStays ? targetStays.activities[0] : null
    const targetColor = getColor(nodePlaceAll, targetRaw.NodePlace_HubID)
    const targetData = createStayActivities(target)
    const targetLegend = {
      title: targetRaw.NodePlace_Station,
      color: targetColor,
    }

    const labels = originData.map(ori => {
      const found = targetData.find(f => f.label === ori.label)
      const max = findMax(ori.x, found.x)
      const diff = findDiff(ori.x, found.x)
      return {
        x: max,
        y: ori.y,
        label: diff > 0 ? '+' + diff.toString() : diff.toString(),
      }
    })
    const axisLabels = originData.map(f => ({ label: f.label, y: f.y }))
    const legend = [originLegend, targetLegend]
    return {
      origin: {
        data: originData,
        color: originColor,
      },
      target: {
        data: targetData,
        color: targetColor,
      },
      labels,
      axisLabels,
      legend,
    }
  }
)

export const selectorPopulation = createSelector(
  selectorOriginTargetCluster,
  currentPopSelector,
  structurePlanSelector,
  structurePlanPopSelector,
  nodeplaceSelector,
  occupancySelector,
  bufferSelector,
  (
    originCluster,
    currentPop,
    structureplans,
    structurePlanPop,
    nodePlace,
    occupancyRate,
    buffers
  ) => {
    if (!(currentPop && originCluster && nodePlace && buffers)) {
      return {
        currentPop: 0,
        structurePlanPop: 0,
      }
    }

    let { pop_density, patronage } = getNodePlace(
      nodePlace,
      originCluster.target.id
    )

    const originStn = getNodePlace(nodePlace, originCluster.origin.id)

    const originPopDensity = originStn.pop_density / 100 // remap to p/km^2
    const originPatronage = originStn.patronage['Weekday_boardings1.6_2018']
    const replace = /"[^A-Za-z0-9 _]"/g
    const originBuffer = buffers.filter(buffer =>
      originCluster.origin.name
        .replace(replace, '')
        .toLowerCase()
        .includes(buffer.StationName.replace(replace, '').toLowerCase())
    )
    const currentStnPop =
      originBuffer && originBuffer.length > 0
        ? (
            originPopDensity *
            parseFloat(originBuffer[0]['BufferArea1600'] * 100)
          ).toFixed(0)
        : 0

    const buffer = buffers.filter(buffer =>
      originCluster.target.name
        .replace(replace, '')
        .toLowerCase()
        .includes(buffer.StationName.replace(replace, '').toLowerCase())
    )[0]

    const patronageNumber = patronage['Weekday_boardings1.6_2018']

    // Pop_den_1.6 is persons per square kilometer so we convert to persons per hectare
    let popDensity = pop_density / 100
    // convert population density to housing density
    let housingDensity = (popDensity / occupancyRate).toFixed(3)

    let futureStnPop =
      buffer &&
      (parseFloat(buffer['BufferArea1600']) * popDensity * 100).toFixed(0)

    const stnPopChange = futureStnPop - currentStnPop
    const stnPatronageChange = patronageNumber - originPatronage

    let originImage =
      window.origin +
      '/Stations/' +
      originCluster.origin.name.replace(' ', '_').toLowerCase() +
      '_400.jpg'

    let targetImage =
      window.origin +
      '/Stations/' +
      originCluster.target.name.replace(' ', '_').toLowerCase() +
      '_400.jpg'

    let result = {
      popChange: currentPop[2].y - currentPop[0].y,
      structurePlanPop: structurePlanPop[originCluster.origin.name],
      housingDensity: housingDensity,
      patronageNumber: stnPatronageChange,
      futureStnPop: futureStnPop,
      originImage,
      targetImage,
      stnPopChange,
    }

    if (!structurePlanPop[originCluster.origin.name]) {
      let structurePlanPop =
        structureplans &&
        structureplans.filter(item => {
          return originCluster.origin.name.includes(item.Station)
        })
      if (structurePlanPop && structurePlanPop.length === 0)
        return {
          popChange: currentPop[2].y - currentPop[0].y,
          structurePlanPop: 0,
          housingDensity: housingDensity,
          patronageNumber: stnPatronageChange,
          futureStnPop: futureStnPop,
          originImage,
          targetImage,
          stnPopChange,
        }

      result = {
        popChange: currentPop[2].y - currentPop[0].y,
        structurePlanPop:
          structurePlanPop && parseInt(structurePlanPop[2].Population, 10),
        housingDensity: housingDensity,
        patronageNumber: stnPatronageChange,
        futureStnPop: futureStnPop,
        originImage,
        targetImage,
        stnPopChange,
      }
    }

    return result
  }
)

export const selectorCurrentScores = createSelector(
  selectorClusters,
  clusters => {
    return clusters
  }
)

const averageAttribute = array => {
  let attributes = {}
  let averages = {}
  let count = 0
  array.forEach(entry => {
    Object.keys(entry).forEach(key => {
      if (Object.keys(attributes).includes(key)) {
        attributes[key] += entry[key]
      } else {
        attributes[key] = entry[key]
      }
    })
    count += 1
  })

  Object.keys(attributes).forEach(key => {
    averages[key] = attributes[key] / count
  })

  return averages
}

const rangeAttributes = array => {
  let ranges = {}
  array.forEach(hub => {
    Object.keys(hub).forEach(key => {
      if (Object.keys(ranges).includes(key)) {
        if (hub[key] < ranges[key][0]) {
          ranges[key][0] = hub[key]
        }

        if (hub[key] > ranges[key][1]) {
          ranges[key][1] = hub[key]
        }
      } else {
        ranges[key] = [hub[key], hub[key]]
      }
    })
  })

  return ranges
}

export const selectorLGAValues = createSelector(
  existingStationSelector,
  nodeplaceSelector,
  (existing, nodePlaceData) => {
    if (existing && nodePlaceData) {
      const existingNodes = Object.values(existing).map(
        node => getNodePlace(nodePlaceData, node.id)['lga_analysis']
      ) // map our existing stations to their nodeplace lga data

      let averages = averageAttribute(existingNodes)
      return averages
    }
  }
)

export const selectorLGARanges = createSelector(
  targetStationSelector,
  nodeplaceSelector,
  (target, nodePlaceData) => {
    if (target && nodePlaceData) {
      const targetNodes = Object.values(target).map(
        node => getNodePlace(nodePlaceData, node.id)['lga_analysis']
      ) // target data to nodeplace lga data
      let ranges = rangeAttributes(targetNodes)
      return ranges
    }
  }
)

export const selectorWorkingPop = createSelector(
  workingPopSelector,
  lgaSelector,
  (workingPop, lgaCode) => {
    if (workingPop && lgaCode) {
      let selectedWorking = workingPop.filter(item => item.LGA.toString() === lgaCode.toString())[0]

      let workingAge = parseFloat(selectedWorking['workingAge'])
      return workingAge
    }
  }
)
