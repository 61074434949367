import { connect } from 'react-redux'
import TravelSurvey from '../components/TravelSurvey'
import selectorTravelSurveyData from '../selectors/travelsurvey-selector'

export default connect(
    state => {
        return {
            data: selectorTravelSurveyData(state),
        }
    }
)(TravelSurvey)