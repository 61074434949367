import { createSelector } from 'reselect'

import indicatorChartUtil from '../utils/indicatorchart'
import legend_config from '../config/legend'

export const nodeplaceSelector = state => state.nodeplace.results

const selectedHubSelector = state => state.hubs.selectedHub
const allHubsSelector = state => state.hubs.data
export const selectorDistance = state => state.nodeplace.distance

export const selectorClusterType = createSelector(
  nodeplaceSelector,
  selectorDistance,
  allHubsSelector,
  (nodePlaceAll, distance, allHubs) => {
    if (!nodePlaceAll || !distance || !allHubs) return null
    const data = nodePlaceAll.map(nodeplace => {
      const foundHub = allHubs.find(f => f.NodePlace_HubID === +nodeplace.ID)

      const name = foundHub ? foundHub.NodePlace_Station : 'unknown'
      if (name !== 'unknown') {
        return {
          name,
          id: +nodeplace['ID'],
          x: nodeplace['node_score']['Node0_100'],
          y: nodeplace['place_score']['Place0_100'],
          color: legend_config.legendNodePlace[nodeplace['place_type']].color,
        }
      }
      return {
        name: undefined,
        id: undefined,
        x: 0,
        y: 0,
        color: 'rgba(0,0,0,0)',
      }
    })
    const npaLegend = Object.keys(legend_config.legendNodePlace).map(key => ({
      title: legend_config.legendNodePlace[key].short,
      color: legend_config.legendNodePlace[key].color,
    }))
    return { data, npaLegend }
  }
)

export const selectorNodePlace = createSelector(
  nodeplaceSelector,
  selectedHubSelector,
  (nodeplace, selectedHub) => {
    const nodeplace_f = nodeplace.filter(
      item => parseInt(item.ID, 10) === selectedHub.NodePlace_HubID
    )
    if (nodeplace_f.length > 0) {
      nodeplace_f[0]['type'] =
        legend_config.legendNodePlace[nodeplace_f[0]['place_type']].long

      return nodeplace_f[0]
    } else {
      return null
    }
  }
)

export const selectorNodePlaceScore = createSelector(
  selectorNodePlace,
  selectorDistance,
  (nodeplace, distance) => {
    if (nodeplace) {
      const { node_score, place_score } = nodeplace
      // In this iteration of the data, we only have it for 1600m.
      const data = {
        '800m': {
          node: node_score.Node0_100,
          place: place_score.Place0_100,
        },
        '1600m': {
          node: node_score.Node0_100,
          place: place_score.Place0_100,
        },
      }
      return data[distance]
    } else {
      return null
    }
  }
)
export const selectorLandUse = createSelector(selectorNodePlace, nodeplace => {
  if (nodeplace) {
    const land_use = nodeplace['land_use']
    let total = 0
    Object.values(land_use).map(item => (total += item))
    return {
      children: Object.keys(land_use).map(item => ({
        title:
          legend_config.legendTreemap[item].shortName +
          ' (' +
          ((land_use[item] / total) * 100).toFixed(0) +
          '%)',
        color: legend_config.legendTreemap[item].color,
        hex: legend_config.legendTreemap[item].color,
        value: land_use[item],
        strokeWidth: 10,
      })),
    }
  } else {
    return {}
  }
})

export const selectorHousePrices = createSelector(
  selectorNodePlace,
  nodeplace => {
    let median_housing =
      nodeplace && Object.keys(nodeplace).includes('median_housing')
        ? nodeplace['median_housing']
        : null

    return median_housing
  }
)

export const selectorIndicators = createSelector(
  selectorNodePlace,
  nodeplace => {
    if (nodeplace) {
      const { node_params, place_params } = nodeplace
      const nodeColors = {
        color: '#F4B35B',
        stroke: '#FF9505',
      }
      const placeColors = {
        color: '#7D92FB',
        stroke: '#3152FB',
      }
      const radialData = indicatorChartUtil.createRadialData({
        node_params,
        place_params,
        nodeColors,
        placeColors,
      })
      const guides = indicatorChartUtil.createGuides({ radialData })
      const labels = indicatorChartUtil.createNodePlaceLabels({ radialData })
      return {
        radialData,
        ...guides,
        ...labels,
      }
    } else {
      return null
    }
  }
)
