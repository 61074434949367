import { SA2Layer } from '../constants/actionTypes'
import { querySA2s } from '../queries/sa2layer'
const {
  GET_SA2_SUCCESS,
  GET_SA2_FAILURE,
  SET_SA2_ATTRIBUTES,
  SET_SA2_MODELNAME,
} = SA2Layer

export const loadSA2s = () => {
  return function(dispatch) {
    querySA2s()
      .then(res => res.json())
      .then(geo => dispatch(getSA2Success(geo)))
      .catch(err => dispatch(getSA2Failure(err)))
  }
}
export const getSA2Success = payload => ({
  type: GET_SA2_SUCCESS,
  payload,
})
export const getSA2Failure = payload => ({
  type: GET_SA2_FAILURE,
  payload,
})
export const setSA2Attributes = payload => ({
  type: SET_SA2_ATTRIBUTES,
  payload,
})
export const setSA2ModelName = payload => ({
  type: SET_SA2_MODELNAME,
  payload,
})
