import React, { Component } from 'react'
import styled from '@emotion/styled'

import ModelPanel from '../../hoc/ModelPanel'
import {GenericIndicator, GenericTitle, GenericSpacer} from './Indicators'
import { ModelHeader } from '../ModelContainer/CommonComponents'

const TravelSurveyContainer = styled('div')`
  flex: 1 1 auto;
  padding: 1rem 2rem 0 2rem;
`

class TravelSurvey extends Component {
    render() {
        const {data} = this.props;
        return (
            <TravelSurveyContainer>
                { data && (<ModelHeader heading={"Travel Survey Analysis"}
                    description={"The travel survey analysis was recently undertaken \
                    on a survey conducted throughout the City of Wanneroo \
                    regarding the attitudes of residents towards different \
                    transport modes, including new modes such as shared and \
                    autonomous vehicles."}
                    academic={"https://resources.patrec.org/reports/railsmart/survey.pdf"} />
                )}
                {data && <GenericTitle data={data.information} 
                    title="Survey Results" />}
                {data && <GenericIndicator data={data.gender} 
                    title={"Gender Demographics"} 
                    subtitle={"Percentage who identified as gender"}
                    colors={{ "Male": "#a5cfe5", "Female": "#f79c99"}} />}
                {data && <GenericIndicator data={data.age}
                    title={"Age Demographics"}
                    subtitle={"Percentage who identified as age"}
                    colors={{ "65-74": "#a2a313", "55-64": "#f2d931", 
                    "45-54": "#a5cfe5", "35-44": "#d29331", "25-34": "#f79c99", 
                    "18-24": "#abe086" }} />}
                {data && <GenericIndicator data={data.education}
                    title={"Education Demographics"}
                    subtitle={"Percentage by highest education type"}
                    colors={{ "P/Grad": "#a2a313", "Univ": "#f2d931", 
                    "TAFE": "#a5cfe5", "Grad HS": "#d29331", 
                    "Some HS": "#f79c99" }} />}
                {data && <GenericIndicator data={data.employment}
                    title={"Employment Demographics"}
                    subtitle={"Percentage by specified employment type"}
                    colors={{ "Wages": "#a2a313", "Unable": "#f2d931", 
                    "Student": "#a5cfe5", "Self-Emp": "#d29331", 
                    "Retired": "#f79c99", "Out, Not Looking": "#abe086", 
                    "Out, Looking": "#2677b6", "Homemaker": "#329e31" }} />}
                {data && <GenericIndicator data={data.modeUsage}
                    title={"Traditional Mode Usage"}
                    subtitle={"Percentage who used mode in last year"}
                    colors={{ "Other": "#a2a313", "Walk": "#f2d931", 
                    "Train": "#a5cfe5", "Taxi": "#d29331", 
                    "Motorbike": "#f79c99", "Car or Van": "#abe086", 
                    "Bus": "#2677b6", "Bicycle": "#329e31" }} />}
                {data && <GenericIndicator data={data.sharingUsage}
                    title={"Sharing Mode Usage"}
                    subtitle={"Percentage who used mode in last year"}
                    colors={{ "Ride Sharing": "#a2a313", 
                    "Car Sharing": "#f2d931", "Car Pooling": "#a5cfe5", 
                    "Bike Sharing": "#d29331" }} />}
                {data && <GenericIndicator data={data.sharingSentiment}
                    title={"Shared Mode Sentiment"}
                    subtitle={"Percentage who felt positive about mode"}
                    colors={{ "Ride Sharing": "#a2a313", 
                    "Car Sharing": "#f2d931", "Car Pooling": "#a5cfe5", 
                    "Bike Sharing": "#d29331" }} />}
                {data && <GenericIndicator data={data.trainUtilisation}
                    title={"Negative Train Utilisation"}
                    subtitle={"Percentage who chose issue"}
                    colors={{ "Too Far Away": "#a2a313", 
                    "Personal Safety": "#f2d931", "No Parking": "#a5cfe5", 
                    "Too Expensive": "#d29331", 
                    "Bad Timetable": "#f79c99" }} />}
                <GenericSpacer />
            </TravelSurveyContainer>
        )
    }
}

export default ModelPanel(TravelSurvey, 'travel survey analysis')