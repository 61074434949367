import { connect } from 'react-redux'
import Map from '../components/ScenarioPlanner/MicroMap'

import { selectorMapWidth } from '../selectors/map-selector'
import {
  selectorCenterLGA,
} from '../selectors/lgaLayer-selector'
import { selectorOtherHubs } from '../selectors/hubLayer-selector'

export default connect(
  state => {
    return {
      panelSize: selectorMapWidth(state),
      center: selectorCenterLGA(state),
      hubs: selectorOtherHubs(state)
    }
  },
  {}
)(Map)
