import React, { PureComponent } from 'react'
import styled from '@emotion/styled'
import { queryYourMove } from '../../queries/yourMove'

import Inputs from './Inputs'
import Results from './Results'
import Info from './Info'

import Title from '../../common/Title'
import Column from '../../common/Column'
import Row from '../../common/Row'

const Container = styled(Column)`
    padding: 1rem 0;
`
const InnerContainer = styled(Row)`
    margin-top:1rem;
`
const TitleContainer = styled(Title)`
    padding: 0.5rem 0.75rem;
    color:${props => props.theme.white};
    border-radius: 4px 4px 0px 0px;
    background-color: ${props => props.theme.darkbluegrey};
`
class YourMove extends PureComponent {
    
    state = {
        info: null,
        submittedParams: null,
        results: null
    }

    handleSubmit = params => {
        const cleanParams = {
            ...params,
            ReductionRate: +(parseFloat(params.ReductionRate.replace('%', ''))/100).toFixed(2)
        }
        queryYourMove(cleanParams)
            .then(res => res.json())
            .then(results => this.setState({submittedParams: params, results}))
            .catch(err => console.log(err))
        
    }

    setYourMoveParams = params => this.props.setYourMoveParams(params)
    
    handleInfo = info => this.setState({info})
    
    render() {
        const { submittedParams, results, info } = this.state
        const { currentParams } = this.props
        return (
            <Container>
                <TitleContainer>Your Move program rapid appraisal tool</TitleContainer>
                <InnerContainer>
                    <Column>
                        <Inputs handleInfo={this.handleInfo} setYourMoveParams={this.setYourMoveParams} handleSubmit={this.handleSubmit} />
                        <Info data={info}/>
                    </Column>
                        <Results handleInfo={this.handleInfo} currentParams={currentParams} submittedParams={submittedParams} results={results} />
                </InnerContainer>
            </Container>
        )
    }
}

export default YourMove