import React, { Component } from 'react'
import styled from '@emotion/styled'

import Search from '../../containers/Search'
import IconButtonMini from '../../common/IconButtonMini'

const SearchContainer = styled('div')`
  padding-left: 9px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

class ContextSearch extends Component {
  render() {
    return (
      <SearchContainer>
        <Search
          border={false}
          size={'small'}
          title={'Search station or stop'}
        />
        <IconButtonMini
          onClick={this.props.clear}
          shape="circle"
          style={{ marginLeft:5,color: 'white' }}
          icon="close"
        />
      </SearchContainer>
    )
  }
}

export default ContextSearch
