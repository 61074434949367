import { Search, Hubs } from '../constants/actionTypes'

const { GET_RESULTS, GET_RESULTS_SUCCESS, GET_RESULTS_FAILURE } = Search

const { SELECT_HUB } = Hubs

export const initialState = {
  allresults: [],
  searchterm: '',
  fetching: false,
  error: null,
}

export default function(state = initialState, { type, payload }) {
  switch (type) {
    case GET_RESULTS: {
      return {
        ...state,
        fetching: true,
        searchterm: payload,
      }
    }

    case SELECT_HUB: {
      return {
        ...state,
        searchterm: '',
        allresults: null,
        fetching: false,
        hub: payload,
      }
    }

    case GET_RESULTS_SUCCESS: {
      return {
        ...state,
        allresults: payload,
        fetching: false,
      }
    }

    case GET_RESULTS_FAILURE: {
      return {
        ...state,
        fetching: false,
        error: payload,
      }
    }
    default:
      return state
  }
}
