import React, { PureComponent } from 'react'
import { Feature, Layer } from 'react-mapbox-gl'
import PropTypes from 'prop-types'

class HubLayer extends PureComponent {

  render() {
    const { data } = this.props
    return (
      <Layer
        id="hublayer"
        type="circle"
        paint={{
          'circle-radius': {
            property: 'patronage',
            stops: [[1000, 4], [5000, 6], [20000, 10], [100000, 15]],
          },
          'circle-color': ['get', 'color'],
          'circle-opacity': 0.7 ,
          'circle-stroke-width': 1.5,
          'circle-stroke-color': ['get', 'stroke'],
        }}
      >
        {data &&
          data.map(hub => (
            <Feature
              properties={{ ...hub }}
              key={hub.ID}
              id={hub.ID}
              coordinates={[hub.X, hub.Y]}
            />
          ))}
      </Layer>
    )
  }
}

HubLayer.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object)
}

export default HubLayer
