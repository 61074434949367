import React, { Component } from 'react'
import styled from '@emotion/styled'

import ContextLayers from '../ContextLayers'
import Legend from '../ContextLayers/Legend'
import ContextSearch from '../ContextLayers/ContextSearch'

import Column from '../../common/Column'
import Row from '../../common/Row'
import MiniTitle from '../../common/MiniTitle'
import IconButtonMini from '../../common/IconButtonMini'

const Container = styled(Column)`
  position: absolute;
  top: 100px;
  width:350px;
  left: 1.5rem;
  z-index: 3;
  border-radius: 3px;
  background-color: ${props => props.theme.lightgrey};
  box-shadow: 0px 2px 4px 0px ${props => props.theme.slightlydarkergrey};
`
const HeaderContainer = styled(Column)`
  box-shadow: 0px 2px 4px 0px ${props => props.theme.slightlydarkergrey};
`
const Heading = styled(Row)`
  padding: 0.5rem;
  background-color:${props=>props.theme.darkbluegrey};
  color:${props=>props.theme.white};
  align-items: center;
`
const Title = styled(MiniTitle)`
  padding: 0;
  width:90px;
`
const ToolContainer = styled(Row)`
  padding: 0.5rem 1rem;
  align-items: center;
`
const Tool = styled('div')`
  font-size: 14px;
  cursor: pointer;
  margin-right: 1rem;
  padding-right: 1rem;
  font-weight: ${props => props.weight};
`
const ToggleContainer = styled(Column)`
  flex: 1 1 0;
  align-items: flex-end;
`
class MapToolbox extends Component {

  state = {
    tools: ['Layers', 'Legend'],
    selectedTool: 'Layers',
    open: true,
    legendData: false
  }

  updateTab = selectedTool => this.setState({ selectedTool })

  // expands the dropdown
  toggleOpen = () => this.setState({ open: !this.state.open })

  // for the search bar
  clearSelectedHub = () => this.props.selectHub(null)

  // sets the legend data 
  setContextLegendData = legendData => this.setState({ legendData })

  // sets the layer mode
  setMode = selectedMode => this.props.setMode(selectedMode)

  render() {
    const { tools, selectedTool, open, legendData } = this.state
    const { modelName, contextLegend, modes, selectedMode, showContextLayer, showEmploymentLayer } = this.props
    return (
      <Container>
        <HeaderContainer>
          <Heading>
            <Title>Map toolbox</Title>
            <ContextSearch clear={this.clearSelectedHub} />
          </Heading>
          <ToolContainer>
            {tools.map(tool => {
              return (
                <Tool
                  key={tool}
                  weight={tool === selectedTool ? 'bold' : 'normal'}
                  onClick={() => this.updateTab(tool)}
                >
                  {tool}
                </Tool>
              )
            })}
            <ToggleContainer>
              <IconButtonMini
                onClick={this.toggleOpen}
                shape="circle"
                style={{ color: 'black' }}
                icon={open ? 'up' : 'down'}
              />
            </ToggleContainer>
          </ToolContainer>
        </HeaderContainer>
        <Column>
          {open &&
            selectedTool === 'Layers' && (
              <ContextLayers 
                modes={modes}
                mode={selectedMode}
                setMode={this.setMode}
                setContextLegendData={this.setContextLegendData} 
              />
            )}
          {open &&
            selectedTool === 'Legend' && (
              <Legend 
                showContextLayer={showContextLayer}
                showEmploymentLayer={showEmploymentLayer}
                mode={selectedMode}
                contextLegend={contextLegend} 
                modelName={modelName} 
                legendData={legendData} 
              />
            )}
        </Column>
      </Container>
    )
  }
}

export default MapToolbox
