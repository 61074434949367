import { connect } from 'react-redux'
import NodePlace from '../components/NodePlace'
import {
  selectorNodePlace,
  selectorLandUse,
  selectorClusterType,
  selectorIndicators,
  selectorDistance,
  selectorHousePrices,
  selectorNodePlaceScore,
} from '../selectors/nodeplace-selector'

import { setDistance } from '../actions/nodeplace'

export default connect(
  state => {
    return {
      nodePlace: selectorNodePlace(state),
      clusterType: selectorClusterType(state),
      landUse: selectorLandUse(state),
      nodeplaceScore: selectorNodePlaceScore(state),
      indicators: selectorIndicators(state),
      housePrices: selectorHousePrices(state),
      distance: selectorDistance(state),
    }
  },
  { setDistance }
)(NodePlace)
