const rootUrl = process.env.REACT_APP_API

export function querySA2s() {
  const url = `${rootUrl}/geojson`
  return fetch(url, {
    method: 'get',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
  })
}
