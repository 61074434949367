import { connect } from 'react-redux'
import Behaviour from '../components/Behaviour'

import {
  selectorActivitiesHub,
  selectorActivitiesComparison,
  selectorWindrose,
} from '../selectors/behaviour-selector'

export default connect(
  state => {
    return {
      activitiesHub: selectorActivitiesHub(state),
      activitiesComparison: selectorActivitiesComparison(state),
      windroseData: selectorWindrose(state),
    }
  },
  {}
)(Behaviour)
