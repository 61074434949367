import { queryActivities } from '../queries/behaviour'
import { Behaviour } from '../constants/actionTypes'

const {
  GET_ACTIVITIES,
  GET_ACTIVITIES_SUCCESS,
  GET_ACTIVITIES_FAILURE,
} = Behaviour

export const loadActivities = () => {
  return dispatch => {
    dispatch(getActivities())
    queryActivities()
      .then(res => res.json())
      .then(data => dispatch(getActivitiesSuccess(data)))
      .catch(err => dispatch(getActivitiesFailure(err)))
  }
}

export const getActivities = () => ({
  type: GET_ACTIVITIES,
})
export const getActivitiesSuccess = payload => ({
  type: GET_ACTIVITIES_SUCCESS,
  payload,
})
export const getActivitiesFailure = payload => ({
  type: GET_ACTIVITIES_FAILURE,
  payload,
})
