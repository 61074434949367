import React from 'react'
import styled from '@emotion/styled'

import Heading from '../../common/Heading'

const HubName = styled('p')`
  color: #333333;
  font-size: 14px;
`

const HubType = props => {
  const { hubType } = props
  return (
    <>
      <Heading>Hub type</Heading>
      <HubName>{hubType}</HubName>
    </>
  )
}

export default HubType
