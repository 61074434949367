import styled from '@emotion/styled'
import { Radio } from 'antd'
export default styled(Radio)`
  display: block;
  line-height: 2rem;
  &:hover {
    & .ant-radio,
    .ant-radio-inner {
      border-color: ${props => props.theme.cyan}!important;
    }
  }
  & :hover .ant-radio .ant-radio-inner,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-focused .ant-radio-inner {
    border-color: ${props => props.theme.cyan};
  }
  & .ant-radio-checked:after {
    border-color: ${props => props.theme.cyan}!important;
  }
  & .ant-radio-inner:after {
    background: ${props => props.theme.cyan}!important;
  }
  & .ant-radio-checked .ant-radio-inner {
    border-color: ${props => props.theme.cyan}!important;
  }
  & .ant-radio-button-wrapper:hover,
  .ant-radio-button-wrapper-focused {
    color: ${props => props.theme.cyan};
  }
  & .ant-radio-button-wrapper-checked {
    border-color: ${props => props.theme.cyan}!important;
    color: ${props => props.theme.cyan};
    -webkit-box-shadow: -1px 0 0 0 ${props => props.theme.cyan};
    box-shadow: -1px 0 0 0 ${props => props.theme.cyan};
  }
  & .ant-radio-button-wrapper-checked {
    &:first-of-type,
    &:active {
      border-color: ${props => props.theme.cyan}!important;
    }
  }
  & .ant-radio-button-wrapper-checked {
    &:hover {
      border-color: ${props => props.theme.cyan};
      -webkit-box-shadow: -1px 0 0 0 ${props => props.theme.cyan};
      box-shadow: -1px 0 0 0 ${props => props.theme.cyan};
    }
  }
  & .ant-radio-button-wrapper-checked {
    &::before {
      background-color: ${props => props.theme.cyan}!important;
    }
  }
  &
    .ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: ${props => props.theme.cyan};
    border-color: ${props => props.theme.cyan};
  }
  &
    .ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    background: ${props => props.theme.cyan};
    border-color: ${props => props.theme.cyan};
  }
  &
    .ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
    background: ${props => props.theme.cyan};
    border-color: ${props => props.theme.cyan};
  }
`
