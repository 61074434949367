import React from 'react'
import { Popup } from 'react-mapbox-gl'
import styled from '@emotion/styled'
import { Button } from 'antd';

const Center = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const PopupLayer = props =>
  props.popup ? (
    <Popup
      coordinates={props.popup.coordinates}
     
    >
    <Button icon="close" onClick={()=>props.selectFeature({map:null, e: null})}></Button>
    <Center>
      <h3>{props.popup.heading}</h3>
      <p>{props.popup.text}</p>
      </Center>
    </Popup>
  ) : null

export default PopupLayer
