import { createSelector } from 'reselect'

const modelsSelector = state => state.modelcontainer.models
const showContainerSelector = state => state.modelcontainer.show
const selectedHubSelector = state => state.hubs.selectedHub
const centerSelector = state => state.map.viewport.center

export const selectorMapWidth = createSelector(
  modelsSelector,
  showContainerSelector,
  selectedHubSelector,
  (models, show, selectedHub) => (show && selectedHub ? models.length : 0)
)

export const selectorMapCenter = createSelector(
  selectedHubSelector,
  centerSelector,
  (selectedHub, center) =>
    selectedHub
      ? { center: [selectedHub['X'], selectedHub['Y']] }
      : { center: center }
)
