import React, { PureComponent } from 'react'
import styled from '@emotion/styled'
import ChangeClusterChartSwitch from './ChangeClusterChartSwitch'
import IndicatorComparisonChart from './IndicatorComparisonChart'
import StaysComparisonChart from './StaysComparisonChart'

import Column from '../../../common/Column'

const Container = styled(Column)`
  width: ${props => props.width}px;
  padding: 1rem;
  border-left: 1px solid ${props => props.theme.bluegrey};
  flex: 1 0 ${props=>props.width}px;
`
class ChangeClusterComparison extends PureComponent {
  state = {
    charts: ['Indicator comparison', 'Stay activities comparison'],
    selectedChart: 'Indicator comparison',
  }

  updateSelectedChart = selectedChart => this.setState({ selectedChart })

  render() {
    const { charts, selectedChart } = this.state
    const { origin, target, width, indicatorRadial, stays } = this.props
    return (
      <Container width={width}>
        <ChangeClusterChartSwitch
          charts={charts}
          selectedChart={selectedChart}
          updateSelectedChart={this.updateSelectedChart}
        />
        {selectedChart === 'Indicator comparison' && <IndicatorComparisonChart
          data={indicatorRadial}
          origin={origin}
          target={target}
        />}
        {selectedChart === 'Stay activities comparison' && <StaysComparisonChart
          data={stays}
          origin={origin}
          target={target}
        />}
      </Container>
    )
  }
}

export default ChangeClusterComparison
