import { connect } from 'react-redux'
import VisualContextLayer from '../components/Layers/VisualContextLayer'

import { selectorCombineAttribute } from '../selectors/contextLayer-selector'
import { selectorShowContextLayer } from '../selectors/sa2Layer-selector'

export default connect(
  state => {
    return {
      newGeometry: selectorCombineAttribute(state).newGeometry,
      theGeometry: selectorCombineAttribute(state).geometry,
      layername: `${state.context.selected}-${state.context.selectedAttribute}`.replace(" ", "-"),
      showMe: selectorShowContextLayer(state)
    }
  },
  {}
)(VisualContextLayer)
