import React, { Component } from 'react'
import styled from '@emotion/styled'
import { Modal, Button } from 'antd'

import MicroMap from '../../containers/MicroMap'
import PopulationForecast from './PopulationForecast'
import NodePlaceChangeChart from './NodePlaceChangeChart'
import ChangeClusterList from './ChangeClusterList'
import LGALevelResults from './LGALevelResults'
import Employment from '../../containers/EmploymentPrint'

import Row from '../../common/Row'
import Column from '../../common/Column'
import Heading from '../../common/Heading'
import PrintProvider, { Print, NoPrint } from 'react-easy-print'

const OuterContainer = styled('div')`
  background-color: #fff;
  margin: -24px;
`
const HeadingContainer = styled(Heading)`
  padding: 1rem 0;
`
const Container = styled(Column)`
  margin: auto;
  width: 1420px;
  padding: 1rem;
`
const BottomContainer = styled(Row)`
  padding: 1rem;
  margin-top: 1rem;
  background-color: ${props => props.theme.lightgrey};
`
const BottomLeft = styled(Column)`
  width: 900px;
`
const Margin = styled('div')`
  margin: 10px;
`
const SpacedRow = styled(Row)`
  justify-content: space-between;
`
const InfoContainer = styled('span')`
  font-size: 0.75rem;
`

class PrintView extends Component {
  close = () => this.props.toggle(false)

  render() {
    const {
      lga,
      clusterType,
      originTargetCluster,
      population,
      newPatronage,
      employment,
      clusterList,
      visible,
    } = this.props
    return (
      <Modal
        title={null}
        centered
        visible={visible}
        footer={null}
        width={'100%'}
        onCancel={this.close}
        onOk={this.close}
      >
        <PrintProvider>
          <Print name={'Report'} single>
            <OuterContainer>
              <Container>
                <SpacedRow>
                  <HeadingContainer>
                    Scenario builder summary: {lga}
                  </HeadingContainer>
                  <NoPrint>
                    <Margin>
                      <Button onClick={() => window.print()}>Print&nbsp;
                        <InfoContainer>(please ensure you print in Landscape A4)</InfoContainer>
                      </Button>
                    </Margin>
                  </NoPrint>
                </SpacedRow>
                <Row>
                  <MicroMap width={400} margin={0} height={250} />
                  <PopulationForecast />
                  <NodePlaceChangeChart
                    clusterList={clusterList}
                    clusterType={clusterType}
                    originTargetCluster={originTargetCluster}
                  />
                </Row>
                <BottomContainer>
                  <BottomLeft>
                    <ChangeClusterList data={clusterList} />
                    <LGALevelResults
                      population={population}
                      newPatronage={newPatronage}
                      employment={employment}
                      lga={lga}
                    />
                  </BottomLeft>
                  <Employment />
                </BottomContainer>
              </Container>
            </OuterContainer>
          </Print>
        </PrintProvider>
      </Modal>
    )
  }
}

export default PrintView
