import styled from '@emotion/styled'
import { Button } from 'antd'
export default styled(Button)`
  &,
  &:focus,
  &:hover,
  &:active,
  &.visited {
    background-color: transparent;
    border-color: transparent;
    color: ${props => props.theme.black};
    box-shadow: none;
  }
`
