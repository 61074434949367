import { createSelector } from 'reselect'

export const travelSurveySelector = state => state.travelsurvey.results

const selectedHubSelector = state => state.hubs.selectedHub

export const selectorTravelSurveyData = createSelector(
    travelSurveySelector, selectedHubSelector, (data, area) => {

        const area_survey = (
            ((data.filter(f => f.analysis_name === area.NodePlace_Station)).length > 0) ? 
            data.filter(f => f.analysis_name === area.NodePlace_Station)[0] : 
            data.filter(f => f.analysis_name === "All Suburbs")[0]);
     
        const response_data = {
            "information": {
                "area": area_survey["analysis_name"],
                "sample_size": area_survey["sample_size"]
            },
            "gender": [
                { y: "Male", x: area_survey["demo_gen_male"] },
                { y: "Female", x: area_survey["demo_gen_female"] }
            ], "age": [
                { y: "18-24", x: area_survey["demo_age_1824"] },
                { y: "25-34", x: area_survey["demo_age_2534"] },
                { y: "35-44", x: area_survey["demo_age_3544"] },
                { y: "45-54", x: area_survey["demo_age_4554"] },
                { y: "55-64", x: area_survey["demo_age_5564"] },
                { y: "65-74", x: area_survey["demo_age_6574"] }
            ], "education": [
                { y: "Some HS", x: area_survey["demo_ed_somehigh"] },
                { y: "Grad HS", x: area_survey["demo_ed_gradhigh"] },
                { y: "TAFE", x: area_survey["demo_ed_tafe"] },
                { y: "Univ", x: area_survey["demo_ed_uni"] },
                { y: "P/Grad", x: area_survey["demo_ed_post"] }
            ], "employment": [
                { y: "Homemaker", x: area_survey["demo_emp_home"] },
                { y: "Out, Looking", x: area_survey["demo_emp_oulo"] },
                { y: "Out, Not Looking", x: area_survey["demo_emp_ounl"] },
                { y: "Retired", x: area_survey["demo_emp_reti"] },
                { y: "Self-Emp", x: area_survey["demo_emp_self"] },
                { y: "Student", x: area_survey["demo_emp_stud"] },
                { y: "Unable", x: area_survey["demo_emp_unab"] },
                { y: "Wages", x: area_survey["demo_emp_wage"] }
            ], "modeUsage": [
                { y: "Bicycle", x: area_survey["used_bike"] },
                { y: "Bus", x: area_survey["used_bus"] },
                { y: "Car or Van", x: area_survey["used_cv"] },
                { y: "Motorbike", x: area_survey["used_mbs"] },
                { y: "Taxi", x: area_survey["used_taxi"] },
                { y: "Train", x: area_survey["used_train"] },
                { y: "Walk", x: area_survey["used_walk"] },
                { y: "Other", x: area_survey["used_oth"] }
            ], "sharingUsage": [
                { y: "Bike Sharing", x: area_survey["used_bshar"] },
                { y: "Car Pooling", x: area_survey["used_cpool"] },
                { y: "Car Sharing", x: area_survey["used_cshar"] },
                { y: "Ride Sharing", x: area_survey["used_rshar"] }
            ], "sharingSentiment": [
                { y: "Bike Sharing", x: area_survey["psen_bshar"] },
                { y: "Car Pooling", x: area_survey["psen_cpool"] },
                { y: "Car Sharing", x: area_survey["psen_cshar"] },
                { y: "Ride Sharing", x: area_survey["psen_rshar"] }
            ], "trainUtilisation": [
                { y: "Bad Timetable", x: area_survey["notrain_badtt"] },
                { y: "Too Expensive", x: area_survey["notrain_expense"] },
                { y: "No Parking", x: area_survey["notrain_nopark"] },
                { y: "Personal Safety", x: area_survey["notrain_persafe"] },
                { y: "Too Far Away", x: area_survey["notrain_toofar"] }                
            ]
        };

        return response_data
    }
)

export default selectorTravelSurveyData;