import React, { PureComponent } from 'react'
import { Feature, Layer } from 'react-mapbox-gl'
import PropTypes from 'prop-types'

class StopsLayer extends PureComponent {
  render() {
    const { data } = this.props
    return (
      <Layer
        id="stopslayer"
        type="circle"
        paint={{
          'circle-radius': 3,
          'circle-color': '#0A5990',
          'circle-opacity': 1,
          'circle-stroke-width': 1,
          'circle-stroke-color': '#fff',
        }}
      >
        {data.map(stop => (
          <Feature key={stop.StopID} coordinates={[stop.X, stop.Y]} />
        ))}
      </Layer>
    )
  }
}

StopsLayer.propTypes = {
  data: PropTypes.array.isRequired,
}

export default StopsLayer
