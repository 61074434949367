import React from 'react'
import styled from '@emotion/styled'
import { keyframes } from "@emotion/core"
import PropTypes from 'prop-types'

const BounceAnimation = keyframes`
  0% { margin-bottom: 5px; }
  50% { margin-bottom: 15px }
  100% { margin-bottom: 5px }
`
const Container = styled('div')`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex: 1 1 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1011;
`
const DotWrapper = styled('div')`
  display: flex;
  align-items: flex-end;
`
const Dot = styled('div')`
  background-color: ${props => props.theme.yellow};
  border-radius: 50%;
  width: 7px;
  height: 7px;
  margin: 0 5px;
  /* Animation */
  animation: ${BounceAnimation} 0.5s ease-in-out infinite;
  animation-delay: ${props => props.delay};
`
const DotText = styled('span')`
  font-size: 1.5rem;
  color: ${props => props.theme.yellow};
`

const Loading = ({ isLoading }) => {
  if (isLoading) {
    return (
      <Container>
        <DotWrapper>
          <DotText>Loading</DotText>
          <Dot delay="0s" />
          <Dot delay=".1s" />
          <Dot delay=".2s" />
        </DotWrapper>
      </Container>
    )
  }
  return null
}

Loading.propTypes = {
  isLoading: PropTypes.bool.isRequired,
}

export default Loading
