import { queryEmployment, queryLGAEmployment } from '../queries/employment'
import { Employment } from '../constants/actionTypes'

const {
  GET_EMPLOYMENT,
  GET_EMPLOYMENT_SUCCESS,
  GET_EMPLOYMENT_FAILURE,
  SET_EMPLOYMENT_CONTROLS
} = Employment

export const loadEmployment = () => {
  return dispatch => {
    dispatch(getEmployment())
    const requests = [queryEmployment(), queryLGAEmployment()]
    Promise.all(requests)
      .then(responses => Promise.all(responses.map(r => r.json())))
      .then(data =>
        dispatch(getEmploymentSuccess({ SA2s: data[0], LGAs: data[1] }))
      )
      .catch(err => dispatch(getEmploymentFailure(err)))
  }
}

export const getEmployment = () => ({
  type: GET_EMPLOYMENT,
})
export const getEmploymentSuccess = payload => ({
  type: GET_EMPLOYMENT_SUCCESS,
  payload,
})
export const getEmploymentFailure = payload => ({
  type: GET_EMPLOYMENT_FAILURE,
  payload,
})
export const setEmploymentControls = payload => ({
  type: SET_EMPLOYMENT_CONTROLS,
  payload
})