import React, { PureComponent } from 'react'
import { GeoJSONLayer } from 'react-mapbox-gl'
import PropTypes from 'prop-types'

class LGALayer extends PureComponent {
  render() {
    const { data, before, showLayer } = this.props
    const geojson =
      data && showLayer ? data : { type: 'FeatureCollection', features: [] }
    return (
      <GeoJSONLayer
        before={before}
        data={geojson}
        linePaint={{
          'line-color': '#333',
          'line-opacity': 1,
          'line-width': 2,
        }}
       
      />
    )
  }
}

LGALayer.propTypes = {
  data: PropTypes.object,
  showLayer: PropTypes.bool.isRequired
}

export default LGALayer
