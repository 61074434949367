import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { Icon } from 'antd'
import { Analytics } from 'aws-amplify';

import Row from '../../common/Row'

const IconContainer = styled('div')`
  color: ${props => (props.menu ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.25)')};
  font-size: 20pt;
  visibility: visible;
  @media (min-width: 992px) {
    visibility: hidden;
  }
`
const Container = styled(Row)`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  top: 59px;
  background-color: rgba(80, 90, 105, 0.75);
  border-left: 1px solid rgb(80, 90, 105);
  width: 100%;
  height: calc(100vh - 59px);
  visibility: ${props => (props.menu ? 'visible' : 'hidden')};
  @media (min-width: 992px) {
    flex-direction: row;
    position: inherit;
    right: inherit;
    top: inherit;
    background-color: inherit;
    border-left: inherit;
    width: fit-content;
    height: inherit;
    align-items: center;
    visibility: inherit;
  }
`
const Link = styled(NavLink)`
  padding: 1rem;
  color: ${props => props.theme.white};
  background-color: rgba(80, 90, 105, 0.75);
  border-bottom: 1px solid rgb(80, 90, 105);
  @media (min-width: 992px) { 
    flex-grow: 1;
    margin: 0 0.5rem;
    align-items: flex-start;
    justify-content: center;
    padding: 1rem 0.5rem;
    cursor: pointer;
    align-items: center;
    font-weight: normal;
    text-decoration: none !important;
    background-color: inherit;
    border-bottom: inherit;
  }
`
const AboutNav = styled('div')`
  padding: 1rem;
  color: ${props => props.theme.white};
  background-color: rgba(80, 90, 105, 0.75);
  border-bottom: 1px solid rgb(80, 90, 105);
  display: none;
  @media (min-width: 1200px) {
    display: inherit;
    flex-grow: 1;
    align-items: flex-start;
    justify-content: center;
    padding: 1rem 0.5rem;
    cursor: pointer;
    font-weight: ${props => (props.active ? '600' : 'normal')};
    background-color: inherit;
    border-bottom: inherit;
  }
`
const activeStyle = {
  fontWeight: '800',
  color: 'white',
}

class Navigation extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: this.props.childProps.isAuthenticated,
      userHasAuthenticated: this.props.childProps.userHasAuthenticated,
      handleLogout: this.props.childProps.handleLogout,
      aKey: 0
    };

    window.currentUser = this;
  }

  componentWillMount() {
    this.setState({isAuthenticated: localStorage.getItem("isAuthenticated") === "true"})
    this.setState({aKey: Math.random()});
  }

  componentDidMount() {
    // this.setState({isAuthenticated: localStorage.getItem('isAuthenticated') === "true"})
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    // this.setState({isAuthenticated: localStorage.getItem("isAuthenticated") === "true"})
    return true;
  }

  userHasAuthenticated = authenticated => {
    // this.setState({isAuthenticated: authenticated})
  }

  navLinkClicked = theLink => {
    this.props.toggleMenu();
    Analytics.record({ name: 'NavLinkClicked', attributes: { 
      theLink: theLink }});
  }

  aboutLinkClicked = () => {
    this.props.toggleAbout();
    Analytics.record({ name: 'AboutLinkClicked' });
  }

  render() {
    const { about, menu } = this.props
    const { isAuthenticated } = this.state;
    return (
      <>
        <IconContainer menu={menu}>
          <Icon onClick={this.props.toggleMenu} type="menu" />
        </IconContainer>
        <Container menu={menu} id={"NavDiv"}>
          <AboutNav active={about} onClick={() => {this.aboutLinkClicked()}}>
            About
          </AboutNav>
        { isAuthenticated && (
          <>
            <Link exact to="/" 
              onClick={() => {this.navLinkClicked("MetroAnalysis")}} 
              activeStyle={activeStyle}>
              Metropolitan Analysis
            </Link>
            <Link exact to="/planner" 
              onClick={() => {this.navLinkClicked("ScenarioBuilder")}} 
              activeStyle={activeStyle}>
              Scenario Builder
            </Link>
            <Link exact to="/cba" 
              onClick={() => {this.navLinkClicked("CostBenefitAnalysis")}} 
              activeStyle={activeStyle}>
              Cost Benefit Analysis
            </Link>
            <Link exact to="/logout" 
              onClick={() => {this.navLinkClicked("LogOut")}} 
              activeStyle={activeStyle}>
              Log Out
            </Link> 
          </>       
        )}
        { !(isAuthenticated) && (
          <>
            <Link exact to="/login" 
              onClick={() => {this.navLinkClicked("Login")}} 
              activeStyle={activeStyle}>
              Log In
            </Link>
            <Link exact to="/signup" 
              onClick={() => {this.navLinkClicked("SignUp")}} 
              activeStyle={activeStyle}>
              Sign Up
            </Link>
          </>
        )}

        </Container>
      </>
    )
  }
}

Navigation.propTypes = {
  toggleMenu: PropTypes.func.isRequired,
  toggleAbout: PropTypes.func.isRequired,
  about: PropTypes.bool.isRequired,
  menu: PropTypes.bool.isRequired,
}

export default Navigation
