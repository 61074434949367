import React, { PureComponent } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import PrimaryButton from '../../common/PrimaryButton'

const BtnContainer = styled('div')`
  position: absolute;
  top: 0px;
  left: -137px;
  box-shadow: -2px 0px 3px 0px #b2b2b2;
`

class AddModelBtn extends PureComponent {
  click = () => this.props.add()

  render() {
    return (
      <BtnContainer>
        <PrimaryButton
          style={{ borderRadius: 0 }}
          onClick={this.click}
          icon="left"
        >
          Add model
        </PrimaryButton>
      </BtnContainer>
    )
  }
}

AddModelBtn.propTypes = {
  add: PropTypes.func.isRequired,
}

export default AddModelBtn
