import React, { PureComponent } from 'react'
import styled from '@emotion/styled'
import { Icon, Tooltip } from 'antd'

import Row from '../../common/Row'
const Container = styled('div')`
  margin: 1rem 0;
`
const HeaderResultsContainer = styled(Row)`
  align-items: center;
  border-radius: 4px;
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.25);
`
const TotalText = styled('div')`
  padding: 1.5rem 1rem;
  font-weight: bold;
  flex-grow: 0;
  color: ${props => props.theme.white};
  border-radius: 4px 0 0 4px;
  background-color: ${props => props.theme.darkbluegrey};
`
const HeaderText = styled('div')`
  padding: 1.5rem 1rem;
  background-color: #fff;
  flex-grow: 1;
  border-left: solid 1px #e0e0e0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
const HeaderValue = styled('span')`
  color: ${props => props.theme.topaz};
  font-size: 16px;
`
const Title = styled('div')`
  font-size: 12px;
  color: ${props => props.theme.white};
  font-weight: 600;
  margin: 0.5rem 0;
`
const HeaderResults = ({ newPop, newPatronage, newJobs, lga }) => {
  return (
    <HeaderResultsContainer>
      <TotalText>Total</TotalText>
      <HeaderText>
        New {lga} population near stations <HeaderValue>{newPop}%</HeaderValue>
        <Tooltip title="Increase of total LGA population needed in 1.6km station catchments to meet targets">
          <InfoIcon type="info-circle" />
        </Tooltip>
      </HeaderText>
      <HeaderText>
        New {lga} patronage <HeaderValue>{newPatronage}</HeaderValue>
        <Tooltip title="Increase of total LGA patronage that could be achieved if the necessary targets are met. This is based on the Average weekday patronage for stops within 1.6km buffer around the station">
          <InfoIcon type="info-circle" />
        </Tooltip>
      </HeaderText>
      <HeaderText>
        New Strategic Jobs Required
        <HeaderValue>{newJobs && parseInt(newJobs)}</HeaderValue>
      </HeaderText>
    </HeaderResultsContainer>
  )
}

const InfoIcon = styled(Icon)`
  color: ${props => props.theme.darkgrey};
`

class LGALevelResults extends PureComponent {
  render() {
    const { population, newPatronage, employment, lga } = this.props
    if (!lga || !newPatronage) return null

    const sumNewPatronage = Object.values(newPatronage).reduce((prev, curr) => {
      if (isNaN(curr)) {
        return prev
      } else {
        return prev + curr
      }
    }, 0)

    return (
      <Container>
        <Title>LGA Level Results</Title>

        <HeaderResults
          lga={lga}
          newPop={population}
          newPatronage={sumNewPatronage}
          newJobs={employment}
        />
      </Container>
    )
  }
}

export default LGALevelResults
