import React, { Component } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import AddModelBtn from './AddModelBtn'
import Accessibility from '../../containers/Accessibility'
import Behaviour from '../../containers/Behaviour'
import NodePlace from '../../containers/NodePlace'
import Employment from '../../containers/Employment'
import TravelSurvey from '../../containers/TravelSurvey'

const ModelsOuter = styled('div')`
  position: absolute;
  top: 59px;
  right: 0;
  height: calc(100% - 59px);
  z-index: 2;
  background-color: ${props => props.theme.white};
`
const ModelsInner = styled('div')`
  height: 100%;
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  max-height: 100vh;
  box-shadow: 1px 1px 4px 0 #959595;
`
const modelLookup = {
  accessibility: <Accessibility key={'accessibility'} />,
  'travel behaviour': <Behaviour key={'travel behaviour'} />,
  'node-place analysis': <NodePlace key={'node-place analysis'} />,
  'strategic employment': <Employment key={'strategic employment'} />,
  'travel survey analysis': <TravelSurvey key={'travel survey analysis'} />
}
class ModelContainer extends Component {
  add = name => this.props.addModel(name)
  render() {
    const { show, models, showAddBtn } = this.props
    if (!show) return null
    return (
      <ModelsOuter>
        {showAddBtn && <AddModelBtn add={this.add} />}
        <ModelsInner>{models.map(f => modelLookup[f])}</ModelsInner>
      </ModelsOuter>
    )
  }
}

ModelContainer.propTypes = {
  addModel: PropTypes.func.isRequired,
  toggleShow: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  models: PropTypes.arrayOf(PropTypes.string),
  showAddBtn: PropTypes.bool.isRequired,
}

export default ModelContainer
