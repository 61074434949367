import { ScenarioPlanner } from '../constants/actionTypes'

const {
  SET_LGA,
  SET_ORIGIN_CLUSTER,
  SET_TARGET_CLUSTER,
  SET_EMPLOYMENT_RESULT,
  SET_OCCUPANCY_RATE,
  GET_STRUCTUREPLANS,
  GET_STRUCTUREPLANS_FAILURE,
  GET_STRUCTUREPLANS_SUCCESS,
  SET_STRUCTUREPLAN_POP,
  SET_FUTURE_STN_POP,
  GET_STN_BUFFERS,
  GET_STN_BUFFERS_FAILURE,
  GET_STN_BUFFERS_SUCCESS,
  GET_WORKINGPOP,
  GET_WORKINGPOP_FAILURE,
  GET_WORKINGPOP_SUCCESS,
  TOGGLE_CLUSTERS_ACTIVE,
  SET_EMPLOYMENT_CREATED,
} = ScenarioPlanner

export const initialState = {
  lga: 'Wanneroo (C)',
  lgaCode: 58760,
  originCluster: null,
  targetCluser: null,
  occupancyRate: 2.3,
  structurePlanPop: {},
  futureStnPop: {},
  targetStation: {},
  futureStnPatronage: {},
  clustersActive: ['1'],
  employmentActive: ['0'],
}

export default function(state = initialState, { type, payload }) {
  switch (type) {
    case SET_LGA: {
      return {
        ...state,
        lga: payload.name,
        lgaCode: parseInt(payload.code, 10),
        originCluster: null,
        targetCluser: null,
        occupancyRate: 2.3,
        structurePlanPop: {},
        futureStnPop: {},
        targetStation: {},
        futureStnPatronage: {},
        existingStation: {},
        clustersActive: ['1'],
        employmentCreated: {},
        baseEmployment: {},
      }
    }

    case TOGGLE_CLUSTERS_ACTIVE: {
      return {
        ...state,
        clustersActive: state.clustersActive[0].toString() === '1' ? ['0'] : ['1'],
        employmentActive: state.employmentActive[0].toString() === '1' ? ['0'] : ['1'],
      }
    }
    case SET_ORIGIN_CLUSTER: {
      return {
        ...state,
        originCluster: payload,
        targetCluser: payload,
      }
    }
    case SET_TARGET_CLUSTER: {
      return {
        ...state,
        targetCluser: payload,
      }
    }
    case SET_EMPLOYMENT_RESULT: {
      return {
        ...state,
        employmentResult: payload,
      }
    }

    case SET_OCCUPANCY_RATE: {
      return {
        ...state,
        occupancyRate: payload,
      }
    }

    case SET_STRUCTUREPLAN_POP: {
      let newStructurePlanPop = Object.assign({}, state.structurePlanPop)
      const { newPop, station } = payload
      if (station) {
        newStructurePlanPop[station] = newPop
        return {
          ...state,
          structurePlanPop: newStructurePlanPop,
        }
      } else {
        return state
      }
    }

    case GET_STRUCTUREPLANS: {
      return {
        ...state,
        fetching: true,
      }
    }

    case GET_STRUCTUREPLANS_SUCCESS: {
      return {
        ...state,
        structureplans: payload,
        fetching: false,
      }
    }

    case GET_STRUCTUREPLANS_FAILURE: {
      return {
        ...state,
        fetching: false,
        error: payload,
      }
    }

    case SET_FUTURE_STN_POP: {
      const { pop, station, origin, target, patronage } = payload
      const futureStnPop = {
        ...state.futureStnPop,
        [station]: pop,
      }
      const targetStation = { ...state.targetStation, [station]: target }

      const existingStation = { ...state.existingStation, [station]: origin }
      const futureStnPatronage = {
        ...state.futureStnPatronage,
        [station]: patronage,
      }

      return {
        ...state,
        futureStnPop,
        targetStation,
        futureStnPatronage,
        existingStation,
      }
    }

    case GET_STN_BUFFERS: {
      return {
        ...state,
        fetching: true,
      }
    }

    case GET_STN_BUFFERS_SUCCESS: {
      return {
        ...state,
        stnBuffers: payload,
        fetching: false,
      }
    }

    case GET_STN_BUFFERS_FAILURE: {
      return {
        ...state,
        fetching: false,
        error: payload,
      }
    }

    case GET_WORKINGPOP: {
      return {
        ...state,
        fetching: true,
      }
    }

    case GET_WORKINGPOP_SUCCESS: {
      return {
        ...state,
        workingPop: payload,
        fetching: false,
      }
    }

    case GET_WORKINGPOP_FAILURE: {
      return {
        ...state,
        fetching: false,
        error: payload,
      }
    }

    case SET_EMPLOYMENT_CREATED: {
      return {
        ...state,
        employmentCreated: payload.newEmployment,
        baseEmployment: { ...state.baseEmployment, ...payload.existingBase },
      }
    }
    default:
      return state
  }
}
