import { Accessibility } from '../constants/actionTypes'
import { setLoading } from './loading'
import { setSA2Attributes } from './sa2layer'
import { setMode } from './maptoolbox'
import {
  queryAccessibilityComparision,
  queryAccessibilityByTime,
  queryAccessibilityTT,
} from '../queries/accessibility'
import config from '../config/sa2Layer'
import { scaleThreshold } from 'd3-scale'
const stops = config['traveltime']
const scale = scaleThreshold()
  .domain(stops.map(f => f[0]))
  .range(stops.map(f => f[1]))

const {
  SET_SA2_TRAVELTIME,
  SET_ACCESS_DATA,
  SET_COMPARISON_DATA,
  SET_METRIC_STAGING,
  SET_MODE_STAGING,
  SET_PERIOD_STAGING,
  SET_DIRECTION_STAGING,
  SET_TIME_STAGING,
  UPDATE_PARAMS,
} = Accessibility

export const runModel = () => {
  return dispatch => {
    dispatch(setLoading(true))
    dispatch(setComparisonData(null))
    Promise.all([
      dispatch(getAccessibilityComparison()),
      dispatch(getAccessibilityTT()),
      dispatch(getAccessibilityByTime()),
    ]).then(() => {
      dispatch(setMode('Travel time'))
      dispatch(updateParams())
      dispatch(setLoading(false))      
    })
  }
}

function getAccessibilityComparison() {
  return (dispatch, getState) => {
    const state = getState()
    const {
      stagingDirection,
      stagingTime,
      stagingMetric,
      stagingMode,
      stagingPeriod,
    } = state.accessibility
    const {
      selectedHub: { Accessibility_SA2 },
    } = state.hubs
    const payload = {
      direction: stagingDirection,
      time: stagingTime,
      metric: stagingMetric,
      zoneID: Accessibility_SA2,
      travel_mode: stagingMode,
      period: stagingPeriod,
    }
    return queryAccessibilityComparision(payload)
      .then(res => res.json())
      .then(
        data => dispatch(setComparisonData(data)),
        err => console.log('error with accessibility comparator', err)
      )
  }
}

function getAccessibilityByTime() {
  return (dispatch, getState) => {
    const state = getState()
    const {
      stagingDirection,
      stagingMetric,
      stagingTime,
      stagingMode,
      stagingPeriod,
    } = state.accessibility
    const payload = {
      direction: stagingDirection,
      metric: stagingMetric,
      time: stagingTime,
      travel_mode: stagingMode,
      period: stagingPeriod,
    }

    return queryAccessibilityByTime(payload)
      .then(res => res.json())
      .then(
        data => dispatch(setAccessData(data)),
        err => console.log('error with access all time', err)
      )
  }
}

function getAccessibilityTT() {
  return (dispatch, getState) => {
    const state = getState()
    const {
      stagingDirection,
      stagingTime,
      stagingMode,
      stagingPeriod,
    } = state.accessibility
    const { selectedHub } = state.hubs
    const { Accessibility_SA2 } = selectedHub
    const payload = {
      direction: stagingDirection,
      time: stagingTime,
      zoneID: Accessibility_SA2,
      travel_mode: stagingMode,
      period: stagingPeriod,
    }
    return queryAccessibilityTT(payload)
      .then(res => res.json())
      .then(
        data => {
          dispatch(setSA2traveltime({ selectedHub }))
          dispatch(
            setSA2Attributes({ attributes: data, type: 'traveltime', scale })
          )
        },
        err => console.log('error with accessibility tt', err)
      )
  }
}

export const setAccessData = payload => ({
  type: SET_ACCESS_DATA,
  payload,
})

export const setSA2traveltime = payload => ({
  type: SET_SA2_TRAVELTIME,
  payload,
})

export const setComparisonData = payload => ({
  type: SET_COMPARISON_DATA,
  payload,
})

export const setMetricStaging = payload => ({
  type: SET_METRIC_STAGING,
  payload,
})

export const setModeStaging = payload => ({
  type: SET_MODE_STAGING,
  payload,
})

export const setPeriodStaging = payload => ({
  type: SET_PERIOD_STAGING,
  payload,
})

export const setDirectionStaging = payload => ({
  type: SET_DIRECTION_STAGING,
  payload,
})

export const setTimeStaging = payload => ({
  type: SET_TIME_STAGING,
  payload,
})

export const updateParams = payload => ({
  type: UPDATE_PARAMS,
  payload,
})
