import React from "react";
import styled from '@emotion/styled';

const NotFound = styled('div')`
    padding-top: 100px;
    text-align: center;
`

export default () =>
    <NotFound>
        <h3>Sorry, page not found!</h3>
    </NotFound>;
