import { createSelector } from 'reselect'

const existingStationSelector = state => state.scenarioplanner.existingStation
const futureStationPatSelector = state => state.scenarioplanner.futureStnPatronage
const futureStationPopSelector = state => state.scenarioplanner.futureStnPop
const targetStationSelector = state => state.scenarioplanner.targetStation

const getProp = (obj, prop) => obj && obj.hasOwnProperty(prop)? obj[prop] : null

export const selectorClusterScenario = createSelector(
    existingStationSelector,
    futureStationPatSelector,
    futureStationPopSelector,
    targetStationSelector,
    (existing, futurePat, futurePop, targetStn) => {
        if(!existing || !futurePat || !futurePop || !targetStn) return null
        const data = Object.keys(existing).map( key=> {
            const station = getProp(existing, key)
            const newPatronage = getProp(futurePat, key)
            const newPop = getProp(futurePop, key)
            const target = getProp(targetStn, key)
            return {
                name: key,
                existing: {...station},
                newPatronage,
                newPop: !isNaN(newPop)? +newPop:newPop,
                target
            }
        })
        return data.filter(f => (!f.target) || (f.target && f.name !== f.target.name))
    }
)