import { createSelector } from 'reselect'

const populationSelector = state => state.population
const lgaSelector = state => state.scenarioplanner.lga
const futureStnPopSelector = state => state.scenarioplanner.futureStnPop

export const selectorLGAPopulation = createSelector(
  populationSelector,
  lgaSelector,
  futureStnPopSelector,
  (population, lga, futureStnPop) => population.medium[lga] &&
  Object.values(futureStnPop).reduce(
    (prev, curr) => prev + parseFloat(curr),
    0
  ).toFixed(2),
)

export const selectorLGAName = createSelector(
  lgaSelector,
  lga=> lga.replace(/ *\([^)]*\) */g, "")
)