import React, { PureComponent } from 'react'
import styled from '@emotion/styled'
import { connect } from 'react-redux'
import { Form } from 'antd'

import { setYourMoveParams } from '../../actions/costbenefitanalysis'

import FormNumberInput from './FormNumberInput'
import FormSelect from './FormSelect'
import FormDecimalNumber from './FormDecimalNumber'

import Row from '../../common/Row'
import Column from '../../common/Column'
import Subtitle from '../../common/Subtitle'
import PrimaryButton from '../../common/PrimaryButton'

import config from './config'

const CreateForm = Form.create

const FormContainer = styled(Form)`
    display:flex;
    width:330px;
    margin-right:1rem;
`
const Container = styled(Column)`
    padding: 2rem;
    border-radius:3px;
    flex: 1 1 0;
    background-color: ${props => props.theme.lightgrey};
`
const SubmitContainer = styled(Row)`
    margin-bottom:1rem;
    justify-content:space-between;
    align-items:center;
`
const FormRow = styled(Row)`
    justify-content: space-between;
`
const WIDTH = 200

class Inputs extends PureComponent {

    componentDidMount() {
        const startState = Object.assign(...Object.keys(config).map(key=> {
            const { name, initialValue } = config[key]
            return {
                [name]: initialValue
            }
        }))
        this.props.setYourMoveParams(startState)
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.handleSubmit(values)
            }
        });
    }

    handleInfo = name => this.props.handleInfo(name)

    render() {
        const { getFieldDecorator } = this.props.form
        return (
            <FormContainer layout={'vertical'}>
                <Container>
                    <SubmitContainer>
                        <Subtitle>Inputs</Subtitle>
                        <PrimaryButton onClick={this.handleSubmit}>Submit</PrimaryButton>
                    </SubmitContainer>
                    <Column>
                        <FormSelect data={config.appraisalPeriod} getFieldDecorator={getFieldDecorator} updateLabel={this.handleInfo} width={WIDTH}/>
                        <FormSelect data={config.reductionRate} getFieldDecorator={getFieldDecorator} updateLabel={this.handleInfo} width={WIDTH}/>
                        <FormRow>
                            <FormNumberInput data={config.capex} getFieldDecorator={getFieldDecorator} updateLabel={this.handleInfo} width={WIDTH}/>
                            <FormNumberInput data={config.opex} getFieldDecorator={getFieldDecorator} updateLabel={this.handleInfo} width={WIDTH}/>
                        </FormRow>
                        <FormNumberInput data={config.participatingHousehold} getFieldDecorator={getFieldDecorator} updateLabel={this.handleInfo} width={WIDTH}/>
                        <FormDecimalNumber data={config.averagePublicTransportFare} getFieldDecorator={getFieldDecorator} updateLabel={this.handleInfo} width={WIDTH}/>      
                    </Column>
                </Container>
            </FormContainer>
        )
    }
}
const ConnectedInputs = connect()(CreateForm({ 
    name: 'yourMove', 
    onValuesChange(props, values){props.dispatch(setYourMoveParams(values))}
})(Inputs))
export default ConnectedInputs
