import React, { PureComponent } from 'react';
import styled from '@emotion/styled'
import { Tooltip, Button, Icon } from 'antd'

import Heading from '../../common/Heading'
import Subtitle from '../../common/Subtitle'
import IconButtonMini from '../../common/IconButtonMini'

const DescriptionContainer = styled('div')`
    margin: 0.25rem 0 1rem 0;
    font-size: 0.75rem;
`

const HeaderContainer = styled('div')`
    display: flex;
    flex-direction: row;
`

const HeaderInnerContainer = styled('div')`
    flex-grow: 1;
`

const AcademicContainer = styled('div')`
    width: 100%;
    margin: 1rem 0;
`

export class ModelHeader extends PureComponent {
    constructor(props) {
        super(props);
        this.state = { isOpen: false };
    }

    changeState = () =>  {
        if (this.state.isOpen === true) {
            this.setState({isOpen: false})
        } else {
            this.setState({isOpen: true})
        }
    }

    render() {
        
        const { isOpen } = this.state;
        const { heading, description, academic } = this.props;

        return (
            <>
                {heading && (
                    <HeaderContainer>
                        <HeaderInnerContainer>
                            <Heading>{heading}</Heading>
                        </HeaderInnerContainer>
                        { isOpen && (
                            <IconButtonMini shape={'circle'} icon="minus-circle"
                                size={'small'} onClick={this.changeState} />
                        )}
                        { !isOpen && (
                            <IconButtonMini shape={'circle'} icon="plus-circle"
                                size={'small'} onClick={this.changeState} />
                        )}
                    </HeaderContainer>
                )}{ description && (
                    <DescriptionContainer>
                        { isOpen && (
                            <>
                                {description}
                            </>
                        )}
                        { !isOpen && (
                            <>
                                {description.split(" ").slice(0, 20).join(" ")}...
                            </>
                        )}
                        
                    </DescriptionContainer>
                )}{ academic && (
                    <AcademicContainer>
                        <Button block style={{fontSize: '0.75rem'}} 
                            href={academic}>
                            <Icon style={{verticalAlign: '0.125em'}} 
                                type="file-text" />
                            Download Academic Paper
                        </Button>
                    </AcademicContainer>
                )}
            </>
        )
    }
}

export class HeaderTooltip extends PureComponent {
    render() {
        const { sub, heading, description } = this.props;
        return (
            <>
                <HeaderContainer>
                    <HeaderInnerContainer>
                        {sub && (
                            <Subtitle>{heading}</Subtitle>
                        )}
                        {!sub && (
                            <Heading>{heading}</Heading>
                        )}
                    </HeaderInnerContainer>
                    <Tooltip title={`${description}`}>
                        <IconButtonMini shape={'circle'} icon="info-circle" 
                            size={'small'} />
                    </Tooltip>
                </HeaderContainer>
            </>
        )
    }
}