import React from 'react'
import ReactDOM from 'react-dom'
import { injectGlobal } from "emotion"
import { Provider } from 'react-redux'
import configureStore from './store/configureStore.js'
import App from './containers/App'

import { loadAllInitData } from './actions/loading'
import Amplify from "aws-amplify";

const store = configureStore()

store.dispatch(loadAllInitData())

injectGlobal`
  @import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600');
  
  * {
    box-sizing: border-box;
  }

  html,
  body,
  #root {
    min-height: 100%;
    min-height: 100vh;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    font-family: 'Open Sans', sans-serif;
    font-size:16px;
    overflow-x: hidden;
    color:#333;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
  }
`
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
)

// Initialise AWS Cognito via Amplify for user logins as well as AWS Pinpoint.
Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID
  },
  Analytics: {
    disabled: false,
    autoSessionRecord: true,
    AWSPinpoint: {
      appId: process.env.REACT_APP_PINPOINT_APP_ID,
      region: process.env.REACT_APP_PINPOINT_REGION
    }
  }
});