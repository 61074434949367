const rootUrl = process.env.REACT_APP_API

export function queryMetadata() {
  const url = `${rootUrl}/retrieveContextLayers`
  const params = {
    mode: 'metadata',
    format: 'json'
  }

  return fetch(url, {
    method: 'POST',
    mode: 'cors',
    headers: { 'Content-Type': 'application/json; charset=utf-8' },
    body: JSON.stringify(params)
  })
}

export function queryGeometry(geometry) {
  const url = `${rootUrl}/retrieveContextLayers`
  const params = {
    mode: 'geometry',
    format: 'json',
    geometry: `${geometry}`
  }

  return fetch(url, {
    method: 'POST',
    mode: 'cors',
    headers: { 'Content-Type': 'application/json; charset=utf-8' },
    body: JSON.stringify(params)
  })
}

export function queryAttribute(attribute) {
  const url = `${rootUrl}/retrieveContextLayers`
  const params = {
    mode: 'attribute',
    format: 'json',
    attribute: `${attribute}`
  }

  return fetch(url, {
    method: 'POST',
    mode: 'cors',
    headers: { 'Content-Type': 'application/json; charset=utf-8' },
    body: JSON.stringify(params)
  })
}