import React, { PureComponent } from 'react'
import styled from '@emotion/styled'
import { XYPlot, HorizontalBarSeries, LabelSeries , DiscreteColorLegend} from 'react-vis'
import Column from '../../../common/Column'

const NoDataContainer = styled(Column)`
  align-items: center;
  justify-content: center;
  flex: 1 1 0;
`
const DiscreteColorLegendContainer = styled(DiscreteColorLegend)`
  font-size: 11px;
  & .rv-discrete-color-legend-item {
    padding: 4px;
  }
`

const checkEmpty = data => data.reduce((acc,curr)=>{ return acc + curr.x}, 0) > 0

class StaysComparisonChart extends PureComponent {
  render() {
    const { target, origin, labels, axisLabels, legend } = this.props.data
    if(!checkEmpty(target.data) && !checkEmpty(origin.data)) return <NoDataContainer>No Data Available</NoDataContainer>

    return (
      <XYPlot
        animation
        margin={{ left: 80, right: 50, top: 5, bottom: 5 }}
        width={250}
        height={255}
        yType="literal"
        key={'activities-chart'}
        colorType="category"
        colorDomain={target.data.map(f => f.alias)}
        colorRange={target.data.map(f => f.color)}
      >
        <HorizontalBarSeries
          barWidth={0.8}
          margin={{ left: 20, bottom: 10 }}
          stroke={'#fff'}
          data={origin.data}
          key={'origin'}
          getY={d => d.y}
          getCluster={d => d.cluster}
          color={origin.color}
        />
        <HorizontalBarSeries
          barWidth={0.8}
          margin={{ left: 20, bottom: 10 }}
          stroke={'#fff'}
          data={target.data}
          key={'target'}
          getCluster={d => d.cluster}
          getY={d => d.y}
          color={target.color}
        />
        <LabelSeries
          getX={d => -10}
          getY={d => d.y}
          getLabel={d => d.label}
          labelAnchorY={'middle'}
          labelAnchorX={'end'}
          data={axisLabels}
          style={{
            fontSize: 12,
          }}
          marginLeft={70}
          key={'label'}
          xOffset={-20}
        />
        <LabelSeries
          getLabel={d => d.label}
          labelAnchorY={'middle'}
          labelAnchorX={'start'}
          data={labels}
          style={{ fontSize: 10 }}
          key={'label2'}
          xOffset={20}
          marginLeft={90}
        />
        <DiscreteColorLegendContainer
          items={legend}
          orientation={'vertical'}
        />
      </XYPlot>
    )
  }
}

export default StaysComparisonChart
