import React, { Component } from 'react'
import styled from '@emotion/styled'
import PopulationForecast from './PopulationForecast'
import MicroMap from '../../containers/MicroMap'
import SelectLGA from '../../containers/SelectLGA'
import ChangeCluster from '../../containers/ChangeCluster'
import LGALevelResults from '../../containers/LGALevelResults'
import DashboardSwitcher from './DashboardSwitcher'
import EmploymentCalculator from '../../containers/EmploymentCalculator'
import PrintBtn from './PrintBtn'
import PrintView from '../../containers/PrintView'
import { Redirect } from 'react-router-dom'

const OuterContainer = styled('div')`
  height: calc(100% - 59px);
  width: 100%;
  overflow: auto;
  position: absolute;
  top: 59px;
  left: 0;
  margin: 0;
  height: calc(100% - 59px);
  background-color: ${props => props.theme.bluegrey};
`
const Container = styled('div')`
  display: flex;
  margin: auto;
  width: 1420px;
  flex-direction: row;
  padding: 1rem;
`
const LeftPanel = styled('div')`
  width: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
`
const RightPanel = styled('div')`
  padding-left: 1rem;
  min-width: 900px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`
const TopLeftContainer = styled('div')`
  border-radius: 4px;
  background-color: ${props => props.theme.white};
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.25);
`

class ScenarioView extends Component {
  
  state = {
    visible: false,
    isAuthenticated: false
  }

  toggle = visible => this.setState({visible})

  componentWillMount() {
    this.setState({isAuthenticated: localStorage.getItem("isAuthenticated") 
      === "true"});
  }
  
  render() {
    const { visible } = this.state

    if (this.state.isAuthenticated) {
      return (
        <>
          <PrintView visible={visible} toggle={this.toggle}/>
          <OuterContainer>
            <Container>
              <LeftPanel>
                <TopLeftContainer>
                  <SelectLGA/>
                  <MicroMap width={330} margin={0.75} height={273}/>
                  <DashboardSwitcher/>
                </TopLeftContainer>
                <PopulationForecast/>
                <PrintBtn toggle={this.toggle}/>
              </LeftPanel>
              <RightPanel>
                <ChangeCluster/>
                <EmploymentCalculator/>
                <LGALevelResults/>
              </RightPanel>
            </Container>
          </OuterContainer>
        </>
      )
    } else {
      return <Redirect to={"/login"}></Redirect>;
    }
  }
}

export default ScenarioView
