const rootUrl = process.env.REACT_APP_API

export function queryTravelSurvey() {
    const url = `${rootUrl}/callmodel`
    const params = {
        results_param: 'accessResults',
        model: 'travelSurvey',
        req_params: {
            format: 'json',
        },
    }

    return fetch(url, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify(params),
    })
}
