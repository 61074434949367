import React, { Component } from 'react'
import styled from '@emotion/styled'
import { ThemeProvider } from 'emotion-theming'
import { BrowserRouter as Router } from 'react-router-dom'

import 'antd/dist/antd.css'
import Routes from "../Routes";
import theme from '../constants/theme'

import Loading from './Loading'
import Header from './Header'
import AboutPage from './AboutPage'

import { Auth } from "aws-amplify"

const AppContainer = styled('div')`
  height: 100%;
  min-height: 100vh;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
`

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: false,
      isAuthenticating: true
    };
  }

  async componentDidMount() {
    try {
      await Auth.currentSession();
      this.userHasAuthenticated(true);
    }
    catch(e) {
      if (e !== 'No current user') {
        // alert(e);
      }
    }
    this.setState({ isAuthenticating: false });
  }

  userHasAuthenticated = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return true;
  }

  handleLogout = async event => {
    await Auth.signOut();
    this.userHasAuthenticated(false);
    this.props.history.push("/login");
  }

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      handleLogout: this.handleLogout,
      alertDisplayedCBA: true
    };
    return (
      <ThemeProvider theme={theme}>
        <AppContainer>
          <Loading/>
          <Router>
            <AboutPage props={childProps} />
            <Header history={this.props.history} childProps={childProps} />
            <Routes childProps={childProps} />
          </Router>
        </AppContainer>
      </ThemeProvider>
    )
  }
}

export default App
