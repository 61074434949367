import { connect } from 'react-redux'
import SelectLGA from '../components/ScenarioPlanner/SelectLGA'
import { setLGA } from '../actions/scenarioplanner'
import {selectorLGA} from "../selectors/selectlga-selector"

export default connect(
  state => {
    return {
      lga: state.scenarioplanner.lga,
      lgas: selectorLGA(state),
    }
  },
  { setLGA }
)(SelectLGA)
