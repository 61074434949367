import { Behaviour } from '../constants/actionTypes'
const {
  GET_ACTIVITIES,
  GET_ACTIVITIES_SUCCESS,
  GET_ACTIVITIES_FAILURE,
} = Behaviour

export const initialState = {
  data: null,
  fetching: false,
  error: false,
}

export default function(state = initialState, { type, payload }) {
  switch (type) {
    case GET_ACTIVITIES: {
      return {
        ...state,
        fetching: true,
      }
    }
    case GET_ACTIVITIES_SUCCESS: {
      return {
        ...state,
        data: payload,
        error: false,
        fetching: false,
      }
    }
    case GET_ACTIVITIES_FAILURE: {
      return {
        ...state,
        error: true,
        fetching: false,
      }
    }
    default:
      return state
  }
}
