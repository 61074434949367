import { createSelector } from 'reselect'
import { mean } from 'd3-array'

export const selectedHubSelector = state => state.hubs.selectedHub
const employmentSelector = state => state.employment.data
const employmentLGASelector = state => state.employment.LGAdata
const hubsSelector = state => state.hubs.data
const lgaSelector = state => state.scenarioplanner.lga

export const selectorEmployment = createSelector(
  employmentSelector,
  selectedHubSelector,
  (employment, selectedHub) => {
    return employment[selectedHub['Accessibility_SA2']]
  }
)

export const selectorComparisonEmployment = createSelector(
  employmentSelector,
  hubsSelector,
  (employment, hubs) => {
    let employmentKeys = Object.keys(employment).filter(key => {
      let found = false
      hubs.forEach(hub => {
        if (hub['Accessibility_SA2'].toString() === key.toString()) {
          found = true
        }
      })
      return found
    })
    let result = []
    let sumS = 0,
      sumP = 0
    Object.keys(employment).forEach(key => {
      if (employmentKeys.includes(key)) {
        const item = employment[key]
        const Strategic = item['set1']['Strategic Jobs'],
          PopulationJobs = item['set1']['Population-driven']
        if (!isNaN(Strategic / PopulationJobs)) {
          sumS += Strategic
          sumP += PopulationJobs
        }
        result.push({
          y: !isNaN(Strategic / PopulationJobs)
            ? Strategic / PopulationJobs
            : null,
          sa2: key,
        })
      }
    })

    const resultSorted = result
      .sort((a, b) => a.y - b.y)
      .map((f, x) => ({ ...f, x }))

    let average = [
      { x: 0, y: sumS / sumP },
      { x: resultSorted[resultSorted.length - 1].x, y: sumS / sumP },
    ]

    return { result: resultSorted, average }
  }
)

export const selectorLGAComparisonEmployment = createSelector(
  employmentLGASelector,
  hubsSelector,
  (employment, hubs) => {
    if (employment && hubs) {
      const hubLGAs = hubs.map(f => f.LGA)
      const hubKeys = Object.keys(employment)
        .map(f => +f)
        .filter(f => hubLGAs.includes(f))
      const lgasUnsorted = hubKeys.map(key => {
        const item = employment[key]['set1a']

        return {
          key,
          name: item['LGA NAME'],
          y: item['Ratio per LGA'],
        }
      })
      const lgas = lgasUnsorted
        .sort((a, b) => a.y - b.y)
        .map((f, x) => ({ ...f, x }))

      let lqs = {}
      let multipliers = {}
      hubKeys.forEach(key => {
        const lq = employment[key]['lq']
        const name = employment[key]['set1a']['LGA NAME']
        const multiplier = employment[key]['multipliers']
        lqs[name] = lq
        multipliers[name] = multiplier
      })
      const meanY = mean(lgas, f => f.y)
      const average = [
        { x: lgas[0].x, y: meanY },
        { x: lgas[lgas.length - 1].x, y: meanY },
      ]

      return {
        average,
        lgas,
        multipliers,
        lqs,
      }
    } else {
      return null
    }
  }
)

export const selectorLGACalcEmployment = createSelector(
  employmentLGASelector,
  hubsSelector,
  lgaSelector,
  (employment, hubs, lga) => {
    if (employment && hubs) {
      const hubLGAs = hubs.map(f => f.LGA)
      const hubKeys = Object.keys(employment)
        .map(f => +f)
        .filter(f => hubLGAs.includes(f))

      let lqs = {}
      let multipliers = {}
      let emps = {}
      let ratios = {}

      hubKeys.forEach(key => {
        const lq = employment[key]['lq']
        const name = employment[key]['set1a']['LGA NAME']
        const multiplier = employment[key]['multipliers']
        const emp = employment[key]['employment']
        const ratio = employment[key]['set1a']
        if (lga.includes(name)) {
          lqs = lq
          multipliers = multiplier
          emps = emp
          ratios = ratio
        }
      })

      return {
        multipliers,
        lqs,
        emps,
        ratios,
      }
    } else {
      return null
    }
  }
)
