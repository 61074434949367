import { createSelector } from 'reselect'

import { selectorCombineAttribute } from './contextLayer-selector'
import { selectorShowRunModel } from './accessibility-selector'

const modelsSelector = state => state.modelcontainer.models
const showContainerSelector = state => state.modelcontainer.show
const selectedHubSelector = state => state.hubs.selectedHub
const ttselectedHubSelector = state => state.accessibility.ttSelectedHub

const geoSelector = state => state.sa2layer.geo
const sa2DataSelector = state => state.sa2layer.data
const modelNameSelector = state => state.sa2layer.modelName
const maptoolboxModeSelector = state => state.maptoolbox.selectedMode

// directly explaining what we are checking for
const isThePanelOpen = arr => arr.includes('accessibility')
const isTheModelContainerOpen = show => show
const isThereASelectedHub = hub => hub
const doesOurTThubMatchSelectedHub = (ttHub, currentHub) => ttHub === currentHub
const notCurrentlyShowingTraveltime = modelName => modelName !== 'traveltime'

export const selectorShowContextLayer = createSelector(
  selectorCombineAttribute,
  maptoolboxModeSelector,
  (contextData, maptoolboxMode) => {
    const { geometry } = contextData
    // show if there is geometry returned, its not a poylgon or its a polygon and on context layer mode
    return geometry && (isNotPolygon(geometry) || isPolygonAndContext(geometry, maptoolboxMode))? true : false
  }
)

export const selectorShowEmploymentLayer = createSelector(
  selectorCombineAttribute,
  maptoolboxModeSelector,
  (contextData, maptoolboxMode) => {
    const { geometry } = contextData
    // show if no geometry or not on Context mode
    if(!geometry || maptoolboxMode === 'Employment') return true
    // show if there is geometry and the geometry is not a polygon 
    return geometry && (isNotPolygon(geometry))? true : false
  }
)
export const selectorShowSA2Layer = createSelector(
  modelsSelector,
  showContainerSelector,
  selectedHubSelector,
  ttselectedHubSelector,
  modelNameSelector,
  selectorShowRunModel,
  (models, show, selectedHub, tthub, modelName, dontShowTT) => {
    if (notCurrentlyShowingTraveltime(modelName) ) return true
    return isThePanelOpen(models) &&
      isTheModelContainerOpen(show) &&
      isThereASelectedHub(selectedHub) &&
      doesOurTThubMatchSelectedHub(selectedHub, tthub) &&
      !dontShowTT
      ? true
      : false
  }
)

export const selectorCombineLayer = createSelector(
  geoSelector,
  sa2DataSelector,
  (geo, data) => {
    if (geo && data && data.attributes && data.type && data.scale) {
      const { attributes, type, scale } = data
      if (type === 'traveltime') {
        // traveltime SA2s are a bit unique
        return createLayer(geo, attributes, scale)
      } else {
        return createLayer(geo, attributes, scale)
      }
    } else {
      return null
    }
  }
)

function createLayer(geo, attributes, scale) {
  // attribute that contains the SA2 id
  const joinedOn = 'SA2_MAIN16'
  // remove any features that arent included in the returned SA2s
  const filteredFeatures = geo.features.filter(
    feature =>
      attributes[+feature.properties[joinedOn]] &&
      !isNaN(attributes[+feature.properties[joinedOn]])
  )
  // add on the correct time to SA2
  const features = filteredFeatures.map(feature => {
    const joinedVal = +feature.properties[joinedOn]
    const color = scale(+attributes[joinedVal])
    const properties = {
      color,
    }
    return {
      ...feature,
      properties,
    }
  })
  const fc = {
    ...geo,
    features,
  }
  return fc
}

function isPolygonAndContext(geometry, mode){
  return (
    mode === 'Context' &&
    geometry &&
    geometry.features.length > 0 &&
    (geometry.features[0].geometry.type === 'Polygon' ||
    geometry.features[0].geometry.type === 'MultiPolygon')
  )
}
function isNotPolygon(geometry){
  return (
    geometry &&
    geometry.features.length > 0 &&
    (geometry.features[0].geometry.type !== 'Polygon' &&
    geometry.features[0].geometry.type !== 'MultiPolygon')
  )
}