import { queryMetadata, queryGeometry, queryAttribute } from '../queries/context'
import { Context } from '../constants/actionTypes'

const { GET_METADATA, GET_METADATA_SUCCESS, GET_METADATA_FAILURE, SELECT_LAYER, 
  GET_ATTRIBUTE, GET_ATTRIBUTE_SUCCESS, GET_ATTRIBUTE_FAILURE, GET_GEOMETRY, 
  GET_GEOMETRY_SUCCESS, GET_GEOMETRY_FAILURE, SET_VALUE, SET_STYLING, 
  SELECT_FEATURE } = Context

/* Get Metadata */
export const loadMetadata = () => {
  return dispatch => {
    dispatch(getMetadata())
    queryMetadata()
      .then(res => res.json())
      .then(data => dispatch(getMetadataSuccess(data)))
      .catch(err => dispatch(getMetadataFailure(err)))
  }
}

export const getMetadata = () => ({
  type: GET_METADATA
})

export const getMetadataSuccess = payload => ({
  type: GET_METADATA_SUCCESS,
  payload,
})

export const getMetadataFailure = payload => ({
 type: GET_METADATA_FAILURE,
 payload,
})

/* Layer Selection */
export const chooseLayer = payload => ({
  type: SELECT_LAYER,
  payload,
})

export const selectLayer = payload => {
  return dispatch => {
    dispatch(chooseLayer(payload))
  }
}

/* Get Attribute Data */
export const loadAttribute = attribute => {
  return dispatch => {
    dispatch(getAttribute())
    queryAttribute(attribute)
      .then(res => res.json())
      .then(data => dispatch(getAttributeSuccess(data)))
      .catch(err => dispatch(getAttributeFailure(err)))
  }
}

export const getAttribute = () => ({
  type: GET_ATTRIBUTE
})

export const getAttributeSuccess = payload => ({
  type: GET_ATTRIBUTE_SUCCESS,
  payload,
})

export const getAttributeFailure = payload => ({
  type: GET_ATTRIBUTE_FAILURE,
  payload,
})

/* Get Geometry Data */
export const loadGeometry = geometry => {
  return dispatch => {
    dispatch(getGeometry())
    queryGeometry(geometry)
      .then(res => res.json())
      .then(data => dispatch(getGeometrySuccess(data)))
      .catch(err => dispatch(getGeometryFailure(err)))
  }
}

export const getGeometry = () => ({
  type: GET_GEOMETRY
})

export const getGeometrySuccess = payload => ({
  type: GET_GEOMETRY_SUCCESS,
  payload,
})

export const getGeometryFailure = payload => ({
  type: GET_GEOMETRY_FAILURE,
  payload,
})

/* Select Values - i.e. attributes */
export const selectAttribute = payload => {
  return dispatch => {
    dispatch(chooseAttribute(payload))
  }
}

export const chooseAttribute = payload => ({
  type: SET_VALUE,
  payload,
})

/* Choose Styling */
export const setStyling = payload => {
  return dispatch => {
    dispatch(chooseStyling(payload))
  }
}

export const chooseStyling = payload => ({
  type: SET_STYLING,
  payload,
})

/* Choose Feature */
export const selectFeature = payload => {
  return dispatch => {
    dispatch(chooseFeature(payload))
  }
}

const chooseFeature = payload => ({ 
  type: SELECT_FEATURE, 
  payload,
})