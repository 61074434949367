export default {
    appraisalPeriod: {
        name: 'AppraisalPeriod',
        initialValue: 10,
        options: [
            4,
            7,
            10,
            30
        ],
        label: 'Analysis period (yrs)',
        info: 'You can choose from a selection of 4, 7, 10 and 30 year analysis periods. The standard analysis period is usually 10 years for this type of investment. The longer the analysis period is, the longer the program will run, therefore the higher benefits.'
    },
    reductionRate: {
        name: 'ReductionRate',
        initialValue: '0%',
        options: [
            '0%',
            '10%',
            '25%'
        ],
        label: 'Effect reduction rate',
        info: 'You can choose from a selection of no effect reduction, or effect reduction of 10% or 25% per annum to be considered in the analysis. When applied, the change in modal share will be reduced accordingly by presuming that the effect of the program reduced over time. '
    },
    opex: {
        name: 'OPEX',
        initialValue: 10000,
        label: 'Operating cost (OPEX)',
        info: 'You can input any CAPEX and OPEX. The analysis presumes CAPEX will be spent in the first year and OPEX will be spent in the subsequent years.',
        formatter: value => `$${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} /yr`,
        parser: value => parseFloat(value.replace(/\D/g,''))
    },
    capex: {
        name: 'CAPEX',
        initialValue: 3640000,
        label: 'Program cost (CAPEX)',
        info: 'You can input any CAPEX and OPEX. The analysis presumes CAPEX will be spent in the first year and OPEX will be spent in the subsequent years.',
        formatter: value => `$${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
        parser: value => parseFloat(value.replace(/\D/g,''))
    },
    participatingHousehold: {
        name: 'ParticipatingHousehold',
        initialValue: 10000,
        label: 'Participating household',
        info: 'You can input the number of households who are participating in the program. The household number is then converted into the number of participants.',
        formatter: value => `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
        parser: value => parseFloat(value.replace(/\D/g,''))
    },
    averagePublicTransportFare: {
        name: 'AveragePublicTransportFare',
        initialValue: 6.6,
        label: 'Average public transport fare',
        info: 'You can input an average public transport fare per trip. The appropriate amount inputted as the fare highly depends on various factors, such as demographics of the area, geographical location and the existing transport network.',
        formatter: value => `$${value.toString()}`,
        parser: value => value.replace("$", ""),
    },
}