import { connect } from 'react-redux'
import Header from '../components/Header'
import { toggleAbout } from '../actions/about'
import { toggleMenu } from '../actions/menu'
export default connect(
  state => {
    return {
      about: state.about,
      menu: state.menu
    }
  },
  { toggleAbout, toggleMenu }
)(Header)
