import { Employment } from '../constants/actionTypes'

const {
  GET_EMPLOYMENT,
  GET_EMPLOYMENT_SUCCESS,
  GET_EMPLOYMENT_FAILURE,
  SET_EMPLOYMENT_CONTROLS
} = Employment

export const initialState = {
  data: null,
  LGAdata: null,
  fetching: false,
  error: false,
  employmentTypes: [
    'None',
    'Strategic Jobs',
    'Population-driven',
    'Location quotient per sector',
  ],
  comparisonAreas: ['Perth Metro', 'WA', 'Australia'],
  sectors: null,
  controls: {
    selectedEmploymentType: null,
    selectedComparisonArea: 'Perth Metro',
    selectedSector: null
  }
}

export default function(state = initialState, { type, payload }) {
  switch (type) {
    case GET_EMPLOYMENT: {
      return {
        ...state,
        fetching: true,
      }
    }
    case GET_EMPLOYMENT_SUCCESS: {
      // build dropdown options
      const { SA2s, LGAs } = payload
      const key = Object.keys(SA2s)[0]
      const feature = SA2s[key]
      const sectors = Object.keys(feature['set2'])
      return {
        ...state,
        sectors: [...sectors],
        data: SA2s,
        LGAdata: LGAs,
        error: false,
        fetching: false,
      }
    }
    case GET_EMPLOYMENT_FAILURE: {
      return {
        ...state,
        error: true,
        fetching: false,
      }
    }
    case SET_EMPLOYMENT_CONTROLS: {
      const { key, value } = payload
      return {
        ...state,
        controls:{
          ...state.controls,
          [key]: value
        }
      }
    }
    default:
      return state
  }
}
