import React, { Component } from 'react'
import styled from '@emotion/styled'
import { connect } from 'react-redux'
import { Button, Tooltip, Select } from 'antd'
import { Analytics } from 'aws-amplify'

import { selectorOtherModels } from '../selectors/modelPanel-selector'
import { removeModel, updateModel } from '../actions/modelcontainer'

const Option = Select.Option

const ModelPanelContainer = styled('div')`
  width: 320px;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow-y: auto;
  min-height: 0px;
  border-right: 1px solid ${props => props.theme.grey};
`
const TitleContainer = styled('div')`
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
`
const CloseBtn = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
`
const SelectContainer = styled('div')`
  font-size: 1rem;
  text-transform: capitalize;
  margin: 0 1.5rem;
`

export default (WrappedComponent, name) => {
  class ModelPanel extends Component {
    close = () => this.props.removeModel(name)
    handleChange = value => {
      // Log Analytics Event
      Analytics.record({ name: "ChangeModel", attributes: {
        previous: name.toString(), next: value.toString() }});
      this.props.updateModel({ previous: name, next: value })
    }
    render() {
      const { removeModel, updateModel, othermodels, ...rest } = this.props
      return (
        <ModelPanelContainer>
          <TitleContainer>
            <CloseBtn>
              <Tooltip title="remove model" placement="left">
                <Button
                  onClick={this.close}
                  size={'small'}
                  shape="circle"
                  icon="close"
                />
              </Tooltip>
            </CloseBtn>
            <SelectContainer>
              <Select
                defaultValue={`${name} model`}
                style={{ width: 230 }}
                onChange={this.handleChange}
              >
                {othermodels.map(f => (
                  <Option
                    style={{ textTransform: 'capitalize' }}
                    key={f}
                    value={f}
                  >
                    {f} model
                  </Option>
                ))}
              </Select>
            </SelectContainer>
          </TitleContainer>
          <WrappedComponent {...rest} />
        </ModelPanelContainer>
      )
    }
  }
  return connect(
    state => {
      return {
        othermodels: selectorOtherModels(state.modelcontainer),
      }
    },
    { removeModel, updateModel }
  )(ModelPanel)
}
