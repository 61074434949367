const rootUrl = process.env.REACT_APP_API

export function queryStnBuffers() {
  const url = `${rootUrl}/retrieveBuffers`
  const params = {}

  return fetch(url, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(params),
  })
}

export function queryWorkingpop() {
  const url = `${rootUrl}/workingAgePop`
  const params = { req_params: { format: 'json' } }

  return fetch(url, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(params),
  })
}
