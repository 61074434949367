import React from 'react'
import { Form, Checkbox } from 'antd'

const FormCheckbox = ({ data, getFieldDecorator }) => {
  const { initialValue, name } = data
  return (
    <Form.Item>
      {getFieldDecorator(name, {
        initialValue,
      })(
        <Checkbox 
            style={{marginRight:10}}
        />
      )}
    </Form.Item>
  )
}
export default FormCheckbox