import React, { PureComponent } from 'react'
import styled from '@emotion/styled'
import {
    MarkSeries,
    XYPlot,
    XAxis,
    YAxis,
    DiscreteColorLegend,
    LineSeries,
  } from 'react-vis'
import ChartLabel from '../../../common/ChartLabel'

const DiscreteColorLegendContainer = styled(DiscreteColorLegend)`
  font-size: 11px;
  & .rv-discrete-color-legend-item {
    padding: 4px;
  }
`

class NodePlaceChangeChart extends PureComponent {

  selectMarker = cluster => this.props.setTargetCluster(cluster.id)

  render() {
    const { clusterType, origin, target, width } = this.props
    if (!clusterType || clusterType.data.length === 0 || !origin) return null
    const { data, npaLegend } = clusterType
    return (
      <XYPlot width={width-40} height={240} colorType="literal">
        <XAxis />
        <ChartLabel
          text="Node Score"
          className="alt-x-label"
          includeMargin={false}
          xPercent={0.4}
          yPercent={1.25}
        />
        <YAxis left={3}/>
        <ChartLabel
          text="Place Score"
          className="alt-y-label"
          includeMargin={false}
          xPercent={-0.14}
          yPercent={0.7}
          rotateX={-90}
        />
        <MarkSeries
          data={data}
          opacity={0.6}
          onValueClick={this.selectMarker}
        />
        {target && <LineSeries data={[origin, target]} stroke={'#000'} />}
        {target && (
          <MarkSeries
            data={[target]}
            opacity={1}
            stroke={'#000'}
            color={'transparent'}
            strokeWidth={1.5}
          />
        )}
        <MarkSeries
          data={[origin]}
          opacity={1}
          stroke={'#000'}
          color={'transparent'}
          strokeWidth={1.5}
        />
        <DiscreteColorLegendContainer
          items={npaLegend}
          orientation={'vertical'}
        />
      </XYPlot>
    )
  }
}

export default NodePlaceChangeChart
