import React, { PureComponent } from 'react'
import { Feature, Layer } from 'react-mapbox-gl'
import PropTypes from 'prop-types'

class SelectedHubLayer extends PureComponent {
  render() {
    const { hub, before } = this.props
    if (!hub) return null
    return (
      <Layer
        id="selectedhublayer"
        before={before}
        type="circle"
        paint={{
          'circle-radius': 8,
          'circle-color': '#fff',
          'circle-opacity': 0,
          'circle-stroke-width': 2,
          'circle-stroke-color': '#333',
        }}
      >
        <Feature key={hub.ID} id={hub.ID} coordinates={[hub.X, hub.Y]} />
      </Layer>
    )
  }
}

SelectedHubLayer.propTypes = {
  hub: PropTypes.object,
  before: PropTypes.string.isRequired,
}

export default SelectedHubLayer
