import { Menu } from '../constants/actionTypes'
const { TOGGLE_MENU } = Menu

export const initialState = false

export default function(state = initialState, { type, payload }) {
  switch (type) {
    case TOGGLE_MENU: {
      return payload ? payload : !state
    }
    default:
      return state
  }
}
