import React from 'react'
import styled from '@emotion/styled'

import Heading from '../../common/Heading'

const InfoTextContainer = styled('div')`
  padding: 0.25rem 0 1rem 0;
  font-size: 14px;
`

const InfoText = () => {
  return (
    <>
      <Heading>Instructions</Heading>
      <InfoTextContainer>
        To show model outputs, select control parameters then click 'Run Model'.
      </InfoTextContainer>
    </>
  )
}
export default InfoText
