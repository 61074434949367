import { Population } from '../constants/actionTypes'
import { queryPopulation } from '../queries/population'

const {
  GET_POPULATION,
  GET_POPULATION_SUCCESS,
  GET_POPULATION_FAILURE,
  SET_DISTANCE,
} = Population

export const loadPopulation = () => {
  return function(dispatch) {
    dispatch(getPopulation())
    queryPopulation()
      .then(res => res.json())
      .then(json => dispatch(getPopulationSuccess(json)))
      .catch(err => dispatch(getPopulationFailure(err)))
  }
}

export const setDistance = payload => ({
  type: SET_DISTANCE,
  payload,
})

export const getPopulation = () => ({
  type: GET_POPULATION,
})

export const getPopulationSuccess = payload => ({
  type: GET_POPULATION_SUCCESS,
  payload,
})

export const getPopulationFailure = payload => ({
  type: GET_POPULATION_FAILURE,
  payload,
})
