import React from 'react'

import DefaultButton from '../../common/DefaultButton'

const PrintBtn = ({ toggle}) => {
    return (
        <DefaultButton onClick={()=>toggle(true)} size={'large'}>Launch Report</DefaultButton>
    )
}

export default PrintBtn