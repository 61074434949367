import React, { Component } from 'react'
import styled from '@emotion/styled'
import classyBrew from 'classybrew'
import { scaleThreshold } from 'd3-scale'
import { Analytics } from 'aws-amplify'

import EmploymentType from './EmploymentType'
import LocationComparisonAreas from './LocationComparisonAreas'
import Sectors from './Sectors'

import config from '../../config/sa2Layer'

const Container = styled('div')`
  padding: 1rem;
`
const QuotientInformation = styled('div')`
  padding 0;
  font-size: 0.75rem;
`
const dataLookUp = {
  'Perth Metro': 'set2',
  WA: 'set2a',
  Australia: 'set2b',
}
const infoText = {
  'Strategic Jobs': 'Total number of strategic jobs in Perth metro',
  'Population-driven': 'Total number of population-driven jobs in Perth metro',
  Employment: 'in Perth Metro in comparison to ',
}

const change = (pthis, controls) => {
  const { data } = pthis.props
  const {
    selectedEmploymentType,
    selectedComparisonArea,
    selectedSector,
  } = controls

  // Log Analytics Event
  Analytics.record({ name: "EmploymentLayers", attributes: {
    employmentType: selectedEmploymentType,
    comparisonArea: selectedComparisonArea,
    sector: selectedSector
  }})

  // scale to use
  const stops = config['employment']
  const colors = stops.map(f => f[1])

  // for employment type options
  if (
    selectedEmploymentType &&
    selectedEmploymentType !== 'Location quotient per sector' &&
    selectedEmploymentType !== 'None'
  ) {
    // remap the object values to the selected attribute
    const values = []
    const attributes = Object.keys(data).reduce((acc, key) => {
      if (
        data[key]['set1'][selectedEmploymentType] &&
        !isNaN(data[key]['set1'][selectedEmploymentType])
      ) {
        acc[key] = data[key]['set1'][selectedEmploymentType]
        values.push(data[key]['set1'][selectedEmploymentType])
      }
      return acc
    }, {})

    // use classybrewer
    const brew = new classyBrew()
    brew.setSeries(values)
    brew.setNumClasses(colors.length)
    brew.classify('jenks')
    const classyBreaks = brew.getBreaks()
    classyBreaks.shift() // hack to remove the useless first break
    // set up scale using outputs from classyBrew
    const scale = scaleThreshold()
      .domain(classyBreaks)
      .range(colors)
    // build out the payload and send to the sa2 layer
    const payload = {
      attributes,
      type: `set1_${selectedEmploymentType}`,
      scale,
    }
    pthis.props.setSA2Attributes(payload)

    // and update the legend
    const title = selectedEmploymentType
    const info = infoText[title]
    // get legend breaks
    const items = classyBreaks.map((f, i) => [f, colors[i]])

    pthis.props.setContextLegendData({ title, info, items })

    // for different comparison areas and sectors
  } else if (
    selectedEmploymentType &&
    selectedSector &&
    selectedEmploymentType === 'Location quotient per sector'
  ) {
    const area = dataLookUp[selectedComparisonArea]
    const attributes = Object.keys(data).reduce((acc, key) => {
      acc[key] = data[key][area][selectedSector]
      return acc
    }, {})
    // scale to use
    const scale = scaleThreshold()
      .domain(stops.map(f => f[0]))
      .range(colors)
    // build out the payload and send to the sa2 layer
    const payload = { attributes, type: `${area}_${selectedSector}`, scale }
    pthis.props.setSA2Attributes(payload)

    // and update the legend
    const title = `Location quotient per ${selectedSector}`
    const info = `${title} ${infoText['Employment']} ${selectedComparisonArea}`
    pthis.props.setContextLegendData({ title, info, items: stops })
  } else if (selectedEmploymentType === 'None') {
    // reset everything
    pthis.props.setSA2Attributes({
      attributes: null,
      type: null,
      scale: null,
    })
    pthis.props.setContextLegendData(null)
  }
}

class EmploymentControls extends Component {

  onChange = payload => {
    // TODO not the best practice so needs refactoring
    // so this whole call is sitting in a selector
    // and the SA2 layer gets which 'model' it should
    // be showing and then does the calculations based
    // on the inputs provided
    const { key, value } = payload
    const controls = {
        ...this.props.controls,
        [key]: value
    }
    change(this, controls)
    this.props.setEmploymentControls(payload)
  }

  render() {
    const { employmentTypes, comparisonAreas, sectors, controls } = this.props
    const {
      selectedEmploymentType,
      selectedComparisonArea,
      selectedSector,
    } = controls
    return (
      <Container>
        {employmentTypes && (
          <EmploymentType
            onChange={this.onChange}
            selected={selectedEmploymentType}
            options={employmentTypes}
          />
        )}
        {comparisonAreas &&
          selectedEmploymentType === 'Location quotient per sector' && (
            <LocationComparisonAreas
              onChange={this.onChange}
              selected={selectedComparisonArea}
              options={comparisonAreas}
            />
          )}
        {sectors &&
          selectedEmploymentType === 'Location quotient per sector' && (
            <Sectors
              onChange={this.onChange}
              selected={selectedSector}
              options={sectors}
            />
          )}
        { selectedEmploymentType === 'Location quotient per sector' && (
          <QuotientInformation>
            A location quotient (LQ) is a valuable way of quantifying how concentrated 
            a particular industry is in comparison to the city, state or country. 
            It can reveal what makes particular regions ‘unique’ in comparison to the 
            average. A LQ of 1 means the area is exactly average, below 1 indicates 
            that the area is not competitive or is a net importer of that sector. Any 
            area above 1 indicates a competitive advantage. 
          </QuotientInformation>
        )}
      </Container>
    )
  }
}

export default EmploymentControls
