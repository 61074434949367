import React, { PureComponent } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import ReactMapboxGl, { ZoomControl, RotationControl, ScaleControl } 
  from 'react-mapbox-gl'

import Layers from '../Layers'
import { Attribution } from '../Map/CommonComponents';
import mapStyle from '../../config/map'

const MapContainer = styled('div')`
  position: absolute;
  top: 59px;
  left: 0;
  height: calc(100% - 59px);
  width: calc(100% - ${props => props.panelSize * 320}px);
  border-top: solid 2px #dedfdf;
`

const ReactMap = ReactMapboxGl({
  accessToken: null, // not required as we are using the free OSM basemap
  maxZoom: 15,
})

class Map extends PureComponent {
  viewport = {
    zoom: 10,
    maxZoom: 20,
    minZoom: 0,
    pitch: 0,
    bearing: 0,
    center: [115.82183992548926, -31.99748894521732],
  }
  componentDidUpdate(prevProps) {
    if (this.map && this.props.panelSize !== prevProps.panelSize) {
      this.map.resize()
    }

    if (this.props.center.center !== this.viewport.center) {
      this.map.flyTo({ center: this.props.center.center })
    }
  }
  storeInternalRef = map => {
    this.map = map
  }
  updateViewport = () => {
    const center = this.map.getCenter()
    const zoom = this.map.getZoom()
    const pitch = this.map.getPitch()
    this.viewport = {
      ...this.viewport,
      center: [center.lng, center.lat],
      zoom,
      pitch,
    }
  }
  changeCursor = hovered =>
    (this.map.getCanvas().style.cursor = hovered ? 'pointer' : '')

  onMouseMove = (map,e) => 
    this.props.selectFeature({map, e});

  render() {
    const { panelSize } = this.props
    const { pitch, zoom, center } = this.viewport
    return (
      <MapContainer panelSize={panelSize}>
        <ReactMap
          style={mapStyle}
          center={center}
          pitch={[pitch]}
          zoom={[zoom]}
          onDragEnd={this.updateViewport}
          onZoomEnd={this.updateViewport}
          onPitchEnd={this.updateViewport}
          onClick={this.onMouseMove}
          ref={map=>this.props.setMap(map)}
          containerStyle={{ height: '100%', width: '100%' }}
          onStyleLoad={this.storeInternalRef}
        >
          <RotationControl
            style={{
              bottom: '84px',
              left: '1.5rem',
            }}
            position="bottom-left"
          />
          <ZoomControl
            style={{
              bottom: '2rem',
              left: '1.5rem',
            }}
            position="bottom-left"
          />
          <ScaleControl
            position="bottom-right"
            style={{
              right: '1rem',
              bottom: '6rem',
            }}
          />
          <Layers changeCursor={this.changeCursor} />
          <Attribution />
        </ReactMap>
      </MapContainer>
    )
  }
}

Map.propTypes = {
  panelSize: PropTypes.number.isRequired,
}

export default Map
