import {
    connect
} from 'react-redux'
import EmploymentControls from '../components/ContextLayers/EmploymentControls'
import { setEmploymentControls } from '../actions/employment'
import { setSA2Attributes } from '../actions/sa2layer'
export default connect(
    state => {
        return {
            employmentTypes: state.employment.employmentTypes,
            comparisonAreas: state.employment.comparisonAreas,
            sectors: state.employment.sectors,
            controls: state.employment.controls,
            data: state.employment.data
        }
    }, {
        setEmploymentControls,
        setSA2Attributes
    }
)(EmploymentControls)