import React, { PureComponent } from 'react'
import styled from '@emotion/styled'
import { MarkSeries, XYPlot, XAxis, YAxis, DiscreteColorLegend } from 'react-vis'
import ChartLabel from '../../common/ChartLabel'
import Subtitle from '../../common/Subtitle'
import Column from '../../common/Column'
import Row from '../../common/Row'

const DiscreteColorLegendContainer = styled(DiscreteColorLegend)`
  font-size: 11px;
  & .rv-discrete-color-legend-item {
  }
`
const Container = styled(Column)`
  padding: 0.75rem;
  margin: 0 1rem;
  border: 1px solid #e0e0e0;
  background-color: ${props => props.theme.white};
`

class NodePlaceChangeChart extends PureComponent {
  render() {
    const { clusterType, clusterList } = this.props
    if (
      !clusterType ||
      clusterType.data.length === 0
    )
      return null
    const { data, npaLegend } = clusterType
    const targetMarkers = clusterList.map(f => f.target)
    return (
      <Container>
        <Subtitle>Hub cluster comparison</Subtitle>
        <Row>
          <XYPlot width={300} height={200} colorType="literal">
            <XAxis />
            <ChartLabel
              text="Node Score"
              className="alt-x-label"
              includeMargin={false}
              xPercent={0.4}
              yPercent={1.3}
            />
            <YAxis left={3} />
            <ChartLabel
              text="Place Score"
              className="alt-y-label"
              includeMargin={false}
              xPercent={-0.12}
              yPercent={0.7}
              rotateX={-90}
            />
            <MarkSeries data={data} opacity={0.6} />
            {targetMarkers && (
              <MarkSeries
                data={targetMarkers}
                opacity={1}
                stroke={'#000'}
                color={'transparent'}
                strokeWidth={1.5}
              />
            )}
          </XYPlot>
          <DiscreteColorLegendContainer
            items={npaLegend}
            width={130}
            orientation={'vertical'}
          />
        </Row>
      </Container>
    )
  }
}
export default NodePlaceChangeChart
