import { SA2Layer } from '../constants/actionTypes'

const {
  GET_SA2_SUCCESS,
  GET_SA2_FAILURE,
  SET_SA2_ATTRIBUTES,
  SET_SA2_MODELNAME,
} = SA2Layer

export const initialState = {
  geo: null,
  error: false,
  data: null,
  modelName: null,
}

export default function(state = initialState, { type, payload }) {
  switch (type) {
    case GET_SA2_SUCCESS: {
      return {
        ...state,
        geo: payload,
      }
    }
    case GET_SA2_FAILURE: {
      return {
        ...state,
        error: true,
      }
    }
    case SET_SA2_ATTRIBUTES: {
      return {
        ...state,
        data: payload,
        modelName: payload.type,
      }
    }
    case SET_SA2_MODELNAME: {
      return {
        ...state,
        modelName: payload,
      }
    }
    default:
      return state
  }
}
