export default {
  traveltime: [
    [30, '#ef6c4b'],
    [60, '#f79c86'],
    [90, '#f5c8b3'],
    [120, '#faf2e6'],
  ],
  employment: [
    [0.5, '#eef8e8'],
    [1, '#dbebb2'],
    [1.5, '#c5da8d'],
    [2, '#aad275'],
    [2.5, '#8bc160'],
    [2.9, '#70b053'],
    [3, '#5A8D42'],
  ],
  context: [
    [0.5, '#eef8e8'],
    [1, '#dbebb2'],
    [1.5, '#c5da8d'],
    [2, '#aad275'],
    [2.5, '#8bc160'],
    [2.9, '#70b053'],
    [3, '#5A8D42'],
  ],
}
