import React, { Component } from 'react'
import styled from '@emotion/styled'

import ModelPanel from '../../hoc/ModelPanel'
import Treemap from './Treemap'
import Indicators from './Indicators'
import MedianHousing from './MedianHousing'
import ClusterType from './ClusterType'
import NodePlaceScore from './NodePlaceScore'
import NoData from '../NoData'
import { ModelHeader } from '../ModelContainer/CommonComponents'

const NodePlaceContainer = styled('div')`
  flex: 1 1 auto;
  padding: 1rem 2rem 0 2rem;
`

const Padding = styled('div')`
  padding: 1rem;
`

class NodePlace extends Component {
  render() {
    const {
      landUse,
      housePrices,
      nodePlace,
      clusterType,
      distance,
      setDistance,
      indicators,
      nodeplaceScore,
    } = this.props

    if (!nodePlace) return <NoData />

    return (
      <NodePlaceContainer>
        {nodePlace && (
          <ModelHeader heading={"Node-Place Analysis"} 
            description={"The Node-Place Analysis tool seeks to analyse and \
            categorise stations by means of their node, place and background \
            traffic characteristics. Node characteristics cover a wide range \
            of physical and social attributes about the land around stations. \
            Node scores look at building density, diversity and aesthetics. \
            The Socio-Economic Indicies for Areas from the ABS are used to to \
            understand the socio-economic traits of the catchment areas. A \
            well functioning, mixed-use Transit Oriented Node will score \
            highly in this model. The Place attributes are more concerned with \
            how the station connects in with the greater Metropolitan area, \
            e.g. how many jobs can be accessed within a given time. Transit \
            hubs score well in this model. The highest score is Perth station \
            and all other scores are benchmarked off of Perth."} 
            academic={"https://doi.org/10.3390/su11020477"} />
        )}
        {nodePlace && (
          <ClusterType
            nodePlace={nodePlace}
            clusterType={clusterType}
            distance={distance}
            setDistance={setDistance}
          />
        )}
        {nodeplaceScore && <NodePlaceScore data={nodeplaceScore} />}
        {indicators && <Indicators data={indicators} />}
        {housePrices && <MedianHousing housePrices={housePrices} />}
        <Treemap landUse={landUse} />
        <Padding />
      </NodePlaceContainer>
    )
  }
}
NodePlace.propTypes = {}

export default ModelPanel(NodePlace, 'node-place analysis')
