import React from 'react'

import FormNumberInput from './FormNumberInput'
import FormSelect from './FormSelect'
import FormCheckbox from './FormCheckbox'

import Row from '../../common/Row'
import Column from '../../common/Column'

const PopulationOverride = ({ getFieldValue, data, width, getFieldDecorator,
  updateLabel }) => {
  const { population, scenarios, override } = data
  const disabled = getFieldValue ? getFieldValue('override') : false
  return (
    <Column>
      <FormSelect disabled={disabled} data={scenarios}
        getFieldDecorator={getFieldDecorator} updateLabel={updateLabel}
        width={width} />
      <Row>
        <FormCheckbox data={override} getFieldDecorator={getFieldDecorator} />
        <FormNumberInput disabled={!disabled} data={population}
          getFieldDecorator={getFieldDecorator}
          updateLabel={updateLabel} width={width - 50} />
      </Row>
    </Column>
  )
}

export default PopulationOverride