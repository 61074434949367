import React, { PureComponent } from 'react'
import styled from '@emotion/styled'
import { Icon } from 'antd'

import Column from '../../common/Column'
import Row from '../../common/Row'
import MiniTitle from '../../common/MiniTitle'
import Heading from '../../common/Heading'

import { numberWithCommas } from '../../utils/formatting'

const Container = styled(Column)`
  padding-bottom: 1rem;
`
const TitleContainer = styled(Row)`
  align-items: center;
`
const Item = styled(Row)`
  margin: 0.25rem 0;
  align-items: center;
  padding:1.5rem 1rem;
  background-color: ${props => props.theme.white};
  border: 1px solid ${props => props.theme.grey};
`
const ClusterItem = styled(Row)`
  align-items: center;
`
const Dot = styled('div')`
  margin:1rem;
  padding:0.5rem;
  border-radius:50%;
  background-color: ${props => props.color};
`
const Value = styled('div')`
  font-size: 14px;
`
const ArrowIcon = styled(Icon)`
  margin: 0 0.75rem;
`
const ImgContainer = styled('div')`
  height: 100px;
  margin: -1.5rem -1rem;
`
const Img = styled('div')`
  margin: 0;
  background-position: center;
  background-size: cover;
  background-image: url('${props => props.src}');
  width: 100%;
  height: 100px;
`
const widths = {
  cluster: 400,
  pop: 200,
  pat: 200,
  image: 250
}

class ChangeClusterList extends PureComponent {

  render(){
    const { data } = this.props
    return (
      <Container>
        <TitleContainer>
          <MiniTitle style={{width:widths['cluster']}}>Change cluster</MiniTitle>
          <MiniTitle style={{width:widths['pop']}}>New population</MiniTitle>
          <MiniTitle style={{width:widths['pat']}}>New patronage</MiniTitle>
          <MiniTitle style={{width:widths['image']}}>Cluster change result</MiniTitle>
        </TitleContainer>
        <Column>
          {
            data && data.map(item=> {
              const { name, newPatronage, newPop, target, existing } = item
              const existingColor = existing.color
              const targetName = target.name
              const targetImg = `${window.origin}/Stations/${targetName.split(' ').join('_').toLowerCase()}_400.jpg` 
              return (
                <Item key={name}>
                    <ClusterItem style={{width:widths['cluster']}}>
                      <Dot color={existingColor} />
                      <Heading>{name}</Heading>
                      <ArrowIcon type="arrow-right" />
                      <Value>{targetName}</Value>
                    </ClusterItem>
                    <Value style={{width:widths['pop']}}>{numberWithCommas(newPop)}k</Value>
                    <Value style={{width:widths['pat']}}>{numberWithCommas(newPatronage)}k</Value>
                    <ImgContainer style={{width:widths['image']}}>
                      <Img src={targetImg} />
                    </ImgContainer>
                </Item>
              )
            })
          }
        </Column>
      </Container>
    )
  }

}
export default ChangeClusterList