import { connect } from 'react-redux'
import Employment from '../components/ScenarioPlanner/EmploymentCalculator'
import { selectorLGACalcEmployment } from '../selectors/employment-selector'

import { loadEmployment } from '../actions/employment'
import { setEmploymentResult, toggleClustersActive , setEmploymentCreated} from '../actions/scenarioplanner'
import { selectorWorkingPop } from '../selectors/scenario-clusterchange-selector';

export default connect(
  state => {
    return {
      selectedLGAComparison: selectorLGACalcEmployment(state),
      lga: state.scenarioplanner.lga,
      population: state.population.medium
        ? state.population.medium[state.scenarioplanner.lga]
        : null,
      workingAgePop: selectorWorkingPop(state),
      employmentActive: state.scenarioplanner.employmentActive
    }
  },
  { loadEmployment, setEmploymentResult, toggleClustersActive , setEmploymentCreated}
)(Employment)
