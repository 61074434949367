import React, { PureComponent } from 'react'
import styled from '@emotion/styled'
import { Icon, Tooltip} from 'antd'

const Info = styled(Icon)`
    margin-left: 1rem;
    color: ${props => props.theme.darkgrey};
    &:hover {
        color: ${props => props.theme.black};
    }
`
class FormLabel extends PureComponent {
    handleInfo = () => this.props.updateLabel(this.props)
    render() {
        const { label, info } = this.props
        return (
            <div>
                {label}
                {info && <Tooltip overlayStyle={{fontSize: '9pt', 
                    padding: '9pt'}} placement="bottom" title={info}>
                    <Info type="info-circle" />
                </Tooltip>}
            </div>
        )
    }
}
export default FormLabel