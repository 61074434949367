import React, { Component, Fragment } from 'react'
import HubLayer from './HubLayer'
import LGALayer from "../../../containers/LGALayer"

class Layers extends Component {
  render() {
    const { hubs } = this.props
    return (
      <Fragment>
        <HubLayer data={hubs} />
        <LGALayer />
      </Fragment>
    )
  }
}

export default Layers
