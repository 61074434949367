import React from 'react'
import { Select } from 'antd'

const Option = Select.Option

const ChangeClusterChartSwitch = ({
  selectedChart,
  charts,
  updateSelectedChart,
}) => {
  return (
    <Select
      value={selectedChart}
      style={{ width: '240px' }}
      onChange={updateSelectedChart}
    >
      {charts.map(f => {
        return (
          <Option key={f} value={f}>
            {f}
          </Option>
        )
      })}
    </Select>
  )
}

export default ChangeClusterChartSwitch
