import { connect } from 'react-redux'
import Employment from '../components/Employment'
import {
  selectorEmployment,
  selectedHubSelector,
  selectorComparisonEmployment,
  selectorLGAComparisonEmployment,
} from '../selectors/employment-selector'

import { dataSelector } from '../selectors/hubLayer-selector'
import { loadEmployment } from '../actions/employment'

export default connect(
  state => {
    return {
      hubs: dataSelector(state),
      selectEmployment: selectorEmployment(state),
      selectedHub: selectedHubSelector(state),
      selectedComparison: selectorComparisonEmployment(state),
      selectedLGAComparison: selectorLGAComparisonEmployment(state),
    }
  },
  { loadEmployment }
)(Employment)
