import React, { Component } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import Heading from '../../common/Heading'
import ModelPanel from '../../hoc/ModelPanel'
import InfoText from './InfoText'
import TravelTime from './TravelTime'
import TravelDirection from './TravelDirection'
import ModePeriod from './ModePeriod'
import Metric from './Metric'
import ComparisonChart from './ComparisonChart'
import RunModel from './RunModel'
import { ModelHeader } from '../ModelContainer/CommonComponents'

const AccessibilityContainer = styled('div')`
  flex: 1 1 auto;
  padding: 1rem 2rem 0 2rem;
`
class Accessibility extends Component {
  render() {
    const {
      stagingDirection,
      stagingMetric,
      stagingMode,
      stagingPeriod,
      direction,
      metric,
      time,
      stagingTime,
      showRunModel,
      comparisonData,
      metric_total,
    } = this.props
    return (
      <AccessibilityContainer>
        <ModelHeader heading={"Accessibility Model"}
          description={"The accessibility model seeks to show how many \
          jobs, people or dwellings are accessible within the given time by \
          the selected mode of transport. The model works from each railway \
          station location to the centre of each STEM zone."}
          academic={"https://www.australasiantransportresearchforum.org.au/sites/default/files/ATRF2017_Abridged_Paper_91.pdf"} />
        <InfoText />
        <Heading>Model Parameters</Heading>
        <TravelTime time={stagingTime} change={this.props.setTimeStaging} />
        <ModePeriod
          travel_mode={stagingMode}
          period={stagingPeriod}
          change={this.props.setModeStaging}
          changePeriod={this.props.setPeriodStaging}
        />
        <TravelDirection
          direction={stagingDirection}
          change={this.props.setDirectionStaging}
        />
        <Metric metric={stagingMetric} change={this.props.setMetricStaging} />
        {!showRunModel &&
          comparisonData && (
            <ComparisonChart
              data={comparisonData}
              direction={direction}
              metric={metric}
              time={time}
              metric_total={metric_total}
            />
          )}
        {showRunModel && <RunModel runModel={this.props.runModel} />}
      </AccessibilityContainer>
    )
  }
}

Accessibility.propTypes = {
  setMetricStaging: PropTypes.func.isRequired,
  setDirectionStaging: PropTypes.func.isRequired,
  setTimeStaging: PropTypes.func.isRequired,
  stagingDirection: PropTypes.string.isRequired,
  stagingTime: PropTypes.number.isRequired,
  stagingMetric: PropTypes.string.isRequired,
  direction: PropTypes.string,
  time: PropTypes.number,
  metric: PropTypes.string,
  runModel: PropTypes.func.isRequired,
  showRunModel: PropTypes.bool.isRequired,
  comparisonData: PropTypes.object,
}

export default ModelPanel(Accessibility, 'accessibility')
