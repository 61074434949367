import React, { Component } from 'react'
import styled from '@emotion/styled'

import ModelPanel from '../../hoc/ModelPanel'
import JobNumericalComparison from './JobNumericalComparison'
import StrategicPopulation from './StrategicPopulation'
import { ModelHeader } from '../ModelContainer/CommonComponents'

const ModelHeaderContainer = styled('div')`
  padding: 1rem 2rem 0 2rem;
`

class Employment extends Component {
  render() {
    const {
      selectEmployment,
      selectedHub,
      selectedComparison,
      selectedLGAComparison,
      hubs,
    } = this.props

    let StrategicJobs = selectEmployment['set1']['Strategic Jobs'],
      PopulationJobs = selectEmployment['set1']['Population-driven'],
      Total = selectEmployment['set1']['Total Perth Metro']

    return (
      <>
      <ModelHeaderContainer>
        <ModelHeader heading={"Strategic Employment Tool"} 
            description={"The Strategic Employment Model splits employment \
            numbers from ABS data into the jobs which are generated simply by \
            the size of the population. The population following (or service) \
            jobs, such as GP’s, teachers, shop assistants, librarians etc. occur \
            in relation to population size. Strategic jobs are however harder to \
            predict. Strategic employment is important as this is what grows the \
            economy of an area. The areas in Perth with higher levels of \
            strategic employment are the economic power houses of the \
            metropolitan area. The graphs below show the area relative to all \
            LGA or SA2’s in Greater Perth, the horizontal line indicates the \
            average population following to strategic ratio for the greater \
            metro area, thus areas above the line are important employment areas \
            in the Metropolitan context."}
            academic={"https://doi.org/10.1080/08111146.2017.1294539"}/>
        </ModelHeaderContainer>
        <JobNumericalComparison
          Hub={
            Object.keys(selectedHub).includes('SmartRider_StopName')
              ? selectedHub['SmartRider_StopName']
              : selectedHub['Doina_HubName']
          }
          StrategicJobs={StrategicJobs}
          PopulationJobs={PopulationJobs}
          Total={Total}
          PredictedTotal={15682}
        />
        <StrategicPopulation
          hubs={hubs}
          selectedHub={selectedHub}
          selectedComparison={selectedComparison}
          selectedLGAComparison={selectedLGAComparison}
        />
      </>
    )
  }
}

export default ModelPanel(Employment, 'strategic employment')
