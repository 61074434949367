import React from 'react'
import styled from '@emotion/styled'
import { Icon } from 'antd'

import MiniTitle from '../../common/MiniTitle'
import Column from '../../common/Column'

const Container = styled('div')`
    width:330px;
    margin-top:1rem;
`
const Inner = styled(Column)`
    padding: 1.5rem;
    border-radius:3px;
    align-items: flex-start;
    background-color: ${props => props.theme.lightgrey};
`
    
const InfoIcon = styled(Icon)`
    margin-bottom: 1rem;
    color: ${props => props.theme.darkgrey};
`
const InfoText = styled('div')`
    font-size:12px;
    font-weight:normal;
`

const Info = ({data}) => {
    if(!data || !data.hasOwnProperty('label')|| !data.hasOwnProperty('info') ) return null
    const { label, info } = data
    return (
        <Container>
            <Inner>
                <InfoIcon type="info-circle" />
                <MiniTitle>{ label }</MiniTitle>
                <InfoText>
                    {info}
                </InfoText>
            </Inner>
        </Container>
    )
}
export default Info