import React, { PureComponent } from 'react'
import styled from '@emotion/styled'
import { HeaderTooltip } from '../ModelContainer/CommonComponents'

const Container = styled('div')`
  padding: 0.5rem 0;
`
const Heading = styled('div')`
  font-weight: 600;
  font-size: 1rem;
  padding: 1rem 0;
`
const Score = styled('div')`
  color: #ff9505;
  font-size: 24px;
  line-height: 42px;
`
const SubColumn = styled('div')`
  display: flex;
  flex-direction: column;
`

const Row = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
class NodePlaceScore extends PureComponent {
  render() {
    const { data } = this.props
    const { node, place } = data
    return (
      <Container>
        <Heading>Key Indicators</Heading>
        <Row>
          {(place || place !== 0) && (
            <SubColumn>
              <HeaderTooltip sub={true} heading={"Place score"}
                description={"Place characteristics cover four main \
                categories: density, diversity, socio-economic indicies and \
                urban aesthetics. Place attributes describe the character of \
                the physical and social surroundings of the station. The data \
                is primarily sourced from the ABS and Landgate."} />
              <Score>{place}</Score>
            </SubColumn>
          )}
          {(node || node !== 0) && (
            <SubColumn>
              <HeaderTooltip sub={true} heading={"Node score"}
                description={"Node characteristics describe the accessibility \
                of the station in terms of city-wide access, opportunities \
                that can be reached within 15, 30, 45 and 60 minutes, parking \
                areas as well as bus and bicycle facilities. The data for the \
                node function is mainly from the STEM model."} />
              <Score>{node}</Score>
            </SubColumn>
          )}
        </Row>
      </Container>
    )
  }
}

export default NodePlaceScore
