const rootUrl = process.env.REACT_APP_API

export function queryAccessibilityTT({
  direction,
  time,
  zoneID,
  travel_mode,
  period,
}) {
  const url = `${rootUrl}/callmodel`
  const params = {
    results_param: 'accessResults',
    model: 'ttFilter',
    req_params: {
      zoneID,
      direction,
      time,
      travel_mode,
      period,
    },
  }
  return fetch(url, {
    method: 'post',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  })
}

export function queryAccessibilityComparision({
  direction,
  time,
  metric,
  zoneID,
  travel_mode,
  period,
}) {
  const url = `${rootUrl}/callmodel`
  const params = {
    results_param: 'accessResults',
    model: 'accessibilityHubs',
    req_params: {
      zoneID,
      direction,
      time,
      metric,
      travel_mode,
      period,
    },
  }
  return fetch(url, {
    method: 'post',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  })
}

export function querySA2s() {
  const url = `${rootUrl}/geojson`
  return fetch(url, {
    method: 'get',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
  })
}

export function queryAccessibilityByTime({
  direction,
  metric,
  time,
  travel_mode,
  period,
}) {
  const url = `${rootUrl}/callmodel`
  const params = {
    results_param: 'accessResults',
    model: 'accessibility',
    req_params: {
      zone_identifier: 'SA2_MAIN16',
      travel_direction: direction,
      metric,
      travel_mode,
      period,
      time_threshold: time,
    },
  }
  return fetch(url, {
    method: 'post',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  })
}
