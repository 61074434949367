import React, { Component } from 'react'
import styled from '@emotion/styled'
import { Radio } from 'antd'

import EmploymentControls from '../../containers/EmploymentControls'
import ContextLayerControls from '../../containers/ContextLayerControls'

import SecondaryRadio from '../../common/SecondaryRadio'

const Container = styled('div')`
    padding: 1rem;
`
const SecondaryRadioShrink = styled(SecondaryRadio)`
    display: inline;
`

const RadioGroup = Radio.Group;

class ContextLayers extends Component {

    setMode = e => this.props.setMode(e.target.value)

    render() {
        const { modes, mode } = this.props 
        return (
            <Container>
                <RadioGroup name={'layer control'} onChange={this.setMode} value={mode}>
                    { modes.map(f=> <SecondaryRadioShrink key={f} value={f}>{f} layers</SecondaryRadioShrink>) }
                </RadioGroup>
                {
                    mode === 'Employment' && <EmploymentControls setContextLegendData={this.props.setContextLegendData}/>
                }
                {
                    mode === 'Context' && <ContextLayerControls setContextLegendData={this.props.setContextLegendData}/>
                }
            </Container>
        )
    }
}

export default ContextLayers