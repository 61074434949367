import React, { Component, Fragment } from 'react'
import HubLayer from '../../containers/HubLayer'
import SelectedHubLayer from '../../containers/SelectedHubLayer'
import SA2Layer from '../../containers/SA2Layer'
import VisualContextLayer from '../../containers/VisualContextLayer'
import StopsLayer from '../../containers/StopsLayer'
import PopupLayer from "../../containers/PopupLayer"

class Layers extends Component {
  render() {
    return (
      <Fragment>
        <StopsLayer />
        <SelectedHubLayer before={'stopslayer'} />
        <HubLayer changeCursor={this.props.changeCursor} />
        <SA2Layer before={'hublayer'} />
        <VisualContextLayer before={'hublayer'} />
        <PopupLayer />
      </Fragment>
    )
  }
}

export default Layers
