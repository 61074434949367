import { connect } from 'react-redux'
import Accessibility from '../components/Accessibility'
import {
  setTimeStaging,
  setDirectionStaging,
  setModeStaging,
  setPeriodStaging,
  runModel,
  setMetricStaging,
} from '../actions/accessibility'

import {
  selectorShowRunModel,
  selectorComparisonData,
  selectorAccessData,
} from '../selectors/accessibility-selector'

export default connect(
  state => {
    return {
      stagingDirection: state.accessibility.stagingDirection,
      stagingTime: state.accessibility.stagingTime,
      stagingMetric: state.accessibility.stagingMetric,
      stagingMode: state.accessibility.stagingMode,
      stagingPeriod: state.accessibility.stagingPeriod,
      direction: state.accessibility.direction,
      time: state.accessibility.time,
      metric: state.accessibility.metric,
      travel_mode: state.accessibility.travel_mode,
      period: state.accessibility.period,
      metric_total: selectorAccessData(state),
      showRunModel: selectorShowRunModel(state),
      comparisonData: selectorComparisonData(state),
    }
  },
  {
    setTimeStaging,
    setDirectionStaging,
    setMetricStaging,
    setModeStaging,
    setPeriodStaging,
    runModel,
  }
)(Accessibility)
