import React, { PureComponent } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import config from '../../config/sa2Layer'
const stops = config.traveltime

const Container = styled('div')`
  padding-bottom: 1rem;
`
const Title = styled('div')`
  font-weight: 600;
  font-size: 14px;
`
const StopsContainer = styled('div')`
  display: flex;
  flex-direction: row;
`
const ItemContainer = styled('div')`
  padding-top: 0.75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const ItemText = styled('div')`
  font-size: 10px;
  padding-bottom: 0.25rem;
`
const ItemColor = styled('div')`
  padding: 0.35rem 2rem;
`
const LegendText = styled('div')`
  padding-top: 0.5rem;
  font-size: 12px;
`

const LegendItem = ({ value, color }) => {
  return (
    <ItemContainer key={value}>
      <ItemText>{value}</ItemText>
      <ItemColor style={{ backgroundColor: color }} />
    </ItemContainer>
  )
}
class Legend extends PureComponent {
  render() {
    const { showAccessibility, direction, selectedHub, mode } = this.props
    if (!showAccessibility || !selectedHub) return null

    const name = selectedHub.SmartRider_StopName
      ? selectedHub.SmartRider_StopName
      : selectedHub.NodePlace_Station
    const how = direction === 'outbound' ? 'from' : 'to'
    const by = mode === 'pt' ? 'public transport' : 'private vehicle'

    return (
      <Container>
        <Title>Travel time (minutes)</Title>
        <StopsContainer>
          {stops && stops.map(f => LegendItem({ value: f[0], color: f[1] }))}
        </StopsContainer>
        <LegendText>
          Travel time {how} {name} by {by}
        </LegendText>
      </Container>
    )
  }
}

Legend.propTypes = {
  direction: PropTypes.string,
  selectedHub: PropTypes.object,
  showAccessibility: PropTypes.bool.isRequired,
  mode: PropTypes.string,
}

export default Legend
