import React, { PureComponent } from 'react'
import { GeoJSONLayer } from 'react-mapbox-gl'

class VisualContextLayer extends PureComponent {
  render() {
    const { newGeometry, theGeometry, before, showMe } = this.props
    return newGeometry === true && showMe? (
      <GeoJSONLayer
        id={'context'}
        data={theGeometry}
        before={before}
        linePaint={
          theGeometry &&
          theGeometry.features.length > 0 &&
          (theGeometry.features[0].geometry.type === 'LineString' ||
            theGeometry.features[0].geometry.type === 'MultiLineString')
            ? {
                'line-color': '#333',
                'line-opacity': 1,
                'line-width': 2,
              }
            : {}
        }
        lineLayout={
          theGeometry &&
          theGeometry.features.length > 0 &&
          (theGeometry.features[0].geometry.type === 'LineString' ||
            theGeometry.features[0].geometry.type === 'MultiLineString')
            ? { visibility: 'visible' }
            : { visibility: 'none' }
        }
        fillPaint={
          theGeometry &&
          theGeometry.features.length > 0 &&
          (theGeometry.features[0].geometry.type === 'Polygon' ||
            theGeometry.features[0].geometry.type === 'MultiPolygon')
            ? {
                'fill-color': ['get', 'color'],
                'fill-opacity': 0.8,
              }
            : {}
        }
        fillLayout={
          theGeometry &&
          theGeometry.features.length > 0 &&
          (theGeometry.features[0].geometry.type === 'Polygon' ||
            theGeometry.features[0].geometry.type === 'MultiPolygon')
            ? {
                visibility: 'visible',
              }
            : { visibility: 'none' }
        }
        circlePaint={
          theGeometry &&
          theGeometry.features.length > 0 &&
          theGeometry.features[0].geometry.type === 'Point'
            ? {
                'circle-color': '#333',
                'circle-radius': 3,
              }
            : {}
        }
        circleLayout={
          theGeometry &&
          theGeometry.features.length > 0 &&
          theGeometry.features[0].geometry.type === 'Point'
            ? { visibility: 'visible' }
            : { visibility: 'none' }
        }
      />
    ) : null
  }
}

export default VisualContextLayer
