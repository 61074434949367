import React, { PureComponent } from 'react'
import { MarkSeries, XYPlot, XAxis, YAxis, DiscreteColorLegend } from 'react-vis'
import styled from '@emotion/styled'

import ChartLabel from '../../common/ChartLabel'        
import { HeaderTooltip } from '../ModelContainer/CommonComponents'

const DiscreteColorLegendContainer = styled(DiscreteColorLegend)`
  font-size: 11px;
  & .rv-discrete-color-legend-item {
    padding: 4px;
  }
`

const Heading = styled('div')`
  font-weight: 600;
  font-size: 1rem;
  padding-top: 0rem;
`
const Cluster = styled('p')`
  opacity: 0.8;
  color: #333333;
  font-size: 0.75rem;
  font-weight: 600;
`
const Padding = styled('div')`
  margin: 80px;
`
const ClusterDesc = styled('div')`
  color: #333333;
  font-size: 14px;
  margin-bottom: 32px;
`

export default class ClusterType extends PureComponent {
  onChange = e => {
    this.props.setDistance(e.target.value)
  }
  render() {
    const { nodePlace, clusterType } = this.props
    const { data, npaLegend } = clusterType

    let selected = JSON.parse(
      JSON.stringify(data.filter(item => item.id === nodePlace.ID))
    )
    
    return (
      <>
        <Heading>Cluster type</Heading>
        <Cluster>CLUSTER {nodePlace['place_type']}</Cluster>
        <ClusterDesc>{nodePlace['type']}</ClusterDesc>
        <HeaderTooltip sub={true} heading={"Hub cluster type comparison"}
          description={"When all of the railway stations in Perth are plotted, \
          the data naturally falls into three groupings. These roughly fall \
          into those with low values for both Node and Place, those with high \
          values for both Node and Place and those which fall roughly in the \
          middle."} />
        <XYPlot width={240} height={240} colorType="literal">
          <XAxis />
          <ChartLabel
            text="Node Score"
            className="alt-x-label"
            includeMargin={false}
            xPercent={0.4}
            yPercent={1.25}
          />
          <YAxis />
          <ChartLabel
            text="Place Score"
            className="alt-y-label"
            includeMargin={false}
            xPercent={-0.17}
            yPercent={0.7}
            rotateX={-90}
          />
          <MarkSeries data={data} opacity={0.6} />
          <MarkSeries
            data={selected}
            opacity={1}
            stroke={'#000'}
            strokeWidth={1.5}
          />
          <DiscreteColorLegendContainer
            items={npaLegend}
            orientation={'vertical'}
          />
        </XYPlot>
        <Padding />
      </>
    )
  }
}
