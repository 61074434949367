import styled from '@emotion/styled'
import { Steps } from 'antd'
export default styled(Steps)`
  & .ant-steps-item-process .ant-steps-item-icon {
    background:${props => props.theme.darkbluegrey};
  }
  & .ant-steps-item-process .ant-steps-item-icon {
    border-color:${props => props.theme.darkbluegrey};
  }
  & .ant-steps-item-finish .ant-steps-item-icon {
    border-color:${props => props.theme.darkbluegrey};
  }
  & .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color:${props => props.theme.darkbluegrey};
  }
  & .ant-steps-item-finish > .ant-steps-item-tail::after {
    background:${props => props.theme.darkbluegrey};
  }
`
