import React from 'react'
import styled from '@emotion/styled'

const Container = styled('div')`
  display: flex;
  flex-direction: row;
  padding: 2rem;
  justify-content: center;
  align-items: center;
  text-align: center;
`
const Title = styled('div')`
  font-size: 1rem;
  color: ${props => props.theme.slightlydarkergrey};
`

const NoData = () => {
  return (
    <Container>
      <Title>
        Data unavailable, please select a different model or a different hub.
      </Title>
    </Container>
  )
}

export default NoData
