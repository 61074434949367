import React from 'react'
import styled from '@emotion/styled'
import { Select } from 'antd'

import Row from '../../common/Row'
import Subtitle from '../../common/Subtitle'

const Option = Select.Option

const Container = styled(Row)`
  padding: 0.5rem 0.75rem;  
  border-radius: 4px 4px 0px 0px;
  align-items: center;
  flex-shrink: 0;
  background-color: ${props => props.theme.darkbluegrey};
`
const SelectContainer = styled(Select)`
  width: 230px;
  margin: 0 1rem;
  margin-right: 0;
`
const Text = styled(Subtitle)`
  display:flex;
  flex-grow: 1;
  color: ${props => props.theme.white};
`

const SelectLGA = props => {
  const { lga, lgas, setLGA } = props
  return (
    <Container>
      <Text>Select LGA</Text>
      <SelectContainer defaultValue={lga}>
        {lgas && lgas.map(lga => (
          <Option key={lga.name} value={lga.name} onClick={()=>setLGA(lga)}>
            {lga.name}
          </Option>
        ))}
      </SelectContainer>
    </Container>
  )
}

export default SelectLGA
