const rootUrl = process.env.REACT_APP_API

export function queryLGAs() {
  const url = `${rootUrl}/geojson`
  let body = {
    req_params: {
      fileKey: 'Geojson/LGA_2016_WA.geojson',
    },
  }
  return fetch(url, {
    method: 'post',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })
}
