import React, { Component } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import ModelPanel from '../../hoc/ModelPanel'

import Windrose from './HubsWindRose'
import HubActivitiesChart from './HubActivitiesChart'
import ActivitiesCompareChart from './ActivitiesComparisonChart'
import HubType from './HubType'
import NoData from '../NoData'
import { ModelHeader } from '../ModelContainer/CommonComponents'

const BehaviourContainer = styled('div')`
  flex: 1 1 auto;
  padding: 1rem 2rem;
`

class Behaviour extends Component {
  render() {
    const { activitiesHub, activitiesComparison, windroseData } = this.props

    if (!windroseData) return <NoData />

    return (
      <BehaviourContainer>
        {activitiesComparison && (
          <ModelHeader heading={"Travel Behaviour Model"}
            description={"The Travel Behaviour Model is a data mining model \
            which takes all of the SmartRider tag-on and tag-off data and \
            looks for patterns within it. The one pattern which emerges is \
            the length of time between tag-off and tag-on. When this data \
            is grouped, patterns emerge which can be readily explained by \
            daily usage. For example, if a person tags-off at 9AM and tags-on \
            again at 5PM, one can assume that this is an “office” pattern, \
            for 9AM to 3PM a “school” pattern, for 5PM to 8AM a “residential” \
            pattern. In this manner, the users at stations have been grouped \
            and categorised. The data clearly shows where the major schools \
            and universities are, where the dormitory suburbs are and office \
            concentrations. It thus tells you about the surrounding \
            development. Interestingly, the data also displays “hubs”, or \
            clusters of stations with the same pattern. When the clusters \
            were aggregated into hubs, it is clear that some of the \
            busiest hubs are not on the railway lines but are at hospitals, \
            universities and around schools. This informs us of the main \
            user profiles in the system. Interestingly, the most common \
            users - university and school students - are also subsidised \
            users."}
            academic={"https://doi.org/10.1145/3360322.3360870"} />
        )}
        {activitiesComparison && (
          <HubType
            hubType={activitiesComparison['highlightHub'][0]['hubType']
              .split(/(?=[A-Z])/)
              .join(' ')}
          />
        )}
        {activitiesHub && <HubActivitiesChart data={activitiesHub} />}
        {activitiesComparison && (
          <ActivitiesCompareChart data={activitiesComparison} />
        )}
        {windroseData && (
          <Windrose data={windroseData.data} hubId={windroseData.hubId} />
        )}
      </BehaviourContainer>
    )
  }
}

Behaviour.propTypes = {
  activitiesHub: PropTypes.arrayOf(PropTypes.object),
  activitiesComparison: PropTypes.object,
  windroseData: PropTypes.object,
}

export default ModelPanel(Behaviour, 'travel behaviour')
