import { Search } from '../constants/actionTypes'
import { querySearch } from '../queries/search'

const { GET_RESULTS, GET_RESULTS_SUCCESS, GET_RESULTS_FAILURE } = Search

export const handleSearch = payload => {
  return function(dispatch) {
    dispatch(getResults(payload))
    querySearch(payload)
      .then(res => res.json())
      .then(json => dispatch(getResultsSuccess(json)))
      .catch(err => dispatch(getResultsFailure(err)))
  }
}

export const getResults = payload => ({
  type: GET_RESULTS,
  payload,
})

export const getResultsSuccess = payload => ({
  type: GET_RESULTS_SUCCESS,
  payload,
})

export const getResultsFailure = payload => ({
  type: GET_RESULTS_FAILURE,
  payload,
})
