import { Map } from '../constants/actionTypes'

const { UPDATE_VIEWPORT, SET_CENTER, TOGGLE_LAYER , SET_MAP} = Map

export const initialState = {
  viewport: {
    zoom: 9,
    maxZoom: 20,
    minZoom: 0,
    pitch: 0,
    bearing: 0,
  },
  center: [115.82183992548926, -31.99748894521732],
  map: null
}

export default function(state = initialState, { type, payload }) {
  switch (type) {
    case SET_CENTER: {
      return {
        ...state,
        center: payload,
      }
    }
    case SET_MAP: {
      return {
        ...state,
        map: payload
      }
    }
    case UPDATE_VIEWPORT: {
      const { center, zoom, pitch } = payload
      return {
        ...state,
        center,
        viewport: {
          ...state.viewport,
          zoom,
          pitch,
        },
      }
    }
    case TOGGLE_LAYER: {
      return {
        ...state,
        showLayer: !state.showLayer,
      }
    }
    default:
      return state
  }
}
