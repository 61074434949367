import React from 'react'
import styled from '@emotion/styled'
import Row from '../../../common/Row'
import Column from '../../../common/Column'
import { Input , Button} from 'antd'

const ShadedPanel = styled(Column)`
  background: #f8f8f8;
  border-radius: 3px 3px 3px 3px;
  padding: 32px;
`

const InfoText = styled('div')`
  color: #343434;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 18px;
  margin-bottom: 40px;
`
const NameText = styled('div')`
  color: #343434;
  font-size: 12px;
  font-weight: 600;
  height: 42px;
  letter-spacing: 0;
  line-height: 21px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 190px;
`

const ResultText = styled('div')`
  color: #333333;
  font-size: 14px;
  font-weight: normal;
  height: 21px;
  letter-spacing: 0;
  line-height: 21px;
`

const BreakLine = styled('div')`
  border: 1px solid #dfe3e9;
  height: 1px;
  width: 283px;
  margin-bottom: 15px;
`

const TextRow = styled(Row)`
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
`

const SmallInput = styled('div')`
  width: 100px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #e0e0e0;
`

function LeftPanel({
  newPop,
  workingAgePop,
  existingJobs,
  selfSufficiencyTarget,
  updateState,
  ratios,
  toggleClustersActive
}) {
  let totalJobs = (
    (newPop * (workingAgePop / 100) - existingJobs) *
    (selfSufficiencyTarget / 100)
  ).toFixed(0)

  let populationDriven = (totalJobs * (1 - ratios['Ratio per LGA'])).toFixed(0)
  let strategic = (totalJobs * ratios['Ratio per LGA']).toFixed(0)
  
  return (
    <ShadedPanel>
      <InfoText>
        Calculation of number of jobs needed to service population growth
      </InfoText>

      <TextRow>
        <NameText>Population 2032</NameText>
        <ResultText>{newPop}</ResultText>
      </TextRow>
      <TextRow>
        <NameText>Working age population as a % of total</NameText>
        <SmallInput>
          <Input
            value={workingAgePop}
            onChange={e => {
              updateState({ workingAgePop: e.target.value })
            }}
            addonAfter={'%'}
          />
        </SmallInput>
      </TextRow>
      <TextRow>
        <NameText>Existing jobs</NameText>
        <SmallInput>
          <Input
            value={existingJobs}
            onChange={e => {
              updateState({ existingJobs: e.target.value })
            }}
          />
        </SmallInput>
      </TextRow>
      <TextRow>
        <NameText>Jobs-housing balance (LGA employment self-sufficiency target)</NameText>
        <SmallInput>
          <Input
            value={selfSufficiencyTarget}
            onChange={e => {
              updateState({ selfSufficiencyTarget: e.target.value })
            }}
            addonAfter={'%'}
          />
        </SmallInput>
      </TextRow>
      <BreakLine />
      <TextRow>
        <NameText>Additional employment required</NameText>
        <ResultText>{totalJobs}</ResultText>
      </TextRow>
      <TextRow>
        <NameText>Employment created in services for population</NameText>
        <ResultText>{populationDriven}</ResultText>
      </TextRow>
      <TextRow>
        <NameText>Additional strategic employment creation for LGA</NameText>
        <ResultText>{strategic}</ResultText>
      </TextRow>

      <Button onClick={()=>toggleClustersActive()}>Back to Clusters</Button>
    </ShadedPanel>
  )
}

export default LeftPanel
