import React, { PureComponent } from 'react'
import styled from '@emotion/styled'

import { queryActiveTransport } from '../../queries/activeTransport'

import Inputs from './Inputs'
import Results from './Results'
import Info from './Info'

import Title from '../../common/Title'
import Column from '../../common/Column'
import Row from '../../common/Row'

const Container = styled(Column)`
    padding: 1rem 0;
`
const InnerContainer = styled(Row)`
    margin-top:1rem;
`
const TitleContainer = styled(Title)`
    padding: 0.5rem 0.75rem;
    color:${props => props.theme.white};
    border-radius: 4px 4px 0px 0px;
    background-color: ${props => props.theme.darkbluegrey};
`
class ActiveTransport extends PureComponent {

    state = {
        info: null,
        submittedParams: null,
        results: null
    }
    
    handleSubmit = params => {
        queryActiveTransport(params)
            .then(res => res.json())
            .then(results => this.setState({submittedParams: params, results}))
            .catch(err => console.log(err))
    }

    setActiveTravelParams = params => this.props.setActiveTravelParams(params)

    handleInfo = info => this.setState({info})

    render(){
        const { submittedParams, results, info } = this.state
        const { currentParams } = this.props
        return (
            <Container>
                <TitleContainer>Active travel infrastructure investment rapid appraisal tool</TitleContainer>
                <InnerContainer>
                    <Inputs handleInfo={this.handleInfo} setActiveTravelParams={this.setActiveTravelParams} handleSubmit={this.handleSubmit} />
                    <Column>
                        <Results currentParams={currentParams} submittedParams={submittedParams} results={results} />
                        <Info data={info}/>
                    </Column>
                </InnerContainer>
            </Container>
        )
    }
}
export default ActiveTransport