import { ScenarioPlanner } from '../constants/actionTypes'
import { queryStructurePlans } from '../queries/population'
import {queryStnBuffers, queryWorkingpop} from "../queries/scenarioplanner"
const {
  SET_LGA,
  SET_ORIGIN_CLUSTER,
  SET_TARGET_CLUSTER,
  SET_EMPLOYMENT_RESULT,
  SET_OCCUPANCY_RATE,
  GET_STRUCTUREPLANS,
  GET_STRUCTUREPLANS_FAILURE,
  GET_STRUCTUREPLANS_SUCCESS,
  SET_STRUCTUREPLAN_POP,
  SET_FUTURE_STN_POP,
  GET_STN_BUFFERS,
  GET_STN_BUFFERS_FAILURE,
  GET_STN_BUFFERS_SUCCESS,
  GET_WORKINGPOP,
  GET_WORKINGPOP_FAILURE,
  GET_WORKINGPOP_SUCCESS,
  TOGGLE_CLUSTERS_ACTIVE,
  SET_EMPLOYMENT_CREATED
} = ScenarioPlanner

export const loadStructurePlans = () => {
  return function(dispatch) {
    dispatch(getStructurePlans())
    queryStructurePlans()
      .then(res => res.json())
      .then(json => dispatch(getStructurePlansSuccess(json)))
      .catch(err => dispatch(getStructurePlansFailure(err)))
  }
}

export const loadStnBuffers = () => {
  return function(dispatch) {
    dispatch(getStnBuffers())
    queryStnBuffers()
      .then(res => res.json())
      .then(json => dispatch(getStnBuffersSuccess(json)))
      .catch(err => dispatch(getStnBuffersFailure(err)))
  }
}

export const loadWorkingpop = () => {
  return function(dispatch) {
    dispatch(getWorkingpop())
    queryWorkingpop()
      .then(res => res.json())
      .then(json => dispatch(getWorkingpopSuccess(json)))
      .catch(err => dispatch(getWorkingpopFailure(err)))
  }
}

export const setLGA = payload => ({
  type: SET_LGA,
  payload,
})

export const setOriginCluster = payload => ({
  type: SET_ORIGIN_CLUSTER,
  payload,
})
export const setTargetCluster = payload => ({
  type: SET_TARGET_CLUSTER,
  payload,
})

export const setEmploymentResult = payload => ({
  type: SET_EMPLOYMENT_RESULT,
  payload,
})

export const setOccupancyRate = payload => ({
  type: SET_OCCUPANCY_RATE,
  payload,
})

export const setStructurePlanPop = payload => ({
  type: SET_STRUCTUREPLAN_POP,
  payload,
})

export const getStructurePlans = () => ({
  type: GET_STRUCTUREPLANS,
})

export const getStructurePlansSuccess = payload => ({
  type: GET_STRUCTUREPLANS_SUCCESS,
  payload,
})

export const getStructurePlansFailure = payload => ({
  type: GET_STRUCTUREPLANS_FAILURE,
  payload,
})

export const setFutureStnPop = payload => ({
  type: SET_FUTURE_STN_POP,
  payload,
})


export const getStnBuffers = () => ({
  type: GET_STN_BUFFERS,
})

export const getStnBuffersSuccess = payload => ({
  type: GET_STN_BUFFERS_SUCCESS,
  payload,
})

export const getStnBuffersFailure = payload => ({
  type: GET_STN_BUFFERS_FAILURE,
  payload,
})


export const getWorkingpop = () => ({
  type: GET_WORKINGPOP,
})

export const getWorkingpopSuccess = payload => ({
  type: GET_WORKINGPOP_SUCCESS,
  payload,
})

export const getWorkingpopFailure = payload => ({
  type: GET_WORKINGPOP_FAILURE,
  payload,
})

export const toggleClustersActive = (payload) => ({
  type: TOGGLE_CLUSTERS_ACTIVE
})

export const setEmploymentCreated = (payload) => ({
  type: SET_EMPLOYMENT_CREATED,
  payload
})