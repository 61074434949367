import React, { PureComponent } from 'react'
import styled from '@emotion/styled'
import image from '../../assets/logo.png'

import Row from '../../common/Row'

const LogoContainer = styled(Row)`
  align-items: center;
  flex-grow: 1;
`
const LogoImg = styled('img')`
  height: 1.75rem;
  text-align: center;
`
const LogoText = styled('div')`
  font-size: 1.2rem;
  color: ${props => props.theme.white};
  border-left: solid 1px;
  border-left-color: ${props => props.theme.darkgrey};
  padding-left: 0.5rem;
  margin-left: 0.5rem;
  visibility: hidden;
  width: 0px;
  @media (min-width: 1200px) {
    visibility: visible;
    width: fit-content;
  }
`
class Logo extends PureComponent {
  render() {
    return (
      <LogoContainer>
        <a
          target="_blank"
          rel="noreferrer noopener"
          href={'http://www.railsmartwa.net.au/'}
        >
          <LogoImg src={image} alt="RailSmart" />
        </a>{' '}
        <LogoText>Planning Support System</LogoText>
      </LogoContainer>
    )
  }
}
export default Logo
