import { connect } from 'react-redux'
import Map from '../components/Map'

import { selectorMapWidth, selectorMapCenter } from '../selectors/map-selector'
import {selectFeature} from "../actions/context"
import {setMap} from "../actions/map"

export default connect(
  state => {
    return {
      panelSize: selectorMapWidth(state),
      center: selectorMapCenter(state),
    }
  },
  { selectFeature, setMap }
)(Map)
