import React, { PureComponent } from 'react'
import styled from '@emotion/styled'
import { scaleLinear } from 'd3-scale'
import { min, max } from 'd3-array'

import Column from '../../common/Column'
import Row from '../../common/Row'
import Subtitle from '../../common/Subtitle'
import clusterColors from '../../config/clusters'
import { Object } from 'es6-shim'

const ClusterContainer = styled(Column)`
  padding: 1rem 0;
  flex-wrap: wrap;
  height: 280px;
  width: ${props => props.width}px;
`
const Cluster = styled(Row)`
  height: 30px;
  margin: 1rem 0;
  align-items: center;
`

const ClusterCol = styled(Column)`
  height: 30px;
  justify-content: center;
  align-items: flex-start;
`

const Name = styled('p')`
  width: 70px;
  font-size: 12px;
  font-weight: 300px;
  margin-bottom: 0px;
`
const Value = styled('div')`
  margin-bottom: 5px;
  width: 80px;
  text-align: center;
  font-weight: 600;
  border-radius: 3px;
`
const SVG = styled('svg')`
  margin: 0;
`

const Smalltext = styled('text')`
  font-size: 9px;
`

const HEIGHT = 12
const WIDTH = 210
const colors = {
  low: clusterColors["1"],
  medium: clusterColors["2"],
  high: clusterColors["3"],
}

const ClusterRange = ({ data }) => {
  const { name, value, newRange, ranges } = data
  const { low, medium, high } = ranges
  const minValue = min([low, medium, high, [value]].map(f => f[0]))
  const maxValue = max([low, medium, high, [value,value]].map(f => f[1]))
  const scale = scaleLinear()
    .domain([minValue, maxValue])
    .range([0, WIDTH])

  return (
    <Cluster>
      <Name>{name}</Name>
      <Value>{value}</Value>
      <ClusterCol>
        <SVG height={HEIGHT} width={WIDTH}>
          <g className="axis">
            <line
              stroke="#333"
              x1={1}
              x2={1}
              y1={0}
              y2={HEIGHT}
              strokeWidth={2}
            />

            <line
              stroke="#333"
              x1={WIDTH - 1}
              x2={WIDTH - 1}
              y1={0}
              y2={HEIGHT}
              strokeWidth={2}
            />
            <line
              stroke="#333"
              x1={0}
              x2={WIDTH}
              y1={HEIGHT / 2}
              y2={HEIGHT / 2}
              strokeWidth={1}
            />
          </g>

          <g className="ranges">
            {Object.keys(ranges).map((key, i) => {
              const x1 = scale(ranges[key][0])
              const x2 = scale(ranges[key][1])
              const color = colors[key]
              const y = HEIGHT / 2
              return (
                <g key={key + "g"}>
                  <line
                    key={key}
                    className={key}
                    stroke={color}
                    x1={x1}
                    x2={x2}
                    y1={y}
                    y2={y}
                    opacity={0.5}
                    strokeWidth={HEIGHT}
                  />
                </g>
              )
              // const y = ((HEIGHT/3) * i) + ((HEIGHT/3)/2)
              // return <line className={key} stroke={color} x1={x1} x2={x2} y1={y} y2={y} opacity={0.7} strokeWidth={HEIGHT/3}/>
            })}
          </g>
          <g
            transform={`translate(${scale(newRange[0])})`}
            className="new-range"
          >
            <rect
              stroke="#333"
              strokeWidth={1}
              fillOpacity={0.4}
              fill={'#fff'}
              width={scale(newRange[1]) - scale(newRange[0])}
              height={HEIGHT}
            />
          </g>
          <g className="current-value">
            <circle
              cx={scale(value)}
              cy={HEIGHT / 2}
              r={5}
              stroke="#333"
              strokeWidth={2}
              fill="white"
            />
          </g>
        </SVG>
        <SVG width={WIDTH} height={14}>
          <g>
            <Smalltext
              x={1}
              y={HEIGHT}
              children={minValue}
              textAnchor={'start'}
            />
            <Smalltext
              x={WIDTH}
              y={HEIGHT}
              children={maxValue}
              textAnchor={'end'}
            />
          </g>
        </SVG>
      </ClusterCol>
    </Cluster>
  )
}

const Legend = () => (
  <svg width={150}>
    <rect
      stroke={colors['low']}
      strokeWidth={5}
      strokeOpacity={0.4}
      fill={'#fff'}
      width={10}
      height={5}
      x={15}
      y={60}
    />
    <text x={35} y={62.5} alignmentBaseline={'middle'}>
      Cluster 1 range
    </text>
    <rect
      stroke={colors['medium']}
      strokeWidth={5}
      strokeOpacity={0.4}
      fill={'#fff'}
      width={10}
      height={5}
      x={15}
      y={90}
    />
    <text x={35} y={92.5} alignmentBaseline={'middle'}>
      Cluster 2 range
    </text>
    <rect
      stroke={colors['high']}
      strokeWidth={5}
      strokeOpacity={0.4}
      fill={'#fff'}
      width={10}
      height={5}
      x={15}
      y={120}
    />
    <text x={35} y={122.5} alignmentBaseline={'middle'}>
      Cluster 3 range
    </text>
    <rect
      stroke={'black'}
      strokeWidth={1}
      strokeOpacity={0.4}
      fill={'#fff'}
      width={12}
      height={8}
      x={13}
      y={150}
    />
    <text x={35} y={154} alignmentBaseline={'middle'}>
      Target range
    </text>
    <circle
      stroke={'black'}
      strokeWidth={2}
      fill={'#fff'}
      r={5}
      cx={20}
      cy={180}
    />
    <text x={35} y={180} alignmentBaseline={'middle'}>
      Current score
    </text>
  </svg>
)

const PARAMETERS = {
  'Empl1.6': 'Employment Density (Jobs/km^2)',
  'Commercial2016_1.6_21Jan19': 'Commercial POI',
  'walk_score_1.6_21Jan19': 'Walk Score',
  'bikeroute_total_1.6_21Jan19': 'Bike Routes',
  'Pop_den_1.6': 'Population Density (People/km^2)',
  'No.ofPRbays2011STEM': 'PnR Bays',
  'Workers_45mins_PT_%': 'Workers 45min PT',
  TOTALBusServices: 'Total Bus Services',
}

class LGALevelSummaryRanges extends PureComponent {
  transformClusters(clusters, existingAverages, futureRange) {
    const data = Object.keys(clusters).map(paramName => {
      let current = clusters[paramName]
      return {
        name: PARAMETERS[paramName],
        ranges: {
          low: current[1],
          medium: current[2],
          high: current[3],
        },
        newRange: futureRange[paramName],
        value: existingAverages[paramName].toFixed(2),
      }
    })
    return data
  }
  render() {
    const { existingAverages, clusters, futureRange } = this.props
    if (clusters && existingAverages && futureRange) {
      let results = this.transformClusters(
        clusters,
        existingAverages,
        futureRange
      )
      return (
        <Row>
          <Column>
            <Subtitle>LGA Summary of cluster change results</Subtitle>
            <ClusterContainer width={WIDTH * 4}>
              {results.map((data, i) => (
                <ClusterRange key={'cluster ' + i} data={data} />
              ))}
            </ClusterContainer>
          </Column>
          <Legend />
        </Row>
      )
    }
    return null
  }
}

export default LGALevelSummaryRanges
