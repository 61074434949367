import React from 'react'
import styled from '@emotion/styled'
import { Icon } from 'antd'

import MiniTitle from '../../common/MiniTitle'

const Container = styled('div')`
    padding: 2rem;
    width:300px;
    background-color: ${props => props.theme.lightgrey};
    border-radius:3px;
`    
const InfoIcon = styled(Icon)`
    margin-bottom: 1rem;
    color: ${props => props.theme.darkgrey};
`
const InfoText = styled('div')`
    font-size:12px;
    font-weight:normal;
`

const Info = ({data}) => {
    if(!data || !data.hasOwnProperty('label')|| !data.hasOwnProperty('info') ) return null
    const { label, info } = data
    return (
        <Container>
            <InfoIcon type="info-circle" />
            <MiniTitle>{ label }</MiniTitle>
            <InfoText>
                {info}
            </InfoText>
        </Container>
    )
}
export default Info