import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import SA2RatioChart from './SA2RatioChart'
import LGARatioChart from './LGARatioChart'

const Container = styled('div')`
  padding: 0 2rem;
`
const Header = styled('div')`
  font-size: 16px;
  font-weight: 600;
  padding: 1rem 0;
`
const SubHeader = styled('div')`
  font-size: 12px;
  padding-bottom: 0.5rem;
  text-align: center;
`
const StrategicPopulation = props => {
  const { selectedComparison, selectedHub, selectedLGAComparison, hubs } = props
  return (
    <Container>
      <Header>Strategic to population-driven jobs balance</Header>
      <SubHeader>
        Ratio of strategic to population-driven jobs across all <b>LGAs </b>
        in Perth metro
      </SubHeader>
      <LGARatioChart data={selectedLGAComparison} selectedHub={selectedHub} />
      <SubHeader>
        Ratio of strategic to population-driven jobs across all <b>SA2s </b>
        in Perth metro
      </SubHeader>
      <SA2RatioChart
        data={selectedComparison}
        lga={selectedLGAComparison}
        selectedHub={selectedHub}
        hubs={hubs}
      />
    </Container>
  )
}
StrategicPopulation.propTypes = {
  selectedComparison: PropTypes.object,
  selectedHub: PropTypes.object,
  selectedLGAComparison: PropTypes.object,
}
export default StrategicPopulation
