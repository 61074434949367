import React, { PureComponent } from 'react'
import { XYPlot, ArcSeries, LabelSeries } from 'react-vis'
import styled from '@emotion/styled'

const Container = styled('div')`
  padding: 0.5rem 0;
`
const Title = styled('div')`
  font-size: 12px;
  font-weight: 600;
`
const LegendContainer = styled('div')`
  padding-top: 5px;
  display: flex;
  flex-direction: row;
`
const LegendItem = styled('div')`
  margin: 1rem 0.5rem;
  padding: 0.1rem 0.5rem;
  background-color: ${props => props.color};
`
const LegendText = styled('div')`
  font-size: 12px;
  font-weight: normal;
`
const ChartContainer = styled('div')`
  margin-left: -15px;
`

const WIDTH = 270
const HEIGHT = 270

const legendItems = [
  { name: 'Place indicators', color: '#3152FB' },
  { name: 'Node indicators', color: '#FF9505' },
]

class Indicators extends PureComponent {
  render() {
    const { data } = this.props
    const { radialData, outerRing, segments, labelsNode, labelsPlace } = data
    return (
      <Container>
        <Title>Key node and place key indicator for hub</Title>
        <LegendContainer>
          {legendItems.map(f => {
            return (
              <LegendContainer key={f.name}>
                <LegendItem color={f.color} />
                <LegendText>{f.name}</LegendText>
              </LegendContainer>
            )
          })}
        </LegendContainer>
        <ChartContainer>
          <XYPlot
            xDomain={[-5, 5]}
            yDomain={[-5, 5]}
            width={WIDTH}
            height={HEIGHT}
            margin={{ left: 0, right: 0, top: 0, bottom: 0 }}
          >
            <ArcSeries
              animation
              radiusType={'literal'}
              radiusDomain={[0, WIDTH]}
              data={segments}
              colorType={'literal'}
              stroke={'#d8d8d8'}
            />
            <ArcSeries
              animation
              radiusType={'literal'}
              radiusDomain={[0, WIDTH]}
              data={outerRing}
              colorType={'literal'}
              stroke={'#999'}
            />
            <ArcSeries
              animation
              radiusType={'literal'}
              radiusDomain={[0, WIDTH]}
              data={radialData}
              colorType={'literal'}
              strokeType={'literal'}
            />
            <LabelSeries
              data={labelsNode}
              labelAnchorX={'start'}
              labelAnchorY={'start'}
              style={{
                fontSize: 8,
              }}
            />
            <LabelSeries
              data={labelsPlace}
              labelAnchorX={'end'}
              labelAnchorY={'end'}
              style={{
                fontSize: 10,
              }}
            />
          </XYPlot>
        </ChartContainer>
      </Container>
    )
  }
}

export default Indicators
