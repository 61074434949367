import { connect } from 'react-redux'
import Legend from '../components/Legend'

import { selectorShowSA2Layer } from '../selectors/sa2Layer-selector'

export default connect(state => {
  return {
    showAccessibility: selectorShowSA2Layer(state),
    direction: state.accessibility.direction,
    selectedHub: state.hubs.selectedHub,
    mode: state.accessibility.travel_mode,
  }
})(Legend)
