import {
    connect
  } from 'react-redux'
  import PrintView from '../components/PrintView'
  import {
    selectorClusterType
  } from '../selectors/nodeplace-selector'
  import {
    selectorOriginTargetCluster
  } from '../selectors/scenario-clusterchange-selector'
  import { 
    selectorLGAPopulation, selectorLGAName
  } from '../selectors/lgaLevelResults-selector'
  import {
    selectorClusterScenario
  } from '../selectors/printView-selector'
  export default connect(
    state => {
      return {
        clusterType: selectorClusterType(state),
        originTargetCluster: selectorOriginTargetCluster(state),
        lga: selectorLGAName(state),
        population: selectorLGAPopulation(state),
        newPatronage: state.scenarioplanner.futureStnPatronage,
        employment: state.scenarioplanner.employmentResult,
        clusterList: selectorClusterScenario(state)
      }
    }, {
    }
  )(PrintView)