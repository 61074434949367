import React, { PureComponent } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { Analytics } from 'aws-amplify';

import PrimaryButton from '../../common/PrimaryButton'

const RunModelContainer = styled('div')`
  width: 100%;
  padding-top: 1rem;
  position: relative;
`
const Skeleton = styled('div')`
  background-color: #f0f0f0;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  margin: ${props => props.margin}rem 0;
`
const BtnContainer = styled('div')`
  position: absolute;
  top: 40%;
  width: 100%;
`
const Button = styled(PrimaryButton)`
  box-shadow: 1px 1px 4px 0 #b4b4b4;
`
const fakeAxis = Array(10)
  .fill(null)
  .map((u, i) => i)

class RunModel extends PureComponent {
  runModel = () => {
    this.props.runModel();
    // Log Analytics Event
    Analytics.record({ name: "Accessibility-RunModel" });
  }
  render() {
    return (
      <RunModelContainer>
        <BtnContainer>
          <Button onClick={this.runModel} block>
            Run Model
          </Button>
        </BtnContainer>
        <Skeleton height={15} width={150} margin={0.5} />
        <Skeleton height={10} width={200} margin={0.5} />
        <Skeleton height={10} width={200} margin={0.5} />
        {fakeAxis.map(f => (
          <Skeleton key={f} margin={1} height={3} width={215} />
        ))}
        <Skeleton height={15} width={150} margin={1} />
        <Skeleton height={7} width={100} margin={1} />
      </RunModelContainer>
    )
  }
}

RunModel.propTypes = {
  runModel: PropTypes.func.isRequired,
}

export default RunModel
