import styled from '@emotion/styled'
import { Button } from 'antd'
export default styled(Button)`
  font-size: 1rem;
  line-height: 1.5rem;
  background-color: ${props => props.theme.topaz};
  border-color: ${props => props.theme.topaz};
  color: ${props => props.theme.white};
  &.visited {
    background-color: ${props => props.theme.yellow};
    border-color: ${props => props.theme.yellow};
    color: ${props => props.theme.white};
  }
  &:focus,
  &:hover,
  &:active {
    background-color: ${props => props.theme.yellow};
    border-color: ${props => props.theme.yellow};
    color: ${props => props.theme.white};
  }
`
