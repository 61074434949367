import React from 'react'
import MiniTitle from '../../common/MiniTitle'
import { Select } from 'antd'
const Option = Select.Option

const EmploymentType = ({ onChange, options, selected }) => {
  const change = value => onChange({ key: 'selectedEmploymentType', value })
  return (
    <div>
      <MiniTitle>Employment Type</MiniTitle>
      <Select
        style={{ width: 275 }}
        onChange={change}
        placeholder={'Select an employment type'}
        value={selected ? selected : undefined}
      >
        {options.map(f => (
          <Option key={f} value={f}>
            {f}
          </Option>
        ))}
      </Select>
    </div>
  )
}

export default EmploymentType
