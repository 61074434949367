import { connect } from 'react-redux'
import Loading from '../components/Loading'

export default connect(
  state => {
    return {
      isLoading: state.loading,
    }
  },
  {}
)(Loading)
