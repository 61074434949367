import React, { PureComponent } from 'react'
import styled from "@emotion/styled"
import { XYPlot, ArcSeries, LabelSeries, DiscreteColorLegend} from 'react-vis'

const DiscreteColorLegendContainer = styled(DiscreteColorLegend)`
  font-size: 11px;
  & .rv-discrete-color-legend-item {
    padding: 4px;
  }
`
const WIDTH = 240
const HEIGHT = 255
class RangeComparisonChart extends PureComponent {
  render() {
    const { data } = this.props
    const { radialData, segments, labelsNode, labelsPlace, legend } = data
    return (
      <XYPlot
        xDomain={[-5, 5]}
        yDomain={[-5, 5]}
        width={WIDTH}
        height={HEIGHT}
        margin={{ left: 0, right: 0, top: 15, bottom: 0 }}
      >
        <ArcSeries
          animation
          radiusType={'literal'}
          radiusDomain={[0, WIDTH]}
          data={segments}
          colorType={'literal'}
          stroke={'#d8d8d8'}
        />
        {/* <ArcSeries
          animation
          radiusType={'literal'}
          radiusDomain={[0, WIDTH]}
          data={outerRing}
          colorType={'literal'}
          stroke={'#999'}
        /> */}
        <ArcSeries
          animation
          radiusType={'literal'}
          radiusDomain={[0, WIDTH]}
          data={radialData}
          colorType={'literal'}
          strokeType={'literal'}
        />
        <LabelSeries
          data={labelsNode}
          labelAnchorX={'start'}
          labelAnchorY={'start'}
          style={{
            fontSize: 10,
          }}
        />
        <LabelSeries
          data={labelsPlace}
          labelAnchorX={'end'}
          labelAnchorY={'end'}
          style={{
            fontSize: 10,
          }}
        />
        <DiscreteColorLegendContainer
          items={legend}
          orientation={'vertical'}
        />
      </XYPlot>
    )
  }
}

export default RangeComparisonChart
