import { Nodeplace } from '../constants/actionTypes'
import { queryNodeplace, queryClusters } from '../queries/nodeplace'

const {
  GET_NODEPLACE,
  GET_NODEPLACE_SUCCESS,
  GET_NODEPLACE_FAILURE,
  GET_CLUSTERS,
  GET_CLUSTERS_FAILURE,
  GET_CLUSTERS_SUCCESS,
  SET_DISTANCE,
} = Nodeplace

export const loadNodeplace = () => {
  return function(dispatch) {
    dispatch(getNodeplace())
    queryNodeplace()
      .then(res => res.json())
      .then(json => dispatch(getNodeplaceSuccess(json)))
      .catch(err => dispatch(getNodeplaceFailure(err)))
  }
}

export const loadClusters = () => {
  return function(dispatch) {
    dispatch(getClusters())
    queryClusters()
      .then(res => res.json())
      .then(json => dispatch(getClustersSuccess(json)))
      .catch(err => dispatch(getClustersFailure(err)))
  }
}


export const setDistance = payload => ({
  type: SET_DISTANCE,
  payload,
})

export const getNodeplace = () => ({
  type: GET_NODEPLACE,
})

export const getNodeplaceSuccess = payload => ({
  type: GET_NODEPLACE_SUCCESS,
  payload,
})

export const getNodeplaceFailure = payload => ({
  type: GET_NODEPLACE_FAILURE,
  payload,
})

export const getClusters = () => ({
  type: GET_CLUSTERS,
})

export const getClustersSuccess = payload => ({
  type: GET_CLUSTERS_SUCCESS,
  payload,
})

export const getClustersFailure = payload => ({
  type: GET_CLUSTERS_FAILURE,
  payload,
})
