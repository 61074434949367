export default {
    modeChange: {
        label: 'Mode changer benefit',
        name: 'Mode changer benefit ($2018 m)',
        info: 'Mode changer benefit is the perceived benefits to mode changers. The benefit is derived from the mode split relationships incorporated in strategic transport planning models. These relationships reflect the change in mode shares between two modes that will result from changes in the relative perceived generalised costs of the two modes.'
    },
    carPark: {
        label: 'Car parking cost saving',
        name: 'Car parking cost saving ($2018 m)',
        info: 'Those who used to drive and pay for parking will be saving the parking costs by using other modes of transport.'
    },
    ptFare: {
        label: 'PT fare cost',
        name: 'PT fare benefit ($2018 m)',
        info: 'The additional PT fares collected from those who switch to PT are accounted as benefit.'
    },
    decongestion: {
        label: 'Decongestion benefit',
        name: 'Decongestion ($2018 m)',
        info: 'Benefit that is derived from reducing traffic and congestions.'
    },
    inducedTraffic: {
        label: 'Induced traffic effect (disbenefit)',
        name: 'Induced traffic effect ($2018 m)',
        info: 'Disbenefit associated with the induced traffic due to reduced congestions.'
    },
    accident: {
        label: 'Accident benefit',
        name: 'Accident ($2018 m)',
        info: 'Benefit that is derived from reducing car usage and avoiding accidents.'
    },
    health: {
        label: 'Health benefit',
        name: 'Health benefit ($2018 m)',
        info: 'Benefit that is derived from being active and improving health.'
    }
}