import React, { PureComponent } from 'react'
import styled from '@emotion/styled'

import legend_config from '../../config/legend'

const Container = styled('div')`
  padding-bottom: 1rem;
  width: 300px;
`
const Title = styled('div')`
  font-weight: normal;
  font-size: 14px;
`
const SubTitle = styled('div')`
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
`
const Info = styled('div')`
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
`
const LegendContainer = styled('div')`
  padding-bottom: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`
const LegendColumn = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: ${props => props.left};
`
const LegendItemContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding-top: 10px;
`
const LegendCircle = styled('div')`
  border-radius: 50%;
  margin-right: 30px;
  background-color: ${props => props.color};
  height: ${props => props.height}px;
  width: ${props => props.width}px;
`
const LegendText = styled('div')`
  font-weight: normal;
  font-size: 10px;
  width: ${props => props.width}px;
`

const Legend = ({ patronage, nodePlace }) => {
  return (
    <LegendContainer>
      <LegendColumn>
        <Title>Stays</Title>
        {Object.keys(patronage).map(key => (
          <LegendItemContainer key={key}>
            <LegendCircle height={patronage[key].size} width={patronage[key].size} color={'#0a5990'} />
            <LegendText width={patronage[key].size}>{key}</LegendText>
          </LegendItemContainer>
        ))}
      </LegendColumn>
      <LegendColumn left={'10px'}>
        <Title>Clusters</Title>
        {Object.keys(nodePlace).map(key => (
          <LegendItemContainer key={key}>
            <LegendCircle height={16} width={16} color={nodePlace[key].color} />
            <LegendText width={100}>{nodePlace[key].short}</LegendText>
          </LegendItemContainer>
        ))}
      </LegendColumn>
    </LegendContainer>
  )
}

class PatronageLegend extends PureComponent {
  render() {
    const legendPatronage = legend_config.legendPatronage
    const legendNodePlace = legend_config.legendNodePlace
    return (
      <Container>
        <Legend patronage={legendPatronage} nodePlace={legendNodePlace} />
        <SubTitle>SmartRider trips to/from hub</SubTitle>
        <Info>Data is a month of data from Aug 2017</Info>
      </Container>
    )
  }
}

export default PatronageLegend
