import React from 'react'
import styled from "@emotion/styled"
import { withRouter } from 'react-router-dom'

import Row from '../../common/Row'
import DefaultButton from '../../common/DefaultButton'
import ScenarioHelp from '../../common/ScenarioHelp'

const Container = styled(Row)`
    align-items: center;
    padding:0 0.75rem 0.75rem 0.75rem;
    flex-shrink: 0;
    justify-content: space-between;
`
const BtnContainer = styled(DefaultButton)`
    margin-left:0.25rem;
`
const ScenarioHelpContainer = styled(ScenarioHelp)`
    font-size:11px;
`
const DashboardSwitcher = props => {
    const sendMeHome = () => props.history.push('/')
    return (
        <Container>
            <ScenarioHelpContainer>To view more detailed statistical modelling, go to the dashboard view</ScenarioHelpContainer>
            <BtnContainer default onClick={sendMeHome}>Dashboard View</BtnContainer>
        </Container>
    )
}

export default withRouter(DashboardSwitcher)
