import React, { Component } from 'react'
import styled from '@emotion/styled'
import { Table, Button, Select } from 'antd'
import Column from '../../../common/Column'
const Option = Select.Option

const SpacedRow = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const MarginChunk = styled('div')`
  margin-left: 15%;
  margin-right: 20%;
`

const LeftAligned = styled('div')`
  text-align: left;
  width: 65%;
`
export default class SectorPicker extends Component {
  constructor(props) {
    super(props)
    this.onSelect = this.onSelect.bind(this)
  }
  onSelect(record) {
    const { that } = this.props
    let { selectedIndustries } = that.state

    if (
      !selectedIndustries.some(value => value['sector'] === record['sector'])
    ) {
      selectedIndustries.push(record)
    } else {
      selectedIndustries.splice(selectedIndustries.indexOf(record), 1)
    }

    that.setState({
      selectedIndustries: selectedIndustries,
      updateEmployment: true,
    })
  }

  render() {
    const { columns, data, that, selectedIndustries, allLQs } = this.props

    const Picker = styled(Table)`
      background-color: white;
      cursor: pointer;
      margin-bottom: 20px;
      width: 100%;
      & .ant-table-thead {
        font-size: 12px;
      }
    `

    let rowSelection = {
      selectedRowKeys: selectedIndustries.map(industry => industry.sector),
      onSelect: record => this.onSelect(record),
    }

    return (
      <Column>
        <Picker
          columns={columns}
          dataSource={data}
          size="small"
          height={285}
          rowKey={'sector'}
          rowSelection={rowSelection}
          pagination={false}
          onRow={record => ({
            onClick: event => this.onSelect(record),
          })}
        />

        <Select mode={'multiple'} placeholder={'Select other sectors'}>
          {allLQs &&
            allLQs.length > 0 &&
            allLQs.map(item => {
              return (
                <Option
                  key={item.sector}
                  value={item.sector}
                  onClick={e => this.onSelect(item) && e.stopPropagation()}
                >
                  <SpacedRow>
                    <LeftAligned>{item.sector}</LeftAligned>
                    <MarginChunk>{item.lq}</MarginChunk>
                    <div>{item.multiplier}</div>
                  </SpacedRow>
                </Option>
              )
            })}
        </Select>
        <p />
        <Button onClick={() => that.setState({ current: 1 })}>Next</Button>
      </Column>
    )
  }
}
