import styled from '@emotion/styled'
import { Radio } from 'antd'
export default styled(Radio.Button)`
  flex-grow: 1;
  text-align: center;
  &.ant-radio-button-wrapper-checked {
    border-color: ${props => props.theme.cyan};
    background-color: ${props => props.theme.cyan};
    color: ${props => props.theme.white};
    &:hover {
      color: ${props => props.theme.white}!important;
    }
  }
  &.ant-radio-button-wrapper:hover,
  .ant-radio-button-wrapper-focused {
    border-color: ${props => props.theme.cyan};
    color: ${props => props.theme.cyan};
  }
`
