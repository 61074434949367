import { createSelector } from 'reselect'

const lgaDataSelector = state => state.lgalayer.geo
const lgaSelector = state => state.scenarioplanner.lga

export const selectorSelectedLGA = createSelector(
  lgaSelector,
  lgaDataSelector,
  (lga, lgaData) => {
    if (lga && lgaData) {
      return {
        type: 'FeatureCollection',
        features: lgaData.features.filter(
          item => item.properties['LGA_NAME16'].toString() === lga.toString()
        ),
      }
    }
  }
)

export const selectorCenterLGA = createSelector(
  selectorSelectedLGA,
  selectedLGA => {
    return selectedLGA && center(selectedLGA.features[0].geometry.coordinates[0][0])
  }
)

function center(polygon) {

  function Point(x, y) {
    this.x = x
    this.y = y
  }

  function Region(points) {
    this.points = points || []
    this.length = points.length
  }

  Region.prototype.area = function() {
    var area = 0,
      i,
      j,
      point1,
      point2

    for (i = 0, j = this.length - 1; i < this.length; j = i, i++) {
      point1 = this.points[i]
      point2 = this.points[j]
      area += point1[0] * point2[1]
      area -= point1[1] * point2[0]
    }
    area /= 2

    return area
  }

  Region.prototype.centroid = function() {
    var x = 0,
      y = 0,
      i,
      j,
      f,
      point1,
      point2


    for (i = 0, j = this.length - 1; i < this.length; j = i, i++) {
      point1 = this.points[i]
      point2 = this.points[j]
      f = point1[0] * point2[1] - point2[0] * point1[1]
      x += (point1[0] + point2[0]) * f
      y += (point1[1] + point2[1]) * f
    }

    f = this.area() * 6

    let point = new Point(x / f, y / f)
    return point
  }

  let region = new Region(polygon)

  let centroid = region.centroid()
  
  return [centroid.x,centroid.y]
}
