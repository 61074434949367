import React, { PureComponent } from 'react'
import styled from '@emotion/styled'
import Column from '../../common/Column'

const AboutTextContainer = styled('div')`
  display: flex;
  flex-direction: row;
  color: ${props => props.theme.black};
`
const AboutP = styled('p')`
  font-size: 0.75rem;
  margin: 0.25rem;
  margin-bottom: 0.5rem;
  font-weight: normal;
  color: ${props => props.theme.black};
`

class AboutText extends PureComponent {
  render() {
    return (
      <AboutTextContainer>
        <Column>
          <AboutP>
            The proof-of-concept RailSmart Planning Support System demonstrates
            the application of academic research to better inform public 
            decision making. Using evidence-based modelling, future development 
            scenarios in terms of employment creation and public transport 
            patronage in the City of Wanneroo can be tested. These scenarios 
            can be used to better inform the development of new railway 
            stations within the City to maximise public transport patronage 
            and employment self-sufficiency. A cost-benefit analysis tool has 
            also been provided for the Department of Transport, which uses the 
            Australian Transport Assessment and Planning guidelines.
          </AboutP>
          <AboutP>
            The PSS was developed by a large team of consultants and academics 
            managed by the Planning and Transport Research Centre for the City 
            of Wanneroo and Department of Transport. It is a user-friendly 
            and modular system which can be further extended by future 
            research. Any questions surrounding the tool should be directed to&nbsp;
            <a href="mailto:linda.robson@uwa.edu.au">Dr Linda Robson</a>. A&nbsp;
            <a href="https://resources.patrec.org/reports/railsmart/umdo.pdf">
            report</a> has been produced as an overview and user manual for the 
            PSS along with a&nbsp;
            <a href="https://onesearch.library.uwa.edu.au/discovery/dbfulldisplay?docid=alma99925459102101&context=L&vid=61UWA_INST:UWA&lang=en">
            formal citation</a>. The three components that comprise the PSS are:
          </AboutP>
        </Column>
      </AboutTextContainer>
    )
  }
}

export default AboutText
