import React, { Component } from 'react'
import styled from '@emotion/styled'
import { Collapse, Icon } from 'antd'

import NodePlaceChangeChart from './NodePlaceChangeChart'
import ClusterListSelection from './ClusterListSelection'
import ChangeClusterTarget from './ChangeClusterTarget'
import ChangeClusterComparison from './ChangeClusterComparison'
import ChangeClusterResults from './ChangeClusterResults'
import LGALevelSummaryRanges from '../../../containers/LGALevelSummary'
import CollapseHeader from '../CollapseHeader'

import Row from '../../../common/Row'
import Column from '../../../common/Column'

const Panel = Collapse.Panel
const Container = styled(Row)`
  flex-grow: 1;
  margin: -16px;
  border-bottom: 1px solid ${props => props.theme.bluegrey};
  margin-bottom: 16px;
  border-radius:0;
`
const ColumnContainer = styled(Column)`
  padding: 1rem;
  border-left: 1px solid ${props => props.theme.bluegrey};
  width: ${props => props.width}px;
`
const WIDTH = 285 // width of panel components
class ChangeCluster extends Component {
  state = {
    results: {
      currentPop: 78000,
      structurePlanPop: 133000,
      popDensity: 2.9,
      newPop: 133000,
      newPatronage: 73000,
    },
  }

  setOriginCluster = id => this.props.setOriginCluster({ id })

  setTargetCluster = id => this.props.setTargetCluster({ id })

  render() {
    const { results } = this.state
    const {
      clusterType,
      clusters,
      originTargetCluster,
      indicatorRadial,
      stays,
      population,
      structurePlanPop,
      setStructurePlanPop,
      patronageNumber,
      futureStnPop,
      setFutureStnPop,
      originImage,
      targetImage,
      stnPopChange,
      clustersActive,
      toggleClustersActive
    } = this.props
    if (
      !clusterType ||
      !clusters ||
      !originTargetCluster ||
      !indicatorRadial ||
      !stays
    )
      return null

    const { origin, target } = originTargetCluster
    return (
      <Collapse
        bordered={false}
        defaultActiveKey={['1']}
        expandIcon={({ isActive }) => (
          <Icon style={{color:'#fff'}} type="caret-right" rotate={isActive ? 90 : 0} />
        )}
        activeKey={clustersActive}
        onChange={toggleClustersActive}
      >
        <Panel header={<CollapseHeader label={'Change Cluster'}/>} key="1" >
        <Column>
          <Container>
            <ClusterListSelection
              updateSelected={this.setOriginCluster}
              clusters={clusters}
              selected={origin}
              toggleClustersActive={toggleClustersActive}
            />
            <ColumnContainer width={WIDTH}>
              <ChangeClusterTarget
                clusterType={clusterType}
                origin={origin}
                target={target}
                setTargetCluster={this.setTargetCluster}
                width={WIDTH}
              />
              <NodePlaceChangeChart
                origin={origin}
                clusterType={clusterType}
                target={target}
                setTargetCluster={this.setTargetCluster}
                width={WIDTH}
              />
            </ColumnContainer>
            <ChangeClusterComparison
              origin={origin}
              target={target}
              width={WIDTH}
              indicatorRadial={indicatorRadial}
              stays={stays}
            />
            <ChangeClusterResults
              origin={origin}
              target={target}
              width={WIDTH}
              results={results}
              popChange={population}
              structurePlanPop={structurePlanPop}
              setStructurePlanPop={setStructurePlanPop}
              patronageNumber={patronageNumber}
              futureStnPop={futureStnPop}
              stnPopChange={stnPopChange}
              setFutureStnPop={setFutureStnPop}
              originImage={originImage}
              targetImage={targetImage}
            />
          </Container>
          <LGALevelSummaryRanges />
          </Column>
        </Panel>
      </Collapse>
    )
  }
}

export default ChangeCluster
