import React, { Component } from 'react'
import { XYPlot, YAxis, LineSeries, MarkSeries, LabelSeries } from 'react-vis'
import PropTypes from 'prop-types'

class LGARatioChart extends Component {
  constructor(props) {
    super(props)
    this.state = { current: {} }
  }
  render() {
    const { data, selectedHub } = this.props
    const { lgas, average } = data

    const thisHub = lgas.filter(item => item.key === selectedHub['LGA'])

    return (
      <XYPlot width={240} height={240} yDomain={[0, 1]} xDomain={[0, 26]}>
        <YAxis />
        <LineSeries
          style={{ strokeWidth: '1px' }}
          stroke={'#FF9505'}
          data={lgas}
          onNearestXY={(value, { event, innerX, innerY, index }) => {
            this.setState({ current: value })
          }}
        />

        <LineSeries
          style={{
            strokeWidth: '1px',
          }}
          stroke={'black'}
          data={average}
        />

        <MarkSeries data={thisHub} stack fill={'#F81F1F'} />
        <LabelSeries
          getX={d => d.x}
          getY={d => d.y}
          getLabel={d => d['name']}
          labelAnchorY={'middle'}
          labelAnchorX={'end'}
          data={[this.state.current]}
          style={{
            fontSize: 10,
          }}
          marginLeft={70}
          key={'label'}
        />
      </XYPlot>
    )
  }
}

LGARatioChart.propTypes = {
  data: PropTypes.object,
  selectedHub: PropTypes.object,
}
export default LGARatioChart
