import { ModelContainer } from '../constants/actionTypes'

const { ADD_MODEL, REMOVE_MODEL, UPDATE_MODEL, TOGGLE_SHOW } = ModelContainer

export const addModel = payload => ({
  type: ADD_MODEL,
  payload,
})
export const removeModel = payload => ({
  type: REMOVE_MODEL,
  payload,
})
export const updateModel = payload => ({
  type: UPDATE_MODEL,
  payload,
})
export const toggleShow = payload => ({
  type: TOGGLE_SHOW,
  payload,
})
