import React, { Component } from 'react'
import styled from '@emotion/styled'
import { Table, Button } from 'antd'
import Column from '../../../common/Column'

const Picker = styled(Table)`
  background-color: white;
  cursor: pointer;
  width: 100%;
  margin-bottom: 20px;
  
  & .ant-table-thead {
    font-size: 12px;
  }
`

export default class Multiplier extends Component {
  render() {
    const { columns, data, setCurrent } = this.props
    return (
      <Column>
        <Picker
          columns={columns}
          dataSource={data}
          size="small"
          height={285}
          rowKey={'sector'}
          pagination={false}
        />

        <Button onClick={setCurrent}>Back</Button>
      </Column>
    )
  }
}
