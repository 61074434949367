import React, { PureComponent } from 'react'
import styled from '@emotion/styled'
import { Icon, Select } from 'antd'

import Row from '../../../common/Row'

const Container = styled(Row)`
    margin-bottom:1rem;
    align-items: center;
    justify-content:space-between;
`
const HeavyTitle = styled('div')`
    font-weight:bold;
    font-size:16px;
    flex: 0 0 auto;
    width: 90px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
`
const ArrowIcon = styled(Icon)`
    margin: 0 0.75rem;
`

const Option = Select.Option

class ChangeClusterTarget extends PureComponent {
    
    onChange = e => this.props.setTargetCluster(e)

    render() {
        const { origin, clusterType, target } = this.props
        if(!clusterType) return null
        return (
            <Container>
                <HeavyTitle>{origin.name}</HeavyTitle>
                <ArrowIcon type="arrow-right" />
                <Select
                    value={target ? target.id : undefined}
                    placeholder={'Select target cluster'}
                    style={{ width:125}}
                    onChange={this.onChange}
                    >
                    {clusterType.data.map((f,i) => {
                        return (
                        <Option key={`${f.name}-${f.id}-${i}`} value={f.id}>
                            {f.name}
                        </Option>
                        )
                    })}
                </Select>
            </Container>
        )
    }
}


export default ChangeClusterTarget
