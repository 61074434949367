import { connect } from 'react-redux'
import PopulationGraph from '../components/ScenarioPlanner/PopulationForecast/PopulationGraph'

export default connect(
  state => {
    return {
      Avalues: state.population.low[state.scenarioplanner.lga],
      Bvalues: state.population.medium[state.scenarioplanner.lga],
      Cvalues: state.population.high[state.scenarioplanner.lga],
      Scenario: state.population.scenario
    }
  },
  {  }
)(PopulationGraph)
