const rootUrl = process.env.REACT_APP_API

export function queryActiveTransport(params) {
  const url = `${rootUrl}/activeTransport`
  return fetch(url, {
    method: 'post',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  })
}
