import { TravelSurvey } from '../constants/actionTypes'

const {
    GET_TRAVELSURVEY,
    GET_TRAVELSURVEY_SUCCESS,
    GET_TRAVELSURVEY_FAILURE,
} = TravelSurvey

export const initialState = {
    results: [],
    fetching: false,
    error: null,
}

export default function (state = initialState, { type, payload }) {
    switch (type) {

        case GET_TRAVELSURVEY: {
            return {
                ...state,
                fetching: true,
            }
        }

        case GET_TRAVELSURVEY_SUCCESS: {
            return {
                ...state,
                results: payload,
                fetching: false,
            }
        }

        case GET_TRAVELSURVEY_FAILURE: {
            return {
                ...state,
                fetching: false,
                error: payload,
            }
        }
        default:
            return state
    }
}
