import React from 'react'
import { Form, Select  } from 'antd'
import FormLabel from './FormLabel'

const Option = Select.Option

const FormSelect = ({width, data, getFieldDecorator, updateLabel, disabled }) => {
    const { options, initialValue, name, label, info } = data
    return (
        <Form.Item
            label={<FormLabel label={label} info={info} updateLabel={updateLabel} />}
        >
        {getFieldDecorator(name, {
            initialValue})(
            <Select 
                style={{ width: width }}
                disabled={disabled? disabled: false}
            >
                {options.map(f => {
                    return (
                    <Option key={f} value={f}>
                        {f}
                    </Option>
                    )
                })}
            </Select>
        )}
        </Form.Item>
    )
}
export default FormSelect