import React from 'react'
import styled from '@emotion/styled'
import * as isEqual from 'lodash.isequal'

import FormLabelResult from './FormLabelResult'

import Row from '../../common/Row'
import Column from '../../common/Column'
import Subtitle from '../../common/Subtitle'
import { numberWithCommas } from '../../utils/formatting'

import config from './config-results'

const COLUMN_WIDTH = 90

const Container = styled(Column)`
    padding:2rem;
    flex-grow:1;
    background-color:${props => props.theme.white};
    border-radius:3px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.19);
`
const HeaderContainer = styled(Row)`
    padding-bottom: 0.5rem;
    margin-bottom: 2rem;
    align-items:center;
`
const SubtitleContainer = styled(Subtitle)`
    display:flex;
    flex-grow: 1;
`
const HeaderItem = styled('div')`
    font-size:11px;
    font-weight:bold;
    margin: 0 0.25rem;
    width: ${ props => props.width}px;
`
const RowContainer = styled(Row)`
    padding: 0.5rem;
`
const LabelContainer = styled(Column)`
    font-size:11px;
    flex: 1 1 0;
`
const ResultsContainer = styled('div')`
    background-color: ${props => props.theme.lightgrey};
`
const SmallTitle = styled('div')`
    font-weight:600;
    font-size:12px;
    margin-bottom:0.5rem;
`
const ResultValue = styled('div')`
    width:${ props => props.width}px;
    font-size: 12px;
    font-weight: ${ props => props.weight};
`
const SummaryPanel = styled(Row)`
    padding: 0.75rem;
    margin-bottom: 1rem;
    border: solid 1px ${ props => props.theme.slightlydarkergrey};
`
const SummaryValue = styled(Subtitle)`
    flex-shrink: 0;
    color: ${ props => props.theme.topaz};
    width: ${ props => ((props.width*2)-5)}px;
`
const OtherPanel = styled(Row)`
    padding: 0.5rem 0.75rem;
`
const OtherValue = styled(Subtitle)`
    flex-shrink: 0;
    width: ${ props => ((props.width*2)-5)}px;
`
const check = (value, show) => {
    return show? value : ''
}

const ResultRow = ({label, data, show, info, handleInfo}) => {
    const { outcome, proportion } = data
    return (
        <RowContainer>
            { info && <FormLabelResult data={info} />}
            <LabelContainer>{label}</LabelContainer>
            <ResultValue weight={600} width={COLUMN_WIDTH}>${check((outcome/1000000).toFixed(2), show)}</ResultValue>
            <ResultValue weight={'normal'} width={COLUMN_WIDTH}>{check((Math.round(proportion * 100)), show)}%</ResultValue>
        </RowContainer>
    )
}
const Results = ({currentParams, submittedParams, results, handleInfo}) => {
    const show = isEqual(currentParams, submittedParams)
    return (
        <Container>
            <HeaderContainer>
                <SubtitleContainer>Results</SubtitleContainer>
                <HeaderItem width={COLUMN_WIDTH}>Outcome (discount at 7%)</HeaderItem>
                <HeaderItem width={COLUMN_WIDTH}>Proportion</HeaderItem>
            </HeaderContainer>
            {results && <Column>
                    <SmallTitle>Cost</SmallTitle>
                    <ResultsContainer>
                        <ResultRow show={show} data={results.costs.CAPEX} label={'Capital costs ($2018 m)'}/>
                        <ResultRow show={show} data={results.costs.OPEX} label={'Maintenance and rehabilitation costs ($2018 m)'}/>
                    </ResultsContainer>
                    <SummaryPanel>
                        <SubtitleContainer>Total costs ($2018 m)</SubtitleContainer>
                        <SummaryValue width={COLUMN_WIDTH}>${check((results.costs.total.outcome/1000000).toFixed(2), show)}</SummaryValue>
                    </SummaryPanel>
                    <SmallTitle>Benefit</SmallTitle>
                    <ResultsContainer>
                        {
                            Object.keys(config).map(key => {
                                const { label, name, info } = config[key]
                                const data = results.benefits[key]
                                return <ResultRow key={key} handleInfo={handleInfo} info={{label, info}} show={show} data={data} label={name}/>
                            })
                        } 
                    </ResultsContainer>
                    <SummaryPanel>
                        <SubtitleContainer>Total benefits ($2018 m)</SubtitleContainer>
                        <SummaryValue width={COLUMN_WIDTH}>${check((results.benefits.total/1000000).toFixed(2), show)}</SummaryValue>
                    </SummaryPanel>
                    <OtherPanel>
                        <SubtitleContainer>BCR</SubtitleContainer>
                        <OtherValue width={COLUMN_WIDTH}>{check((results.bcr).toFixed(2), show)}</OtherValue>
                    </OtherPanel>
                    <OtherPanel>
                        <SubtitleContainer>NPV ($2018 m)</SubtitleContainer>
                        <OtherValue width={COLUMN_WIDTH}>${check((results.npv/1000000).toFixed(2), show)}</OtherValue>
                    </OtherPanel>
                    <OtherPanel>
                        <SubtitleContainer>Changes in public transport patronage</SubtitleContainer>
                        <OtherValue width={COLUMN_WIDTH}>{numberWithCommas(check(Math.round(results.changesPatronage), show))}</OtherValue>
                    </OtherPanel>
                </Column>}
        </Container>
    )
}
export default Results