import { createSelector } from 'reselect'
import { scaleLog } from 'd3-scale'
import legendConfig from '../config/legend'

const { legendBehaviours } = legendConfig

const hubSelector = state => state.hubs.selectedHub
const dataSelector = state => state.behaviour.data

export const selectorActivitiesHub = createSelector(
  hubSelector,
  dataSelector,
  (hub, data) => {
    if (!hub || !data || data.filter(f => f.ID === hub.ID).length < 1)
      return null
    const filtered = data.filter(f => f.ID === hub.ID)[0]
    const activities = filtered.activities[0]
    return legendBehaviours.map((item, index) => {
      return {
        y: ((index + 1) * 180) / legendBehaviours.length,
        x: +activities[item.name],
        label: item.alias,
        ...item
      }
    })
  }
)
export const selectorActivitiesComparison = createSelector(
  hubSelector,
  dataSelector,
  (selectedHub, data) => {
    if (
      !selectedHub ||
      !data ||
      data.filter(f => f.ID === selectedHub.ID).length < 1
    ) return null
      
    let maxTotal = 0
    data.forEach(hub => {
      const activities = hub.activities[0]
      const total = legendBehaviours.reduce((sum, key) => sum + +activities[key.name], 0)
      if (total > maxTotal) {
        maxTotal = total
      }
    })
    let yScale = scaleLog()
      .domain([1000, maxTotal])
      .range([0, 200])
    const allHubs = data
      .map(hub => {
        const activities = hub.activities[0]
        const total = legendBehaviours.reduce((sum, key) => sum + +activities[key.name], 0)
        return {
          name: `${total} stays in ${hub.SmartRider_StopName}`,
          ID: hub.ID,
          y: yScale(total),
          x: hub.ID.toString(),
          color: hub.ID === selectedHub.ID ? '#222C3C' : '#FF9505',
          hubType: activities['HubClusterType'],
        }
      })
      .sort((a, b) => a.y - b.y)
    const highlightHub = allHubs.filter(f => f.ID === selectedHub.ID)
    return {
      allHubs,
      highlightHub,
      yScale,
    }
  }
)

export const selectorWindrose = createSelector(
  hubSelector,
  dataSelector,
  (hub, data) => {
    if (Object.keys(hub).includes('SmartRider_HubID') && data) {
      return {
        data: data.filter(item => item.SmartRider_HubID === hub.SmartRider_HubID)[0]
          .petals,
        hubId: hub.ID,
      }
    } else {
      return null
    }
  }
)
