import { createSelector } from 'reselect'

const searchtermSelector = state => state.search.searchterm
const selectedHubSelector = state => state.hubs.selectedHub

export const selectorSearchterm = createSelector(
  searchtermSelector,
  selectedHubSelector,
  (searchterm, selectedHub) => {
    return NoSearchTermNoSelectedHub(searchterm, selectedHub)
      ? searchterm
      : YesSearchTermNoSelectedHub(searchterm, selectedHub)
        ? searchterm
        : YesSelectedHubAndGetName(selectedHub)
  }
)

function NoSearchTermNoSelectedHub(searchterm, selectedHub) {
  return !searchterm && !selectedHub
}

function YesSearchTermNoSelectedHub(searchterm, selectedHub) {
  return searchterm && !selectedHub
}

function YesSelectedHubAndGetName(selectedHub) {
  return selectedHub.SmartRider_StopName
    ? selectedHub.SmartRider_StopName
    : selectedHub.NodePlace_Station
}
