import { LGALayer } from '../constants/actionTypes'

const {
  GET_LGA_SUCCESS,
  GET_LGA_FAILURE,
} = LGALayer

export const initialState = {
  geo: null,
  error: false,
}

export default function(state = initialState, { type, payload }) {
  switch (type) {
    case GET_LGA_SUCCESS: {
      return {
        ...state,
        geo: payload,
      }
    }
    case GET_LGA_FAILURE: {
      return {
        ...state,
        error: true,
      }
    }
    default:
      return state
  }
}
