import { LGALayer } from '../constants/actionTypes'
import { queryLGAs } from '../queries/lgalayer'
const {
  GET_LGA_SUCCESS,
  GET_LGA_FAILURE,
} = LGALayer

export const loadLGAs = () => {
  return function(dispatch) {
    queryLGAs()
      .then(res => res.json())
      .then(geo => dispatch(getLGASuccess(geo)))
      .catch(err => dispatch(getLGAFailure(err)))
  }
}
export const getLGASuccess = payload => ({
  type: GET_LGA_SUCCESS,
  payload,
})
export const getLGAFailure = payload => ({
  type: GET_LGA_FAILURE,
  payload,
})
