import React, { Component } from "react";
import { Auth } from "aws-amplify";
import { Redirect } from 'react-router-dom'

export default class Logout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isAuthenticated: this.props.isAuthenticated,
      userHasAuthenticated: this.props.userHasAuthenticated,
    };

    console.log("Logout: " + this.state.isAuthenticated)
  }


  componentWillMount() {
    console.log("Logout: " + this.state.isAuthenticated)
    // this.state.userHasAuthenticated(false);
    localStorage.setItem('isAuthenticated', 'false')
  }

  async componentDidUpdate() {

    await Auth.signOut();

    this.state.userHasAuthenticated(false);
    localStorage.setItem('isAuthenticated', 'false')
    window.location = "/login";
    this.props.history.push("/login");
  }

  render() {
    localStorage.setItem('isAuthenticated', 'false')

    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.state.userHasAuthenticated,
      handleLogout: this.state.handleLogout
    };

    return <Redirect to={"/login"} props={childProps}></Redirect>;
  }


}


