import React, { PureComponent } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { Radio } from 'antd'
import RadioButton from '../../common/RadioButton'
import Subtitle from '../../common/Subtitle'

const TravelDirectionContainer = styled('div')`
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
`
const RadioGroupContainer = styled(Radio.Group)`
  padding: 0.5rem 0;
  display: flex;
`

class TravelDirection extends PureComponent {
  onChange = e => this.props.change(e.target.value)

  render() {
    const { direction } = this.props
    return (
      <TravelDirectionContainer>
        <Subtitle>Travel direction</Subtitle>
        <RadioGroupContainer value={direction} onChange={this.onChange}>
          <RadioButton value="inbound">Inbound</RadioButton>
          <RadioButton value="outbound">Outbound</RadioButton>
        </RadioGroupContainer>
      </TravelDirectionContainer>
    )
  }
}

TravelDirection.propTypes = {
  change: PropTypes.func.isRequired,
  direction: PropTypes.string.isRequired,
}

export default TravelDirection
