import React, { PureComponent } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { Radio } from 'antd'
import RadioButton from '../../common/RadioButton'
import { HeaderTooltip } from '../ModelContainer/CommonComponents'

const ModePeriodContainer = styled('div')`
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
`
const RadioGroupContainer = styled(Radio.Group)`
  padding: 0.5rem 0;
  display: flex;
`

const ModePeriodPadding = styled('div')`
  padding: 0.5rem 0;
`

class ModePeriod extends PureComponent {
  onChange = e => this.props.change(e.target.value)

  onChangePeriod = e => this.props.changePeriod(e.target.value)

  render() {
    const { travel_mode, period } = this.props
    return (
      <ModePeriodContainer>
        <HeaderTooltip sub={true} heading={"Travel mode"}
          description={"PT: Public Transport; PV: Private Vehicle"} />
        <RadioGroupContainer value={travel_mode} onChange={this.onChange}>
          <RadioButton value="pt">PT</RadioButton>
          <RadioButton value="pv">PV</RadioButton>
        </RadioGroupContainer>
        <ModePeriodPadding />
        <HeaderTooltip sub={true} heading={"Travel period"}
          description={"AM: Morning Peak, PM: Afternoon Peak, OP: Off Peak \
          (between AM and PM), EV: Evening (after PM) "} />
        <RadioGroupContainer value={period} onChange={this.onChangePeriod}>
          <RadioButton value="am">AM</RadioButton>
          {travel_mode !== 'pt' && <RadioButton value="pm">PM</RadioButton>}
          {travel_mode !== 'pt' && <RadioButton value="op">OP</RadioButton>}
          {travel_mode !== 'pt' && <RadioButton value="ev">EV</RadioButton>}
        </RadioGroupContainer>
      </ModePeriodContainer>
    )
  }
}

ModePeriod.propTypes = {
  change: PropTypes.func.isRequired,
}

export default ModePeriod
