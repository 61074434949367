const rootUrl = process.env.REACT_APP_API

export function querySearch(search) {
  const url = `${rootUrl}/searchhubstops`
  const params = {
    search: search.toString(),
  }
  return fetch(url, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(params),
  })
}

export function queryHubStops({ search, withstops }) {
  const url = `${rootUrl}/searchhubstops`
  const params = {
    withstops,
    search: search.toString(),
  }
  return fetch(url, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(params),
  })
}
