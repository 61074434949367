import React from 'react'
import styled from '@emotion/styled'
import { Steps } from 'antd'

import MiniTitle from '../../../common/MiniTitle'
import Column from '../../../common/Column'
import DefaultButton from '../../../common/DefaultButton'
import PrimaryStepper from '../../../common/PrimaryStepper'

const Step = Steps.Step
const Container = styled(Column)`
  padding: 1rem;
  flex-shrink: 0;
  width: 165px;
`
const StepsContainer = styled(PrimaryStepper)`
  padding-top: 1rem;
`
const Item = styled(Step)`
  cursor: pointer;
  & .ant-steps-item-title { font-size: 12px; }
  & .ant-steps-item-icon {
    margin-right: 8px;
  }
`
const MiniTitleContainer = styled(MiniTitle)`
  padding-bottom: 0;
  padding-top: 7px;
`

const ClusterListSelection = props => {
  const selectCluster = id => props.updateSelected(id)
  const { clusters, selected, toggleClustersActive } = props
  const current = clusters.findIndex(i => i.NodePlace_HubID === selected.id)
  const getNode = i => clusters.filter((item, index) => index === i)
  return (
    <Container>
      <MiniTitleContainer>Choose your cluster</MiniTitleContainer>
      <StepsContainer direction="vertical" size="small" current={current}>
        {clusters &&
          clusters.map((f, i) => {
            return (
              <Item
                onClick={() => selectCluster(f.NodePlace_HubID)}
                key={f.NodePlace_Station}
                title={f.NodePlace_Station}
                weight={f.NodePlace_HubID === selected.id ? 'bold' : 'normal'}
                color={
                  f.NodePlace_HubID === selected.id ? '#FDB406' : '#E0E0E0'
                }
                
              />
            )
          })}
      </StepsContainer>
      <DefaultButton
        onClick={() => {
          if (current + 1 < clusters.length) {
            selectCluster(getNode(current + 1)[0].NodePlace_HubID)
          } else {
            toggleClustersActive()
          }
        }}
      >
        {current + 1 < clusters.length ? 'Next' : 'Done'}
      </DefaultButton>
    </Container>
  )
}
export default ClusterListSelection
