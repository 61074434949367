import React from 'react'
import { Form, InputNumber } from 'antd'
import FormLabel from './FormLabel'

const FormDecimalNumber = ({width, data, getFieldDecorator, updateLabel, disabled, step }) => {
  const { label, initialValue, name, info, formatter, parser } = data
  return (
    <Form.Item
      label={<FormLabel label={label} info={info} updateLabel={updateLabel} />}
    >
      {getFieldDecorator(name, {
        initialValue,
      })(
        <InputNumber
          style={{ width: width - 75 }}
          step={step? step: 0.01}
          disabled={disabled? disabled: false}
          formatter={formatter}
          parser={parser}
        />
      )}
    </Form.Item>
  )
}
export default FormDecimalNumber