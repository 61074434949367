import { connect } from 'react-redux'
import LGALevelResults from '../components/ScenarioPlanner/LGALevelResults'

export default connect(state => {
  const name = state.scenarioplanner.lga.replace(/ *\([^)]*\) */g, ""); 
  return {
    population:
      state.population.medium[state.scenarioplanner.lga] &&
      Object.values(state.scenarioplanner.futureStnPop).reduce(
        (prev, curr) => prev + parseFloat(curr),
        0
      ).toFixed(2),
    newPatronage: state.scenarioplanner.futureStnPatronage,
    employment: state.scenarioplanner.employmentResult,
    lga: name
  }
})(LGALevelResults)
