import React, { Component } from 'react'
import { extent, range } from 'd3-array'
import { scaleLinear, scaleQuantile } from 'd3-scale'
import { XYPlot, ArcSeries, LabelSeries } from 'react-vis'
import Heading from '../../common/Heading'
import ChartTitle from '../../common/ChartTitle'

const WIDTH = 300
const HEIGHT = 300

class HubsWindRose extends Component {
  constructor(props) {
    super(props)
    this.state = { data: { segments: [] } }
  }

  renderChart = () => {
    const { data } = this.props
    const segments = data.segments
    const petals = Object.keys(segments).map(key => segments[key])
    const colors = ['#FAD961', '#F76B1C']

    const colorScale = scaleQuantile()
      .domain(extent(petals, f => f.count))
      .range(colors)
    const gridScale = scaleLinear()
      .domain([0, 8])
      .range([10, WIDTH - 220])
    const gridScaleLabel = [
      { label: 'N', coords: [0, 5.2] },
      { label: 'E', coords: [5.2, 0] },
      { label: 'S', coords: [0, -5.2] },
      { label: 'W', coords: [-5.2, -0] },
    ]
    const angleScales = range(9).map(f => f * 45)
    const petalsColor = petals.map(petal => {
      return {
        ...petal,
        color: colorScale(petal.count),
      }
    })
    const gridlines = range(8).map(f => {
      return {
        label: f * 10000,
        radius: gridScale(f + 1),
        radius0: 0,
        angle0: 0,
        angle: (360 * Math.PI) / 180,
        color: 'transparent',
      }
    })
    const angleGridLines = angleScales.map((f, i) => {
      return {
        label: angleScales[i],
        angle: (angleScales[i + 1] * Math.PI) / 180,
        angle0: (angleScales[i] * Math.PI) / 180,
        radius: WIDTH - 220,
        radius0: 0,
        color: 'transparent',
      }
    })
    const dom = colorScale.domain(),
      l = (dom[1] - dom[0]) / colorScale.range().length,
      breaks = range(0, colorScale.range().length).map(function(i) {
        return (i + 1) * l
      })
    const legendBreaks = breaks
      .sort((a, b) => b - a)
      .map(f => {
        let returnValue = {}
        if (!isNaN(Math.ceil(f))) {
          returnValue = {
            title: Math.ceil(f),
            color: colorScale(Math.ceil(f)),
            strokeWidth: 2,
          }
        }
        return returnValue
      })
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <XYPlot
          xDomain={[-5, 5]}
          yDomain={[-5, 5]}
          width={WIDTH - 80}
          height={HEIGHT - 80}
        >
          <ArcSeries
            radiusType={'literal'}
            radiusDomain={[0, WIDTH - 220]}
            colorType={'literal'}
            stroke={'#E0E0E0'}
            data={gridlines}
          />
          <ArcSeries
            radiusType={'literal'}
            radiusDomain={[0, WIDTH - 220]}
            colorType={'literal'}
            stroke={'#E0E0E0'}
            data={angleGridLines}
          />
          <ArcSeries
            animation
            radiusType={'literal'}
            radiusDomain={[0, WIDTH - 220]}
            data={petalsColor}
            colorType={'literal'}
            stroke={'#333'}
          />
          <LabelSeries
            labelAnchorX={'middle'}
            labelAnchorY={'middle'}
            data={gridScaleLabel.map(f => {
              const { label, coords } = f
              return {
                label,
                x: coords[0],
                y: coords[1],
              }
            })}
          />
          <LabelSeries
            labelAnchorX={'middle'}
            labelAnchorY={'middle'}
            data={[
              {
                label: '80km',
                x: -4,
                y: 4,
                style: { fontSize: 10, color: 'lightgrey' },
              },
            ]}
          />
        </XYPlot>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginLeft: 10,
          }}
        >
          <div
            style={{
              background: 'linear-gradient(to top, #FAD961,#F76B1C)',
              height: 190,
              width: 10,
            }}
          />
          <div
            style={{
              height: 190,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            {legendBreaks.map((f, i) => {
              return (
                <div key={f.title}>
                  {(i === 0 || i === legendBreaks.length - 1) && (
                    <span style={{ fontSize: 10, marginLeft: 2 }}>
                      {Math.ceil(f.title)}
                    </span>
                  )}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  }
  render() {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Heading>Hub catchments</Heading>
        <ChartTitle>Travel catchment to hub</ChartTitle>
        <div
          style={{
            width: WIDTH - 100,
            height: HEIGHT - 100,
          }}
        >
          {this.renderChart()}
        </div>
      </div>
    )
  }
}

export default HubsWindRose
