import React, { Component } from 'react'
import {
  XYPlot,
  YAxis,
  LineSeries,
  MarkSeries,
  DiscreteColorLegend,
  LabelSeries,
} from 'react-vis'
import PropTypes from 'prop-types'

class SA2RatioChart extends Component {
  constructor(props) {
    super(props)
    this.state = { current: {} }
  }

  render() {
    const { data, selectedHub, hubs, lga } = this.props
    const { result } = data
    const { average } = lga
    const set = result

    // Monkey patching the SA2 ratio for perth to match LGA ratio
    const maxX = set[set.length - 1].x
    average[1].x = maxX

    let fHubs = hubs.filter(item => item.Accessibility_SA2.toString() ===
      this.state.current.sa2)
    let thisHub = set.filter(item => item.sa2 === 
      selectedHub['Accessibility_SA2'].toString())

    return (
      <XYPlot width={240} height={240} yDomain={[0, 1]}>
        <YAxis />
        <LineSeries
          style={{ strokeWidth: '1px' }}
          stroke={'#FF9505'}
          data={set}
          onNearestXY={(value, { event, innerX, innerY, index }) => {
            this.setState({ current: value })
          }}
        />
        <LineSeries
          style={{
            strokeWidth: '1px',
          }}
          stroke={'black'}
          data={average}
        />
        <DiscreteColorLegend
          height={50}
          width={200}
          items={[{ title: 'Perth metro ratio', fill: 'black' }]}
        />
        <MarkSeries data={thisHub} stack fill={'#F81F1F'} />
        {(Object.keys(this.state.current).length > 0) && (
          <LabelSeries
            getX={d => d.x}
            getY={d => d.y}
            getLabel={d => (fHubs[0] !== undefined) ? 
              (fHubs[0].SmartRider_StopName) : null}
            labelAnchorY={'middle'}
            labelAnchorX={'end'}
            data={[this.state.current]}
            style={{
              fontSize: 10,
            }}
            marginLeft={70}
            key={'label'}
          />
        )}
      </XYPlot>
    )
  }
}

SA2RatioChart.propTypes = {
  data: PropTypes.object,
  selectedHub: PropTypes.object,
}
export default SA2RatioChart
