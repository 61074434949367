import React from 'react'
import styled from '@emotion/styled'
import { Table } from 'antd'
import Row from '../../common/Row'
import Column from '../../common/Column'
import Subtitle from '../../common/Subtitle'

const Container = styled(Column)`
  padding:0 1rem;
`
const Picker = styled(Table)`
  background-color: white;
  cursor: pointer;
  width: 100%;
  margin: 0.25rem 0;

  & .ant-table-thead {
    font-size: 12px;
  }
`
const ColorWrapper = styled('div')`
  color: #149fd7;
  font-size: 25px;
  font-weight: normal;
`
const CenteredRow = styled(Row)`
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
`

const Employment = ({
  employmentCreated,
  baseEmployment,
  employmentResult,
}) => {
  let data = baseEmployment ? Object.values(baseEmployment) : []
  data = data.map(item => {
    item.new = item.employment + employmentCreated[item.sector]
    return item
  })
  return (
    <Container>
      <Subtitle>Employment scenario</Subtitle>
      <Picker
        columns={[
          {
            title: 'Sector',
            dataIndex: 'sector',
            key: 'sector',
          },
          {
            title: 'Current',
            dataIndex: 'employment',
            key: 'employment',
          },
          {
            title: 'Total Increase of Sector ($m)',
            dataIndex: 'new',
            key: 'new',
          },
          {
            title: 'Multiplier',
            dataIndex: 'multiplier',
            key: 'multiplier',
          },
        ]}
        dataSource={data}
        size="small"
        height={285}
        rowKey={'sector'}
        pagination={false}
      />

      <CenteredRow>
        <span>Total increase in output demand ($m)</span>
        <ColorWrapper>
          {employmentCreated && Object.values(employmentCreated).reduce(
            (prev, curr) => prev + curr,
            0
          )}
        </ColorWrapper>
      </CenteredRow>

      <CenteredRow>
        <span>Total increase in jobs created by total additional output</span>
        <ColorWrapper>
          {employmentResult && parseFloat(employmentResult).toFixed(0)}
        </ColorWrapper>
      </CenteredRow>
    </Container>
  )
}

export default Employment
