import { createSelector } from 'reselect'

const lgaSelector = state => state.lgalayer.geo

export const selectorLGA = createSelector(
  lgaSelector,
  lgaData =>
    lgaData &&
    lgaData.features.map(feature => ({
      name: feature.properties['LGA_NAME16'],
      code: feature.properties['LGA_CODE16'],
    }))
)
