import { ModelContainer } from '../constants/actionTypes'

const { ADD_MODEL, REMOVE_MODEL, UPDATE_MODEL, TOGGLE_SHOW } = ModelContainer

export const initialState = {
  allmodels: [
    'node-place analysis',
    'strategic employment',
    'travel behaviour',
    'accessibility',
    'travel survey analysis'
  ],
  models: ['node-place analysis'],
  show: true,
}

export default function(state = initialState, { type, payload }) {
  switch (type) {
    case ADD_MODEL: {
      const addMe = state.allmodels.filter(f => !state.models.includes(f))
      return {
        ...state,
        models: [...new Set([...state.models, addMe[0]])],
      }
    }
    case REMOVE_MODEL: {
      return {
        ...state,
        models: state.models.filter(f => f !== payload),
      }
    }
    case UPDATE_MODEL: {
      const { previous, next } = payload
      return {
        ...state,
        models: state.models.map(f => (f === previous ? next : f)),
      }
    }
    case TOGGLE_SHOW: {
      return {
        ...state,
        show: !state.show,
      }
    }
    default:
      return state
  }
}
