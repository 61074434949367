import { Population } from '../constants/actionTypes'

const {
  GET_POPULATION,
  GET_POPULATION_SUCCESS,
  GET_POPULATION_FAILURE
} = Population

export const initialState = {
  low: {},
  medium: {},
  high: {},
  scenario: {
    x: '2026',
    y: 300000,
  },
  fetching: false
}

export default function(state = initialState, { type, payload }) {
  switch (type) {

    case GET_POPULATION: {
      return {
        ...state,
        fetching: true,
      }
    }

    case GET_POPULATION_SUCCESS: {
      let low = {}
      let medium = {}
      let high = {}

      payload.forEach(item=>{
          let {Year, Low, Medium, High, LGA} = item
          Year = parseInt(Year, 10)
          Low = parseInt(Low,10)
          Medium = parseInt(Medium,10)
          High = parseInt(High,10)

          if(!Object.keys(low).includes(LGA)) {
              low[LGA] = [{x: Year, y: Low}]
              medium[LGA]= [{x: Year, y: Medium}]
              high[LGA]= [{x: Year, y: High}]
          }else{
            low[LGA].push({x: Year, y: Low})
            medium[LGA].push({x: Year, y: Medium})
            high[LGA].push({x: Year, y: High})
          }

      })
      
      return {
        ...state,
        low,
        medium,
        high,
        fetching: false,
      }
    }

    case GET_POPULATION_FAILURE: {
      return {
        ...state,
        fetching: false,
        error: payload,
      }
    }
    default:
      return state
  }
}




