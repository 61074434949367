import { createSelector } from 'reselect'

const hubsSelector = state => state.stops
const selectedHub = state => state.selectedHub

export const selectorStops = createSelector(
  hubsSelector,
  selectedHub,
  (hubs, selectedHub) => {
    if (
      !hubs ||
      !selectedHub ||
      hubs.length < 1 ||
      hubs.filter(f => f.ID === selectedHub.ID).length < 1
    )
      return []

    const foundHub = hubs.filter(f => f.ID === selectedHub.ID)
    if (foundHub.length < 1 || !foundHub[0].stops) return []
    const selectedStops = foundHub[0].stops

    return selectedStops.map(f => {
      // const coords = f.Coordinates.split(',')
      return {
        ...f,
        X: +f.X,
        Y: +f.Y,
      }
    })
  }
)
