export default {
    node: [
      { name: ["Workers (45min, Car)"], id: "Workers_45mins_car" },
      { name: ["Workers (45min, PT)"], id: "Workers_45mins_PT" },
      { name: ["Workers (45min, PnR)"], id: "Workers_45mins_PnR" },
      { name: ["Jobs (45min, Car)"], id: "Jobs_45mins_car" },
      { name: ["Jobs (45min, PT)"], id: "Jobs_45mins_PT" },
      { name: ["Jobs (45min, PnR)"], id: "Jobs_45mins_PnR" },
      { name: ["PnR Bays"], id: "No.ofPRbays2011STEM" },
      { name: ["Bus Services"], id: "No.ofbusServicesAMpeakhour2011STEM" }
    ],
    place: [
      { name: ['Pop Density'], id: 'Pop_den_1.6' },
      { name: ['IRSAD Percentile'], id: 'IRSAD_perc1.6' },
      { name: ['Walk Score'], id: 'walk_score_1.6_21Jan19' },
      { name: ['Urban Space'], id: 'urban_space_1.6_21Jan19' },
      { name: ['Entropy LU Mix'], id: 'Entropy_LU2018_1.6' },
      { name: ['Bike Route (km)'], id: 'bikeroute_total_1.6_21Jan19' }
    ],
  }