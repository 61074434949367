import React, { PureComponent } from 'react'
import styled from '@emotion/styled'
import {
  DiscreteColorLegend,
  HorizontalGridLines,
  LineSeries,
  VerticalGridLines,
  XAxis,
  XYPlot,
  YAxis,
} from 'react-vis'
import '../../../../node_modules/react-vis/dist/style.css'

import Row from '../../../common/Row'

const Container = styled('div')`
  display:flex;
  flex-direction: ${props => props.direction};
`
const Legend = styled(Row)`
  justify-content: center;
`
const series = [
    { name: 'Avalues', title: 'Low', strokeWidth: 2, color: '#7eb764' },
    { name: 'Bvalues', title: 'Medium', strokeWidth: 2, color: '#f4e5cb' },
    { name: 'Cvalues', title: 'High', strokeWidth: 2, color: '#ef6c4b' },
]
  
class PopulationGraph extends PureComponent {

    render() {
        const { Avalues, Bvalues, Cvalues, orientation, width, height, margin, direction, legendWidth } = this.props
        if (!Cvalues) return null
      
        let verticalTicks = Cvalues.map(item => item.y)
        const horizontalTicks =Cvalues.map(item => item.x)
        const min = (arr1, arr2, arr3) => {
          let min = 1000000000
      
          arr1.forEach(item => {
            if (item.y < min) {
              min = item.y
            }
          })
          arr2.forEach(item => {
            if (item.y < min) {
              min = item.y
            }
          })
          arr3.forEach(item => {
            if (item.y < min) {
              min = item.y
            }
          })
      
          return min
        }
      
        const Minimum = min(Avalues, Bvalues, Cvalues)
        return (
            <Container direction={direction}>
                <XYPlot
                    width={width}
                    height={height}
                    {...{
                        xDomain: ['2016', '2031'],
                        yDomain: [Minimum, Cvalues[Cvalues.length - 1].y],
                    }}
                    margin={margin}
                    >
                    {!verticalTicks || verticalTicks.length ? (
                        <VerticalGridLines tickValues={verticalTicks}  />
                    ) : null}
                    {!horizontalTicks || horizontalTicks.length ? (
                        <HorizontalGridLines tickValues={horizontalTicks} />
                    ) : null}
                    <XAxis on0={false} tickFormat={v => v} tickValues={horizontalTicks} />
                    <YAxis on0={false} tickTotal={4} tickValues={verticalTicks}/>
                    {series.map(f => (
                        <LineSeries key={f.name} data={this.props[f.name]} color={f.color} />
                    ))}
                </XYPlot>
                <Legend>
                    <DiscreteColorLegend
                        orientation={orientation}
                        width={legendWidth}
                        items={series}
                    />
                </Legend>
            </Container>    
        )
    }
}

export default PopulationGraph