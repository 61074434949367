import React, { Component } from 'react'
import styled from '@emotion/styled'
import { XYPlot, VerticalBarSeries, LabelSeries, YAxis } from 'react-vis'
import PropTypes from 'prop-types'

import { HeaderTooltip } from '../ModelContainer/CommonComponents'

const ComparisonChartContainer = styled('div')`
  padding: 1rem 0;
`
const ChartTextContainer = styled('div')`
  padding: 0.75rem 0;
  text-align: center;
  font-size: 0.75rem;
`
const ScoreContainer = styled('div')`
  padding-top: 1rem;
`
const ScoreValue = styled('span')`
  font-size: 12px;
  color: #ff9505;
`

class ComparisonChart extends Component {
  render() {
    const { metric, time, direction, data, metric_total } = this.props
    const { allHubs, highlightHub } = data
    const { score, score_num } = highlightHub[0]
    const scoreValue = ((parseFloat(score_num) / parseFloat(metric_total)) * 
      100).toFixed(0)
    return (
      <ComparisonChartContainer>
        <HeaderTooltip heading={"Accessiblity comparison"} 
          description={"Total number of jobs accessible within the selected \
          time. The graph below shows a comparitive analysis of the selected \
          station with all stations in Greater Perth. "} />
        <ChartTextContainer>
          {<ScoreValue>{score}</ScoreValue>} {metric} accessible within {time}{' '}
          minutes travelling {direction}.
        </ChartTextContainer>
        <XYPlot
          animation
          margin={{ left: 30, right: 2, top: 2, bottom: 10 }}
          width={230}
          height={180}
          xType={'ordinal'}
          yType={'linear'}
        >
          <VerticalBarSeries
            fill={'#FF9505'}
            stroke={'#FF9505'}
            data={allHubs}
          />
          <VerticalBarSeries
            fill={'#F76B1C'}
            stroke={'#F76B1C'}
            data={highlightHub}
            barWidth={2}
          />
          <LabelSeries
            style={{ fontSize: 10 }}
            getLabel={d => `${d.name}`}
            data={highlightHub}
          />
          <YAxis
            tickSize={1}
            left={0}
            bottom={0}
            position={'start'}
            top={5}
            tickPadding={2}
            tickTotal={5}
            tickFormat={v => `${Math.round(v / 1000)}k`}
            style={{
              text: { stroke: 'none', fill: '#666666', fontSize: '10px' },
            }}
          />
        </XYPlot>
        <ScoreContainer>
          <HeaderTooltip sub={true} heading={"Accessibility score"}
            description={"The percentage of all metropolitan Perth jobs \
            accessible within the time selected."} />
            <ScoreValue>
              { scoreValue.toString() + '% of ' + metric + 
              ' in Perth Metro Accessible' }
            </ScoreValue>
        </ScoreContainer>
      </ComparisonChartContainer>
    )
  }
}

ComparisonChart.propTypes = {
  direction: PropTypes.string,
  time: PropTypes.number,
  metric: PropTypes.string,
}

export default ComparisonChart
