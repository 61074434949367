import clusterColors from './clusters'
export default {
    legendPatronage: {
        '1k': { size: 4 * 2 },
        '5k': { size: 8 * 2 },
        '20k': { size: 10 * 2 },
        '>100k': { size: 15 * 2 },
    },
    legendNodePlace: {
        '1': { short: 'Dormitory Hub', long: 'Dormitory Hub; low place score and low-middle node score; primarily transit node.', color: clusterColors["1"] },
        '2': { short: 'Suburban Hub', long: 'Suburban Hub; middle place score and middle node score; balance of Hub usages.', color: clusterColors["2"] },
        '3': { short: 'Inner City Hub', long: 'Inner City Hub; high place score and high node score; potential for stress.', color: clusterColors["3"] }
    },
    legendTreemap: {
        'Commercial2018_1.6_21Jan19': { shortName: "Commercial", color: "#8dd3c7" },
        'Education2018_1.6_21Jan19': { shortName: "Education", color: "#ffffb3" },
        'Health2018_1.6_21Jan19': { shortName: "Healthcare", color: "#bebada" },
        'Manufacturingindustry2018_1.6_21Jan19': { shortName: "Manufacturing Industry", color: "#fb8072" },
        'Office2018_1.6_21Jan19': { shortName: "Office", color: "#80b1d3" },
        'Recreation2018_1.6_21Jan19': { shortName: "Recreation", color: "#adb462" },
        'Residential_1.6_21Jan19': { shortName: "Residential", color: "#b3de69" },
        'Retailoutlets2018_1.6_21Jan19': { shortName: "Retail Outlets", color: "#fccde5" },
        'heritage1.6_21Jan19': { shortName: "Heritage", color: "#d9d9d9" }
    },
    legendBehaviours: [
        { name: 'WorkActivity', alias: 'Work', color: '#a5cfe5'},
        { name: 'SchoolDayActivity', alias: 'School', color: '#2677b6'},
        { name: 'SleepActivity', alias: 'Sleep', color: '#f79c99'},
        { name: 'ShortActivity', alias: 'Short', color: '#abe086'},
        { name: 'MediumActivity', alias: 'Medium', color: '#329e31'},
        { name: 'Transfer', alias: 'Transfer', color: '#d29331'},
        { name: 'arriveOpen', alias: 'Arrive only', color: '#f2d931'},
        { name: 'departOpen', alias: 'Depart only', color: '#a2a313'},
    ]
}