import { Loading } from '../constants/actionTypes'

const { SET_LOADING } = Loading

export const initialState = false

export default function(state = initialState, { type, payload }) {
  switch (type) {
    case SET_LOADING: {
      return payload
    }
    default:
      return state
  }
}
