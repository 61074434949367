import { Map } from '../constants/actionTypes'

const { UPDATE_VIEWPORT, UPDATE_CENTER, TOGGLE_LAYER, SET_MAP } = Map

export const updateViewport = payload => ({
  type: UPDATE_VIEWPORT,
  payload,
})

export const updateCenter = payload => ({
  type: UPDATE_CENTER,
  payload,
})

export const toggleLayer = () => ({
  type: TOGGLE_LAYER,
})

export const chooseMap = payload => ({
  type: SET_MAP,
  payload
})

export const setMap = payload => {
  return dispatch => {
    dispatch(chooseMap(payload))
  }
}