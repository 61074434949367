import {
  connect
} from 'react-redux'
import ChangeCluster from '../components/ScenarioPlanner/ChangeCluster'
import {
  selectorClusterType
} from '../selectors/nodeplace-selector'
import {
  selectorClusters,
  selectorOriginTargetCluster,
  selectorIndicator,
  selectorActivitiesComparison,
  selectorPopulation,
} from '../selectors/scenario-clusterchange-selector'
import {
  setOriginCluster,
  setTargetCluster,
  setOccupancyRate,setStructurePlanPop,
  setFutureStnPop,
  toggleClustersActive
} from '../actions/scenarioplanner'


export default connect(
  state => {
    return {
      clusterType: selectorClusterType(state),
      clusters: selectorClusters(state),
      originTargetCluster: selectorOriginTargetCluster(state),
      indicatorRadial: selectorIndicator(state),
      stays: selectorActivitiesComparison(state),
      population: selectorPopulation(state).popChange,
      structurePlanPop: selectorPopulation(state).structurePlanPop,
      occupancyRate: state.scenarioplanner.occupancyRate,
      housingDensity: selectorPopulation(state).housingDensity,
      patronageNumber: selectorPopulation(state).patronageNumber,
      futureStnPop: selectorPopulation(state).futureStnPop,
      originImage: selectorPopulation(state).originImage,
      targetImage: selectorPopulation(state).targetImage,
      stnPopChange: selectorPopulation(state).stnPopChange,
      clustersActive: state.scenarioplanner.clustersActive
    }
  }, {
    setOriginCluster,
    setTargetCluster,
    setStructurePlanPop,
    setOccupancyRate, 
    setFutureStnPop,
    toggleClustersActive
  }
)(ChangeCluster)