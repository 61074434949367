import React, { Component } from 'react'
import styled from '@emotion/styled'
import { Modal, Button } from 'antd';
import { Analytics } from 'aws-amplify';

import SwitchModel from './SwitchModel'
import ActiveTransport from '../../containers/ActiveTransport'
import YourMove from '../../containers/YourMove'

import Column from '../../common/Column'
import { Redirect } from 'react-router-dom'

const OuterContainer = styled('div')`
  height: calc(100% - 59px);
  width: 100%;
  overflow: auto;
  position: absolute;
  top: 59px;
  left: 0;
  margin: 0;
  height: calc(100% - 59px);
  background-color: ${props => props.theme.bluegrey};
`
const Container = styled(Column)`
  margin: auto;
  width: 1200px;
  padding: 2rem 0;
`

class CostBenefitAnalysis extends Component {
  state = {
    models: ['Active Travel', 'Your Move'],
    selected: 'Active Travel',
    isAuthenticated: false,
    alertDisplayed: true,
  }

  updateSelected = selected => {
    this.setState({ selected });
    // Log Analytics Event
    Analytics.record({ name: "CostBenefit-SelectCBA", attributes: {
        selected: selected }});
  }

  componentWillMount() {
    this.setState({isAuthenticated: localStorage.getItem("isAuthenticated") 
      === "true"});
  }

  render() {
    const { models, selected, alertDisplayed } = this.state

   if (this.state.isAuthenticated) {
     return (
      <>
        <Modal visible={alertDisplayed} 
          title={"Cost-Benefit Analysis"}
          width={900} iconType={"exclamation-circle"} 
          footer={[null, <Button key="submit" type="danger" 
          onClick={() => this.setState({alertDisplayed: false})}>
          I Understand</Button>]} closable={false} >
          <p>The rapid appraisal models are designed to estimate 
          indicative Cost-Benefit Analysis (CBA) results by varying a 
          number of analysis inputs. The models do not account for the 
          interdependencies of the inputs. Additionally, the analysis is 
          based on general assumptions, which may not be appropriate to 
          be used for some projects. More project-specific information is 
          required for more accurate results.</p>
          <p>The models should only be used by professionals with 
          experience in CBA and sufficient knowledge about the project to 
          conduct the analysis. For example, they need to know that when the 
          length of the initiative is changed, the size of population impacted, 
          CAPEX and OPEX, travel time improvements and time taken for 
          construction assumptions need to be reviewed.</p>
          <p>The models shown should only be used as a preliminary analysis 
          tool to define options based on indicative results and are not 
          suitable to be used as an investment decision making tool. 
          PATREC is not responsible for any decisions made on the basis of 
          the results of these models.</p>
        </Modal>
        <OuterContainer>
          <Container>
            <SwitchModel
              models={models}
              selected={selected}
              update={this.updateSelected} />
            {selected === 'Active Travel' && <ActiveTransport/>}
            {selected === 'Your Move' && <YourMove/>}
          </Container>
        </OuterContainer>
      </>
     )
   } else {
     return <Redirect to={"/login"}></Redirect>;
   }
  }
}

export default CostBenefitAnalysis
