import React, { PureComponent } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { XYPlot, VerticalBarSeries, LabelSeries, YAxis } from 'react-vis'

import ChartTitle from '../../common/ChartTitle'
import { HeaderTooltip } from '../ModelContainer/CommonComponents'

const Container = styled('div')`
  padding: 0.5rem 0;
`

class ActivitiesCompareChart extends PureComponent {
  render() {
    const { data } = this.props
    const { allHubs, highlightHub, yScale } = data
    const tickValues = [
      42.02098679790254,
      60.1184407588707,
      102.13942755677323,
      120.2368815177414,
      162.25786831564392,
      198.45277623758022,
    ]
    return (
      <Container>
        <HeaderTooltip heading={"Stay activities comparison"} 
          description={"A stay is a categorisation of the length of \
          time between a tag-off and a tag-on."} />
        <ChartTitle>Number of stays for all stays</ChartTitle>
        <XYPlot
          animation
          margin={{ left: 30, right: 2, top: 2, bottom: 2 }}
          width={250}
          height={180}
          xType={'ordinal'}
          yType={'linear'}
        >
          <VerticalBarSeries
            fill={'#FF9505'}
            stroke={'#FF9505'}
            data={allHubs}
          />
          <VerticalBarSeries
            fill={'#F76B1C'}
            stroke={'#F76B1C'}
            data={highlightHub}
            barWidth={2}
          />

          <YAxis
            tickSize={1}
            position={'start'}
            bottom={0}
            top={5}
            tickPadding={2}
            tickValues={tickValues}
            tickFormat={v => `${Math.round(yScale.invert(v) / 1000)}k`}
            style={{
              text: { stroke: 'none', fill: '#666666', fontSize: '10px' },
            }}
          />
          <LabelSeries
            style={{ fontSize: 10 }}
            getLabel={d => `${d.name}`}
            data={highlightHub}
            marginTop={-5}
          />
        </XYPlot>
      </Container>
    )
  }
}

ActivitiesCompareChart.propTypes = {
  data: PropTypes.object,
}

export default ActivitiesCompareChart
