const rootUrl = process.env.REACT_APP_API

export function queryNodeplace() {
  const url = `${rootUrl}/callmodel`
  const params = {
    results_param: 'accessResults',
    model: 'nodePlace',
    req_params: {
      format: 'json',
    },
  }

  return fetch(url, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(params),
  })
}

export function queryClusters() {
  const url = `${rootUrl}/callmodel`
  const params = {
    results_param: 'accessResults',
    model: 'nodePlace',
    req_params: {
      format: 'json',
      clusters: true,
    },
  }

  return fetch(url, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(params),
  })
}
