import { createSelector } from 'reselect'

const metricSelector = state => state.accessibility.metric
const metricStagingSelector = state => state.accessibility.stagingMetric
const directionSelector = state => state.accessibility.direction
const directionStagingSelector = state => state.accessibility.stagingDirection
const timeSelector = state => state.accessibility.time
const timeStagingSelector = state => state.accessibility.stagingTime
const comparisonDataSelector = state => state.accessibility.comparisonData
const selectedHubSelector = state => state.hubs.selectedHub
const allHubsSelector = state => state.hubs.data
const ttSelectedHubSelector = state => state.accessibility.ttSelectedHub
const accessSelector = state => state.accessibility.accessData

const modeStagingSelector = state => state.accessibility.stagingMode
const periodStagingSelector = state => state.accessibility.stagingPeriod
const modeSelector = state => state.accessibility.travel_mode
const periodSelector = state => state.accessibility.period
const modelNameSelector = state => state.sa2layer.modelName

export const selectorComparisonData = createSelector(
  comparisonDataSelector,
  selectedHubSelector,
  allHubsSelector,
  (data, selectedHub, hubs) => {
    if (!data || !selectedHub) return null
    const allHubs = hubs
      .map(hub => {
        const { Accessibility_SA2 } = hub
        const score = data[Accessibility_SA2]
        const name = hub.SmartRider_StopName
          ? hub.SmartRider_StopName
          : hub.NodePlace_Station
        return {
          name,
          x: hub.ID.toString(),
          y: score ? score : 0,
          zone: Accessibility_SA2,
          score: score
            ? score.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            : 0,
          score_num: score,
          ID: hub.ID,
        }
      })
      .sort((a, b) => a.y - b.y)
    const highlightHub = allHubs.filter(f => f.ID === selectedHub.ID)
    return {
      allHubs,
      highlightHub,
    }
  }
)

export const selectorAccessData = createSelector(accessSelector, access => {
  if (access) {
    return access.metric_total
  }
})

export const selectorShowRunModel = createSelector(
  metricSelector,
  metricStagingSelector,
  directionSelector,
  directionStagingSelector,
  timeSelector,
  timeStagingSelector,
  selectedHubSelector,
  ttSelectedHubSelector,
  modeSelector,
  modeStagingSelector,
  periodSelector,
  periodStagingSelector,
  modelNameSelector,
  (
    metric,
    stagingMetric,
    direction,
    stagingDirection,
    time,
    stagingTime,
    selectedHub,
    ttSelectedHub,
    travel_mode,
    stagingMode,
    period,
    stagingPeriod,
    modelName
  ) => {
    const current = {
      metric,
      direction,
      time,
      travel_mode,
      period,
    }
    const staging = {
      metric: stagingMetric,
      direction: stagingDirection,
      time: stagingTime,
      travel_mode: stagingMode,
      period: stagingPeriod,
    }
    const parameterCheck = areEqualShallow(current, staging)
    return (
      !parameterCheck ||
      selectedHub !== ttSelectedHub ||
      modelName !== 'traveltime'
    )
  }
)

function areEqualShallow(a, b) {
  for (var key in a) {
    if (a[key] !== b[key]) {
      return false
    }
  }
  return true
}
