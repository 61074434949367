import React from 'react'
import { Treemap } from 'react-vis'
import styled from '@emotion/styled'
import DiscreteColorLegend from '../../common/DiscreteColorLegend'
import {HeaderTooltip} from '../ModelContainer/CommonComponents'

const MyTreemap = styled(Treemap)`
  &.nested-tree .rv-treemap__leaf .rv-treemap__leaf__content {
    padding: 0px;
  }
`

const TreemapPadding = styled('div')`
  padding: 1rem 0;
`
const SubHeader = styled('p')`
  color: #333333;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  padding-top: 0.5rem;
`

export default ({ landUse }) => {
  if (Object.keys(landUse).length > 0) {
    return (
      <>
        <TreemapPadding />
        <HeaderTooltip heading={"Land use diversity index"}
          description={"The land use diversity is based on the abosulte number \
          of properties by type, expressed as a percentage of all land uses in \
          the catchment. "} />
        <SubHeader>Landuse breakdown for hub</SubHeader>
        <MyTreemap
          {...{
            animation: true,
            className: 'nested-tree',
            colorType: 'literal',
            data: landUse,
            mode: 'resquarify',
            renderMode: 'Canvas',
            height: 240,
            width: 240,
            margin: 2,
            getSize: d => d.value,
            getColor: d => d.hex,
            getLabel: d => null,
            style: {
              border: '#000000',
              color: '#000000',
              fontSize: 10,
              padding: 1,
            },
          }}
        />
        <DiscreteColorLegend items={landUse.children} />
      </>
    )
  } else {
    return null
  }
}
