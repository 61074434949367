import { connect } from 'react-redux'
import SA2Layer from '../components/Layers/SA2Layer'

import {
  selectorShowSA2Layer,
  selectorCombineLayer,
} from '../selectors/sa2Layer-selector'

export default connect(
  state => {
    return {
      data: selectorCombineLayer(state),
      showLayer: selectorShowSA2Layer(state),
    }
  },
  {}
)(SA2Layer)
