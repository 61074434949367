import React from 'react'
import styled from '@emotion/styled'
import MiniTitle from '../../common/MiniTitle'
import { Select } from 'antd'
const Option = Select.Option

const Container = styled('div')`
  padding-bottom: 1rem;
`
const Sectors = ({ onChange, options, selected }) => {
  const change = value => onChange({ key: 'selectedSector', value })
  return (
    <Container>
      <MiniTitle>Sectors</MiniTitle>
      <Select
        style={{ width: 275 }}
        onChange={change}
        placeholder={'Select a sector'}
        value={selected ? selected : undefined}
      >
        {options.map(f => (
          <Option key={f} value={f}>
            {f}
          </Option>
        ))}
      </Select>
    </Container>
  )
}

export default Sectors
