import { connect } from 'react-redux'
import AboutPage from '../components/AboutPage'
import { toggleAbout } from '../actions/about'
export default connect(
  state => {
    return {
      show: state.about,
    }
  },
  { toggleAbout }
)(AboutPage)
