import React, { PureComponent } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { Radio } from 'antd'
import RadioButton from '../../common/RadioButton'
import { HeaderTooltip } from '../ModelContainer/CommonComponents'

const MetricContainer = styled('div')`
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
`
const RadioGroupContainer = styled(Radio.Group)`
  padding: 0.5rem 0;
  display: flex;
`
const metrics = ['Jobs', 'Population', 'Dwellings']
class Metric extends PureComponent {
  onChange = e => this.props.change(e.target.value)

  render() {
    const { metric } = this.props
    return (
      <MetricContainer>
        <HeaderTooltip sub={true} heading={"Accessibility metric"} 
          description={"Areas are ranked by their accessibility to the most \
          jobs, people or houses. "} />
        <RadioGroupContainer value={metric} onChange={this.onChange}>
          {metrics.map(f => (
            <RadioButton key={f} value={f}>
              {f}
            </RadioButton>
          ))}
        </RadioGroupContainer>
      </MetricContainer>
    )
  }
}

Metric.propTypes = {
  change: PropTypes.func.isRequired,
  metric: PropTypes.string.isRequired,
}

export default Metric
