import { connect } from 'react-redux'
import ActiveTransport from '../components/ActiveTransport'
import { setActiveTravelParams } from '../actions/costbenefitanalysis'
export default connect(
  state => {
    return {
      currentParams: state.costbenefitanalysis.activeTravel   
    }
  },
  { setActiveTravelParams }
)(ActiveTransport)
