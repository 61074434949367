import React, { Component } from 'react'
import styled from '@emotion/styled'
import { Button, Icon, Select, Radio } from 'antd'
import { Analytics } from 'aws-amplify'

import MiniTitle from '../../common/MiniTitle'
import SecondaryRadio from '../../common/SecondaryRadio'

const Option = Select.Option
const OptGroup = Select.OptGroup
const RadioGroup = Radio.Group

const Container = styled('div')`
  padding: 1rem;
`
const LegendMiniTitle = styled('div')`
  font-size: 0.75rem;
  padding-top: 0.75rem;
`
const AcademicContainer = styled('div')`
  margin: 0.5rem 0rem 0.5rem 0rem;
  width: 95%;
`

export default class ContextLayers extends Component {

  async chooseAttr(value) {
    // Log Analytics Event
    Analytics.record({ name: "ContextLayers-SelectAttr", attributes: {
        value: value }})
    const split_key = this.props.selectedLayer.split(';')
    await this.props.loadAttribute(split_key[0])
    await this.props.loadGeometry(split_key[1])
    await this.props.selectAttribute(value.target)
  }

  async onChange(value) {
    // Log Analytics Event
    Analytics.record({ name: "ContextLayers-SelectLayer", attributes: {
        value: value }})
    const split_key = value.split(';')
    await this.props.selectLayer(value)
    await this.props.loadAttribute(split_key[0]) 
    await this.props.loadGeometry(split_key[1]) 
  }

  render() {
    const {
      contextLayers,
      selectedLayer,
      dataLayer,
      selectedCrossOne,
      selectedCrossTwoA,
      selectedCrossTwoB,
    } = this.props
    const contextGroups = contextLayers
      ? [...new Set(contextLayers.map(item => item.category))]
      : false

    return (
      <Container>
        <MiniTitle>Contextual Layers</MiniTitle>
        <Select
          style={{ width: 275 }}
          onChange={this.onChange.bind(this)}
          placeholder={'Select a context layer'}
          value={selectedLayer ? selectedLayer : undefined}
        >
          <OptGroup key={'Turn off Layers'} label={'Turn off Layers'}>
            <Option key={'non;non'} value={'non;non'}>
              None
            </Option>
          </OptGroup>
          {contextGroups &&
            contextGroups.map(
              f =>
                f !== '' && (
                  <OptGroup key={f} label={f}>
                    {contextLayers.map(
                      g =>
                        g !== '' &&
                        g.category === f && 
                        g.type !== 'time' && (
                          <Option
                            key={g.attribute + ';' + g.geometry}
                            value={g.attribute + ';' + g.geometry}
                          >
                            {g.desc}
                          </Option>
                        )
                    )}
                  </OptGroup>
                )
            )}
        </Select>
        {dataLayer &&
          dataLayer.type === 'CrossOne' && (
            <>
              <LegendMiniTitle>Choose an Attribute</LegendMiniTitle>
              <RadioGroup
                name={'CrossOne'}
                onChange={this.chooseAttr.bind(this)}
                value={selectedCrossOne}
              >
                {Object.keys(dataLayer.fields).map(f => (
                  <SecondaryRadio key={f} value={f}>
                    {dataLayer.fields[f]}
                  </SecondaryRadio>
                ))}
              </RadioGroup>
            </>
          )}
        {dataLayer &&
          dataLayer.type === 'CrossTwo' && (
            <>
              <LegendMiniTitle>Choose an Attribute</LegendMiniTitle>
              <RadioGroup
                name={'CrossTwoA'}
                onChange={this.chooseAttr.bind(this)}
                value={selectedCrossTwoA}
              >
                {Object.keys(dataLayer.fields[0]).map(f => (
                  <SecondaryRadio key={f} value={f}>
                    {dataLayer.fields[0][f]}
                  </SecondaryRadio>
                ))}
              </RadioGroup>
              <LegendMiniTitle>Choose an Attribute</LegendMiniTitle>
              <RadioGroup
                name={'CrossTwoB'}
                onChange={this.chooseAttr.bind(this)}
                value={selectedCrossTwoB}
              >
                {Object.keys(dataLayer.fields[1]).map(f => (
                  <SecondaryRadio key={f} value={f}>
                    {dataLayer.fields[1][f]}
                  </SecondaryRadio>
                ))}
              </RadioGroup>
            </>
          )}
        <AcademicContainer>
          <Button block style={{ fontSize: '0.75rem' }}
            href={"https://resources.patrec.org/reports/railsmart/gis.pdf"}>
            <Icon style={{ verticalAlign: '0.125em' }} type="file-text" />
            Download Academic Paper
          </Button>
        </AcademicContainer>
      </Container>
    )
  }
}
