import React, { PureComponent } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { XYPlot, HorizontalBarSeries, LabelSeries } from 'react-vis'

import ChartTitle from '../../common/ChartTitle'
import { HeaderTooltip } from '../ModelContainer/CommonComponents'

const Container = styled('div')`
  padding: 0;
`

class HubActivitiesChart extends PureComponent {
  render() {
    const { data } = this.props
    return (
      <Container>
        <HeaderTooltip  key={'activities-heading'} 
          heading={"Stay activities"}
          description={"Data is a month from Aug 2017."} />
        <ChartTitle key={'activities-title'}>
          Number of stays per stay type
        </ChartTitle>
        <XYPlot
          animation
          margin={{ left: 80, right: 50, top: 2, bottom: 2 }}
          width={250}
          height={220}
          yType="literal"
          key={'activities-chart'}
          colorType="category"
          colorDomain={data.map(f=>f.alias)}
          colorRange={data.map(f=>f.color)}
        >
          <HorizontalBarSeries
            barWidth={0.8}
            margin={{ left: 20 }}
            stroke={'#fff'}
            data={data}
            key={'bar'}
            getY={d => d.y}
            getColor={d => d.color}
          />
          <LabelSeries
            getX={d => -10}
            getY={d => d.y}
            getLabel={d => d.label}
            labelAnchorY={'middle'}
            labelAnchorX={'end'}
            data={data}
            style={{
              fontSize: 10,
            }}
            marginLeft={70}
            key={'label'}
            xOffset={-20}
          />
          <LabelSeries
            getLabel={d => d.x}
            labelAnchorY={'middle'}
            labelAnchorX={'start'}
            data={data}
            style={{ fontSize: 10 }}
            key={'label2'}
            xOffset={20}
            marginLeft={90}
          />
        </XYPlot>
      </Container>
    )
  }
}

HubActivitiesChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
}

export default HubActivitiesChart
