import React, { PureComponent } from 'react'
import styled from '@emotion/styled'
import { Icon, Tooltip } from 'antd'

import Row from '../../common/Row'

const Container = styled('div')`
  margin-top: 1rem;
`
const HeaderResultsContainer = styled(Row)`
  align-items: center;
  border-radius: 4px;
  border: 1px solid ${props => props.theme.grey};
`
const TotalText = styled('div')`
  padding:1rem 0.75rem;
  font-weight: bold;
  flex-grow: 0;
  color: ${props => props.theme.black};
  border-radius: 4px 0 0 4px;
  background-color:${props => props.theme.grey};
`
const HeaderText = styled('div')`
  padding:1rem 0.75rem;
  background-color: #fff;
  flex-grow: 1;
  border-left: solid 1px #e0e0e0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
`
const HeaderValue = styled('span')`
  color: ${props => props.theme.topaz};
  font-size: 16px;
  margin:0 0.5rem;
`
const Title = styled('div')`
  font-size:12px;
  color:${props => props.theme.bluegrey};
  font-weight:600;
  margin:0.5rem 0;
`
const HeaderResults = ({ newPop, newPatronage, newJobs, lga }) => {
  return (
    <HeaderResultsContainer>
      <TotalText>Total</TotalText>
      <HeaderText>
        New {lga} population near stations <HeaderValue>{newPop}%</HeaderValue>
        <Tooltip title="Increase of total LGA population needed in 1.6km station catchments to meet targets">
          <InfoIcon type="info-circle" />
        </Tooltip>
      </HeaderText>
      <HeaderText>
        New {lga} patronage <HeaderValue>{newPatronage}</HeaderValue>
        <Tooltip title="Increase of total LGA patronage that could be achieved if the necessary targets are met">
          <InfoIcon type="info-circle" />
        </Tooltip>
      </HeaderText>
      <HeaderText>
        New {lga} jobs
        <HeaderValue>{newJobs && parseInt(newJobs)}</HeaderValue>
      </HeaderText>
    </HeaderResultsContainer>
  )
}

const InfoIcon = styled(Icon)`
  color: ${props => props.theme.darkgrey};
`

class LGALevelResults extends PureComponent {
  render() {
    const { population, newPatronage, employment, lga } = this.props
    if(!lga || !newPatronage) return null

    const sumNewPatronage = Object.values(newPatronage).reduce((prev, curr) => {
      if(isNaN(curr)) {
        return prev
      } else {
        return prev + curr
      }
    },0)

    return (
      <Container>
        <Title>LGA Level Results</Title>

        <HeaderResults
          lga={lga}
          newPop={population}
          newPatronage={sumNewPatronage}
          newJobs={employment}
        />
      </Container>
    )
  }
}

export default LGALevelResults