import {
  CostBenefitAnalysis
} from '../constants/actionTypes'
const {
  SET_ACTIVE_TRAVEL_PARAMS,
  SET_YOUR_MOVE_PARAMS
} = CostBenefitAnalysis

export const initialState = {
  activeTravel: {},
  yourMove: {}
}

export default function (state = initialState, {
  type,
  payload
}) {

  switch (type) {
    case SET_ACTIVE_TRAVEL_PARAMS:
      {
        return {
          ...state,
          activeTravel: {
            ...state.activeTravel,
            ...payload
          },
        }
      }
    case SET_YOUR_MOVE_PARAMS:
      {
        return {
          ...state,
          yourMove: {
            ...state.yourMove,
            ...payload
          }
        }
      }
    default:
      return state
  }
}