import React from "react";
import { Route, Switch } from "react-router-dom";
import Login from "./components/Authentication/Login";
import Logout from "./components/Authentication/Logout";
import NotFound from "./components/Authentication/NotFound";
import Signup from "./components/Authentication/Signup";
import ScenarioPlanner from './components/ScenarioPlanner';
import CostBenefitAnalysis from './components/CostBenefitAnalysis';
import Dashboard from './components/Dashboard'


export default ({ childProps }) =>
  <Switch>

    <Route path={"/"} exact component={Dashboard} props={childProps}/>
    <Route path={"/planner"} component={ScenarioPlanner} props={childProps} />
    <Route path={"/cba"} component={CostBenefitAnalysis} props={childProps} />
    <Route path={"/login"}  component={Login} props={childProps} />
    <Route path={"/logout"}  component={Logout} props={childProps} />
    <Route path={"/signup"}  component={Signup} props={childProps} />

    { /* Finally, catch all unmatched routes */ }
    <Route component={NotFound} />
  </Switch>;


