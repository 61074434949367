import { connect } from 'react-redux'
import LGALevelSummaryRanges from '../components/ScenarioPlanner/LGALevelSummaryRanges'
import { selectorCurrentScores, selectorLGAValues, selectorLGARanges } from '../selectors/scenario-clusterchange-selector';


export default connect(state => {
  
  return {
    clusters: state.nodeplace.clusters,
    cluster: selectorCurrentScores(state),
    existingAverages: selectorLGAValues(state),
    futureRange: selectorLGARanges(state)
  }
})(LGALevelSummaryRanges)
