import React from 'react'
import styled from '@emotion/styled'
import { Icon, Input } from 'antd'

import Column from '../../../common/Column'

const Container = styled(Column)`
  width: 100px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #e0e0e0;
  justify-content: center;
  height: 100%;

  & input,
  select,
  textarea {
    color: ${props => props.emp};
    text-align: center;
  }

  & .ant-input-group-addon {
    padding: 2px;
  }
`

function NewEmp(props) {
  let {
    newEmployment,
    newEmploymentAdded,
    record,
    jobIncrease,
    updateState,
    setEmploymentResult,
    newPop,
    workingAgePop,
    existingJobs,
    selfSufficiencyTarget,
    ratios,
    setEmploymentCreated,
  } = props

  const totalJobs = (
    (newPop * (workingAgePop / 100) - existingJobs) *
    (selfSufficiencyTarget / 100)
  ).toFixed(0)

  const populationDriven = (totalJobs * (1 - ratios['Ratio per LGA'])).toFixed(
    0
  )

  return (
    <Container
      key={newEmployment[record['sector']] + record['sector']}
      emp={newEmployment[record['sector']] >= 0 ? '#149FD7' : '#FF5056'}
    >
      <Input
        addonAfter={
          <Icon
            type={'plus'}
            onClick={() => {
              newEmployment[record['sector']] += 1
              newEmploymentAdded[record['sector']] +=
                parseFloat(record['multiplier'])
              jobIncrease = jobIncrease + parseFloat(record['multiplier'])
              setEmploymentResult(jobIncrease + populationDriven)
              setEmploymentCreated({
                newEmployment,
                existingBase: { [record['sector']]: record },
              })
              updateState({
                newEmployment,
                newEmploymentAdded,
                jobIncrease,
              })
            }}
          />
        }
        addonBefore={
          <Icon
            type={'minus'}
            onClick={() => {
              newEmployment[record['sector']] -= 1
              newEmploymentAdded[record['sector']] -=
                parseFloat(record['multiplier'])
              jobIncrease = jobIncrease - parseFloat(record['multiplier'])
              setEmploymentResult(jobIncrease + populationDriven)
              setEmploymentCreated({
                newEmployment,
                existingBase: { [record['sector']]: record },
              })
              updateState({
                newEmployment,
                newEmploymentAdded,
                jobIncrease,
              })
            }}
          />
        }
        size={'small'}
        defaultValue={newEmployment[record['sector']]}
        onBlur={e => {
          jobIncrease +=
            (e.target.value -
              record['employment'] -
              newEmployment[record['sector']]) *
            parseFloat(record['multiplier'])
          newEmployment[record['sector']] =
            e.target.value - record['employment']

          newEmploymentAdded[record['sector']] =
            e.target.value -
            record['employment'] * parseFloat(record['multiplier'])

          setEmploymentResult(jobIncrease)
          setEmploymentCreated({
            newEmployment,
            existingBase: { [record['sector']]: record },
          })
          updateState({ newEmployment, newEmploymentAdded, jobIncrease })
        }}
      />
    </Container>
  )
}

export default NewEmp
