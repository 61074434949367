import { connect } from 'react-redux'
import MapToolbox from '../components/MapToolbox'
import { selectHub } from '../actions/hub'
import { setMode } from '../actions/maptoolbox'

import { selectorCombineAttribute } from '../selectors/contextLayer-selector'
import { selectorShowContextLayer, selectorShowEmploymentLayer } from '../selectors/sa2Layer-selector'

export default connect(
  state => {
    return {
      modelName: state.sa2layer.modelName,
      contextLegend: selectorCombineAttribute(state).contextLegend,
      modes: state.maptoolbox.modes,
      selectedMode: state.maptoolbox.selectedMode,
      showContextLayer: selectorShowContextLayer(state),
      showEmploymentLayer: selectorShowEmploymentLayer(state)
    }
  },
  { selectHub, setMode }
)(MapToolbox)
