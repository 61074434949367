import { createSelector } from 'reselect'
import config from '../config/legend'

export const dataSelector = state => state.hubs.data
const selectedHubSelector = state => state.hubs.selectedHub
const nodeplaceSelector = state => state.nodeplace.results

let colors = {}
Object.keys(config.legendNodePlace).forEach(cluster => {
  colors[cluster] = config.legendNodePlace[cluster].color
})
const noDataColor = '#0A5990'

export const joinedHubs = createSelector(
  dataSelector,
  nodeplaceSelector,
  (data, nodeplace) => {
    if (data && nodeplace) {
      const placeTypes = nodeplace.reduce((acc, curr) => {
        const color = colors[curr.place_type] ? colors[curr.place_type] : null
        acc[+curr.ID] = color
        return acc
      }, {})
      return data.map(f => {
        if (!f.hasOwnProperty('NodePlace_HubID'))
          return { ...f, color: noDataColor, stroke: '#fff' }
        const color = placeTypes[f.NodePlace_HubID]
          ? placeTypes[f.NodePlace_HubID]
          : noDataColor
        const stroke = placeTypes[f.NodePlace_HubID] ? '#333' : '#fff'
        return { ...f, color, stroke }
      })
    } else {
      return []
    }
  }
)

export const selectorOtherHubs = createSelector(
  joinedHubs,
  selectedHubSelector,
  (data, hub) => {
    return hub ? data.filter(f => f.ID !== hub.ID) : data
  }
)
