import { TravelSurvey } from '../constants/actionTypes'
import { queryTravelSurvey } from '../queries/travelsurvey'

const {
    GET_TRAVELSURVEY,
    GET_TRAVELSURVEY_SUCCESS,
    GET_TRAVELSURVEY_FAILURE,
} = TravelSurvey

export const loadTravelSurvey = () => {
    return function (dispatch) {
        dispatch(getTravelSurvey())
        queryTravelSurvey()
            .then(res => res.json())
            .then(json => dispatch(getTravelSurveySuccess(json)))
            .catch(err => dispatch(getTravelSurveyFailure(err)))
    }
}

export const getTravelSurvey = () => ({
    type: GET_TRAVELSURVEY,
})

export const getTravelSurveySuccess = payload => ({
    type: GET_TRAVELSURVEY_SUCCESS,
    payload,
})

export const getTravelSurveyFailure = payload => ({
    type: GET_TRAVELSURVEY_FAILURE,
    payload,
})
