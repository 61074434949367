import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

const Container = styled('div')`
  padding: 0 2rem;
`
const Header = styled('div')`
  font-size: 16px;
  font-weight: 600;
`
const SecondHeader = styled('div')`
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 1rem;
`

const InfoContainer = styled('div')`
  display: flex;
`
const ItemContainer = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-bottom: 1rem;
  padding-top: -1rem;
`
const SubHeader = styled('div')`
  font-size: 12px;
`
const JobText = styled('div')`
  color: ${props => props.color};
  font-size: 28px;
`
const SelectedHub = styled('div')`
  font-size: 0.875rem;
  padding-bottom: 1rem;
`
const commas = x => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

const JobNumericalComparison = props => {
  const { StrategicJobs, PopulationJobs, Total, Hub } = props
  return (
    <Container>
      <Header>Selected Hub</Header>
      <SelectedHub>{Hub}</SelectedHub>
      <SecondHeader>Jobs</SecondHeader>
      <InfoContainer>
        <ItemContainer>
          <SubHeader>Strategic</SubHeader>
          <JobText color={'#ff9505'}>{commas(StrategicJobs)}</JobText>
        </ItemContainer>
        <ItemContainer>
          <SubHeader>Population-driven</SubHeader>
          <JobText color={'#ff9505'}>{commas(PopulationJobs)}</JobText>
        </ItemContainer>
      </InfoContainer>

      <InfoContainer>
        <ItemContainer>
          <SubHeader>Total</SubHeader>
          <JobText color={'#ff9505'}>{commas(Total)}</JobText>
        </ItemContainer>
        <ItemContainer />
      </InfoContainer>
    </Container>
  )
}
JobNumericalComparison.propTypes = {
  StrategicJobs: PropTypes.number,
  PopulationJobs: PropTypes.number,
  Total: PropTypes.number,
  Hub: PropTypes.string,
}
export default JobNumericalComparison
