import React, { PureComponent } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { Modal } from 'antd'

import AboutTitle from './AboutTitle'
import AboutGetStarted from './AboutGetStarted'
import AboutBottom from './AboutBottom'

const Container = styled('div')`
  background-color:#fff;
  margin: -24px; 
`

class AboutPage extends PureComponent {
  close = () => this.props.toggleAbout(false)
  render() {
    const { show } = this.props
    return (
      <Modal
          title={null}
          centered
          visible={show}
          footer={null}
          width={1100}
          onCancel={this.close}
          onOk={this.close}
          // style={{height: '90vh', overflowY: 'scroll'}}
        >
        <Container>
          <AboutTitle />
          <AboutGetStarted close={this.close}/>
          <AboutBottom />
        </Container>
      </Modal>
    )
  }

}

AboutPage.propTypes = {
  toggleAbout: PropTypes.func.isRequired,
  show: PropTypes.bool,
}

export default AboutPage