import {
    CostBenefitAnalysis
} from '../constants/actionTypes'
const {
    SET_ACTIVE_TRAVEL_PARAMS,
    SET_YOUR_MOVE_PARAMS
} = CostBenefitAnalysis

export const setActiveTravelParams = payload => ({
    type: SET_ACTIVE_TRAVEL_PARAMS,
    payload,
})
export const setYourMoveParams = payload => ({
    type: SET_YOUR_MOVE_PARAMS,
    payload,
})