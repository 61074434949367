import React from 'react'
import styled from '@emotion/styled'
import { Input } from 'antd'

import Column from '../../../common/Column'
import Row from '../../../common/Row'
import Heading from '../../../common/Heading'
import { numberWithCommas } from '../../../utils/formatting'
import { Tooltip } from 'antd'
import IconButtonMini from '../../../common/IconButtonMini'

const Container = styled(Column)`
  width: ${props => props.width}px;
  border-left: 1px solid ${props => props.theme.bluegrey};
  color: ${props => props.theme.black};
  flex: 1 0 ${props => props.width}px;
`

const ResultsContainer = styled('div')`
  background-color: #f8f8f8;
  border-top: 1px solid ${props => props.theme.bluegrey};
  padding: 1rem 1rem 0.5rem 1rem;
`
const TextRow = styled(Row)`
  align-items: center;
  margin: 0.5rem 0;
  font-weight: bold;
`

const Station = styled('div')`
  font-size: 12px;
`

const Title = styled('div')`
  font-weight: normal;
  margin-right: 1rem;
  width: 120px;
  flex-shrink: 0;
  font-size: 12px;
`
const HighlightedChange = styled('div')`
  margin-left: 0.5rem;
  font-size: 12px;
  color: ${props => props.theme.red};
`
const ImgContainer = styled('div')`
  height: 100px;
  border: solid 1px #fff;

  margin-bottom: 10px;
`

const Img = styled('div')`
  margin: 0;
    background-position: center;
    background-size: cover;
    background-image: url('${props => props.src}');
    width: 100%;
    height: 100px;
    border-radius:3px;
`

const InputRow = ({ title, value, after, highlight, onChange }) => {
  return (
    <TextRow>
      <Title>{title}</Title>{' '}
      <Input
        addonAfter={after}
        defaultValue={value}
        value={value}
        style={{ width: 90 }}
        onChange={onChange}
      />
      {highlight && <HighlightedChange>{highlight}</HighlightedChange>}
    </TextRow>
  )
}

const Result = ({
  popChange,
  newPatronage,
  station,
  setFutureStnPop,
  target,
  origin,
  structurePlanPop,
  setStructurePlanPop,
  originImage,
  targetImage,
  lgaPopChange,
  width,
}) => {
  setFutureStnPop({
    pop: popChange,
    station: station,
    origin: origin,
    target: target,
    patronage: newPatronage,
  })
  return (
    <ResultsContainer>
      <Heading>{station} cluster change result</Heading>
      <Station>From {origin.name}</Station>
      <ImgContainer>
        <Img src={originImage} />
      </ImgContainer>
      <Station>To {target.name}</Station>
      <ImgContainer>
        <Img src={targetImage} />
      </ImgContainer>

      <TextRow>
        <Title>Population 2032:</Title> {numberWithCommas(lgaPopChange)}
      </TextRow>
      <InputRow
        title={'Structure plan population target:'}
        value={structurePlanPop}
        onChange={e => {
          setStructurePlanPop({ newPop: e.target.value, station })
        }}
        highlight={null}
      />

      <TextRow>
        <Title>% of Population Change</Title>
        {isNaN(popChange) ? 0 : popChange} %
      </TextRow>
      <TextRow>
        <Title>New Patronage</Title>
        {newPatronage}
        <Tooltip title={'Estimated average weekday patronage for stops within 1.6km buffer around the station'}>
          <IconButtonMini shape={'circle'} icon="info-circle" size={'small'} />
        </Tooltip>
      </TextRow>
    </ResultsContainer>
  )
}

const ChangeClusterResults = ({
  width,
  origin,
  target,
  popChange,
  structurePlanPop,
  setStructurePlanPop,
  patronageNumber,
  setFutureStnPop,
  originImage,
  targetImage,
  stnPopChange,
}) => {
  return (
    <Container width={width}>
      <Result
        origin={origin}
        target={target}
        popChange={((stnPopChange / popChange) * 100).toFixed(2)}
        lgaPopChange={popChange}
        newPatronage={Math.ceil(patronageNumber / 100) * 100}
        setFutureStnPop={setFutureStnPop}
        station={origin.name}
        structurePlanPop={structurePlanPop}
        setStructurePlanPop={setStructurePlanPop}
        originImage={originImage}
        targetImage={targetImage}
      />
    </Container>
  )
}

export default ChangeClusterResults
