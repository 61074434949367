import styled from '@emotion/styled'
import { Slider } from 'antd'
export default styled(Slider)`
  &.ant-slider .ant-slider-track {
    background-color: ${props => props.theme.cyan};
  }
  &.ant-slider .ant-slider-track,
  .ant-slider-rail {
    height: 6px;
  }
  &.ant-slider .ant-slider-handle {
    border-color: ${props => props.theme.cyan};
    width: 16px;
    height: 16px;
    &:focus {
      box-shadow: 1px 1px 4px 0 ${props => props.theme.darkgrey};
    }
  }
  &.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
    border-color: ${props => props.theme.cyan};
  }
  &.ant-slider .ant-slider-step .ant-slider-dot {
    width: 3px;
    height: 8px;
    border: none;
    margin-left: 0;
    border-radius: 0;
  }
  &.ant-slider .ant-slider-step .ant-slider-dot {
    &:first-of-type,
    :last-child {
      display: none;
    }
  }
  &.ant-slider .ant-slider-mark-text {
    user-select: none;
  }
`
