import React, { PureComponent } from 'react'
import styled from '@emotion/styled'
import { connect } from 'react-redux'
import { Form } from 'antd'

import { setActiveTravelParams } from '../../actions/costbenefitanalysis'

import FormNumberInput from './FormNumberInput'
import FormSelect from './FormSelect'
import FormDecimalNumber from './FormDecimalNumber'
import PopulationOverride from './PopulationOverride'

import Row from '../../common/Row'
import Column from '../../common/Column'
import Subtitle from '../../common/Subtitle'
import PrimaryButton from '../../common/PrimaryButton'

import config from './config'

const CreateForm = Form.create

const FormContainer = styled(Form)`
    display:flex;
    flex-grow: 1;
`
const FormColumn = styled(Column)`
    margin:0;
`
const FormRow = styled(Row)`
    justify-content: space-between;
`
const Container = styled(Column)`
    padding: 2rem;
    margin-right: 1rem;
    border-radius:3px;
    flex: 1 1 0;
    background-color: ${props => props.theme.lightgrey};
`
const SubmitContainer = styled(Row)`
    margin-bottom:1rem;
    justify-content:space-between;
`

const WIDTH = 250

class Inputs extends PureComponent {

  componentDidMount() {
    const startState = Object.assign(...Object.keys(config).map(key => {
      const { name, initialValue } = config[key]
      return {
        [name]: initialValue
      }
    }))
    this.props.setActiveTravelParams(startState)
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.handleSubmit(values)
      }
    });
  }

  handleInfo = name => this.props.handleInfo(name)

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form
    return (
      <FormContainer layout={'vertical'}>
        <Container>
          <SubmitContainer>
            <Subtitle>Inputs</Subtitle>
            <PrimaryButton onClick={this.handleSubmit}>Submit</PrimaryButton>
          </SubmitContainer>
          <FormRow>
            <Column>
              <FormRow>
                <FormColumn>
                  <FormSelect data={config.lgas} getFieldDecorator={getFieldDecorator} updateLabel={this.handleInfo} width={WIDTH} />
                  <PopulationOverride getFieldValue={getFieldValue} data={config} getFieldDecorator={getFieldDecorator} updateLabel={this.handleInfo} width={WIDTH} />
                </FormColumn>
                <FormColumn style={{ marginLeft: 50 }}>
                  <FormNumberInput data={config.populationImpacted} getFieldDecorator={getFieldDecorator} updateLabel={this.handleInfo} width={WIDTH} />
                  <FormNumberInput data={config.capex} getFieldDecorator={getFieldDecorator} updateLabel={this.handleInfo} width={WIDTH} />
                  <FormNumberInput data={config.opex} getFieldDecorator={getFieldDecorator} updateLabel={this.handleInfo} width={WIDTH} />
                </FormColumn>
              </FormRow>
              <Column>
                <FormSelect data={config.interventions} getFieldDecorator={getFieldDecorator} updateLabel={this.handleInfo} width={WIDTH + 225} />
                <FormSelect data={config.safety} getFieldDecorator={getFieldDecorator} updateLabel={this.handleInfo} width={WIDTH + 225} />
              </Column>
            </Column>
            <FormColumn>
              <FormDecimalNumber data={config.traveltimeCyclists} getFieldDecorator={getFieldDecorator} updateLabel={this.handleInfo} width={WIDTH} step={0.1} />
              <FormDecimalNumber data={config.traveltimePedestrians} getFieldDecorator={getFieldDecorator} updateLabel={this.handleInfo} width={WIDTH} step={0.1} />
              <FormDecimalNumber data={config.lengthOfInitiative} getFieldDecorator={getFieldDecorator} updateLabel={this.handleInfo} width={WIDTH} step={0.1} />
              <FormNumberInput data={config.constructionStartYear} getFieldDecorator={getFieldDecorator} updateLabel={this.handleInfo} width={WIDTH} />
              <FormNumberInput data={config.openingYear} getFieldDecorator={getFieldDecorator} updateLabel={this.handleInfo} width={WIDTH} />
              <FormNumberInput disabled={true} data={config.evaluationperiod} getFieldDecorator={getFieldDecorator} updateLabel={this.handleInfo} width={WIDTH} />
            </FormColumn>
          </FormRow>
        </Container>
      </FormContainer>
    )
  }
}

const ConnectedInputs = connect()(CreateForm({
  name: 'activetransport',
  onValuesChange(props, values) { props.dispatch(setActiveTravelParams(values)) }
})(Inputs))
export default ConnectedInputs

