import React, { PureComponent } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import ReactMapboxGl from 'react-mapbox-gl'
import Layers from './Layers'

import mapStyle from '../../config/map'

const MapContainer = styled('div')`
  margin: ${props => props.margin}rem;
  height: ${props => props.height}px;
  width: ${props => props.width}px;
  flex-shrink: 0;
`

const ReactMap = ReactMapboxGl({
  accessToken: null, // not required as we are using the free OSM basemap
  maxZoom: 15,
})

class Map extends PureComponent {
  viewport = {
    zoom: 10,
    maxZoom: 20,
    minZoom: 0,
    pitch: 0,
    bearing: 0,
    center: [115.82183992548926, -31.99748894521732],
  }
  componentDidUpdate(prevProps) {
    if (this.map && this.props.panelSize !== prevProps.panelSize) {
      this.map.resize()
    }

    if (this.props.center !== this.viewport.center) {
      this.map && this.map.flyTo({ center: this.props.center })
    }
  }
  storeInternalRef = map => {
    this.map = map
  }
  updateViewport = () => {
    const center = this.map.getCenter()
    const zoom = this.map.getZoom()
    const pitch = this.map.getPitch()
    this.viewport = {
      ...this.viewport,
      center: [center.lng, center.lat],
      zoom,
      pitch,
    }
  }
  render() {
    const { hubs, margin, height, width } = this.props
    const { pitch, zoom, center } = this.viewport
    return (
      <MapContainer margin={margin} height={height} width={width} >
        <ReactMap
          style={mapStyle}
          center={center}
          pitch={[pitch]}
          zoom={[zoom]}
          onDragEnd={this.updateViewport}
          onZoomEnd={this.updateViewport}
          onPitchEnd={this.updateViewport}
          containerStyle={{ height: '100%', width: '100%' }}
          onStyleLoad={this.storeInternalRef}
        >
          <Layers hubs={hubs}/>
        </ReactMap>
      </MapContainer>
    )
  }
}

Map.propTypes = {
  panelSize: PropTypes.number.isRequired,
}

export default Map
