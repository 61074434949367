import React from 'react'
import styled from '@emotion/styled'
import { Button, Icon, Select } from 'antd'

import Subtitle from '../../common/Subtitle'
import Row from '../../common/Row'
import Column from '../../common/Column'

const Option = Select.Option

const Container = styled(Column)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const Inner = styled(Row)`
  padding: 0.5rem 0.75rem;
  align-items: center;
  border-radius: 4px 4px 0px 0px;
  width: 330px;
  background-color: ${props => props.theme.darkbluegrey};
`
const DarkSubtitle = styled(Subtitle)`
  color: ${props => props.theme.white};
  margin-right: 1rem;
`
const AcademicContainer = styled('div')`
  display: inherit;
  width: 15rem;
  margin: 0;
`

const SwitchModel = ({
  models,
  selected,
  update,
}) => {
  return (
    <Container>
      <Inner>
        <DarkSubtitle>Select model</DarkSubtitle>
        <Select
          value={selected}
          style={{ width: '200px' }}
          onChange={update}
        >
          {models.map(f => {
            return (
              <Option key={f} value={f}>
                {f}
              </Option>
            )
          })}
        </Select>
      </Inner>
      <AcademicContainer>
        <Button ghost block style={{ fontSize: '0.75rem' }} 
          href={"https://resources.patrec.org/reports/railsmart/cba.pdf"}>
          <Icon style={{ verticalAlign: '0.125em' }} type="file-text" />
          Download Academic Paper
        </Button>
      </AcademicContainer>
    </Container>
  )
}

export default SwitchModel
