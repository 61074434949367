export default {
    evaluationperiod: {
        name: 'evaluationperiod',
        initialValue: 20,
        label: 'Evaluation period',
        info: false,
        formatter: value => `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} yrs`,
        parser: value => parseFloat(value.replace(/\D/g,''))
    },
    scenarios: {
        name: 'scenario',
        initialValue: 'High',
        options: [
            "Low",
            "Medium",
            "High"
        ],
        label: 'Population scenario',
        info: 'There is a selection of low, medium and high future population assumptions.',
    },
    population: {
        name: 'population',
        initialValue: 0,
        label: 'Override population',
        info: 'Override the forecasted population and manually input your own population forecast.',
        formatter: value => `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
        parser: value => parseFloat(value.replace(/\D/g,''))
    },
    override: {
        name: 'override',
        initialValue: false,
        label: '',
        info: false,
    },
    openingYear: {
        name: 'openingYear',
        initialValue: 2020,
        label: 'Opening year',
        info: 'You need to input the year that the construction starts (which would be the evaluation start year) and the year that the facility becomes available (which would be the benefit start year). These years can be the same or different.',
        formatter: value => value,
        parser: value => parseFloat(value.replace(/\D/g,''))
    },
    constructionStartYear: {
        name: 'startYear',
        initialValue: 2019,
        label: 'Construction start year',
        info: 'You need to input the year that the construction starts (which would be the evaluation start year) and the year that the facility becomes available (which would be the benefit start year). These years can be the same or different.',
        formatter: value => value,
        parser: value => parseFloat(value.replace(/\D/g,''))
    },
    lengthOfInitiative: {
        name: 'lengthOfProposedInitiative',
        initialValue: 2,
        label: 'Length of the initiative',
        info: 'You need to input the length of the proposed intervention in km.',
        formatter: value => `${value.toString()} km`,
        parser: value => parseFloat(value.replace(/k|m/g, '')),
    },
    traveltimePedestrians: {
        name: 'travelTimeImprovementsForPedestrians',
        initialValue: 20,
        label: 'Travel time improvement for pedestrians',
        info: 'You can input travel time improvement if any in minutes. Input 0 mins for provision of a new piece of infrastructure.',
        formatter: value => `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} min`,
        parser: value => parseFloat(value.replace(/\D/g,''))
    },
    traveltimeCyclists: {
        name: 'travelTimeImprovementsForCyclists',
        initialValue: 10,
        label: 'Travel time improvement for cyclists',
        info: 'You can input travel time improvement if any in minutes. Input 0 mins for provision of a new piece of infrastructure.',
        formatter: value => `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} min`,
        parser: value => parseFloat(value.replace(/\D/g,''))
    },
    safety: {
        name: 'safetyImprovement',
        initialValue: 'Complete separation of cyclists and pedestrians from the traffic implemented',
        options: [
            'Complete separation of cyclists and pedestrians from the traffic implemented',
            'Other types of safety measures implemented',
            'No safety improvement'
        ],
        label: 'Safety improvement',
        info: 'The selection needs to consider what you have selected for the intervention type.',
    },
    interventions: {
        name: 'interventionType',
        initialValue: 'New off-road AT path - inner city location',
        options: [
            'New off-road AT path - inner city location',
            'New off-road AT path - outer suburban or rural location',
            'On-road cycle lane',
            'Improved signals and channelisation for cyclists and pedestrians',
            'End-of-trip facilities',
        ],
        label: 'Intervention type',
        info: 'You have a selection of different intervention types.',
    },
    opex: {
        name: 'OPEX',
        initialValue: 10000,
        label: 'Operating cost (OPEX)',
        info: 'You can input any CAPEX and OPEX. The analysis presumes CAPEX will be spent in the first year and OPEX will be spent in the subsequent years.',
        formatter: value => `$${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} /yr`,
        parser: value => parseFloat(value.replace(/\D/g,''))
    },
    capex: {
        name: 'CAPEX',
        initialValue: 3500000,
        label: 'Program cost (CAPEX)',
        info: 'You can input any CAPEX and OPEX. The analysis presumes CAPEX will be spent in the first year and OPEX will be spent in the subsequent years.',
        formatter: value => `$${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
        parser: value => parseFloat(value.replace(/\D/g,''))
        // formatter: value => `$${value/1000000}m`,
        // parser: value => {
        //     const result = value.replace('$', '').replace('m', '')
        //     return result * 1000000
        // }
    },
    populationImpacted: {
        name: 'sizeOfImpactedPop',
        initialValue: 1000,
        label: 'Population impacted',
        info: 'The number of travellers that will be impacted by the intervention depends on its nature. For instance, a provision of a long cycleway may attract many travellers compared to a provision of a small bike shed at a train station. The user can input the size of population that will be impacted by the intervention. This is not the number of travellers that will be using the initiative. Instead, this is the number of travellers that reside or work close by who are aware of the initiative and may or may not be using it.',
        formatter: value => `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
        parser: value => parseFloat(value.replace(/,/g, ''))
    },
    lgas: {
        name: 'lga',
        initialValue: "Wanneroo (C)",
        label: 'Travel behaviour characteristics',
        info: 'Select appropriate LGA to incorporate travel behaviour characteristics into the analysis. Select “all” to use general Perth characteristics.',
        options: [
            "All",
            "Bassendean (T)",
            "Bayswater (C)",
            "Belmont (C)",
            "Cambridge (T)",
            "Canning (C)",
            "Claremont (T)",
            "Cockburn (C)",
            "Cottesloe (T)",
            "East Fremantle (T)",
            "Fremantle (C)",
            "Gosnells (C)",
            "Joondalup (C)",
            "Kalamunda (S)",
            "Kwinana (C)",
            "Mandurah (C)",
            "Melville (C)",
            "Mosman Park (T)",
            "Mundaring (S)",
            "Murray (S)",
            "Nedlands (C)",
            "Peppermint Grove (S)",
            "Perth (C)",
            "Rockingham (C)",
            "Serpentine-Jarrahdale (S)",
            "South Perth (C)",
            "Stirling (C)",
            "Subiaco (C)",
            "Swan (C)",
            "Victoria Park (T)",
            "Vincent (C)",
            "Wanneroo (C)"
        ]
    }
}