import { connect } from 'react-redux'
import HubLayer from '../components/Layers/HubLayer'
import { loadAllHubData } from '../actions/hub'
import { selectorOtherHubs } from '../selectors/hubLayer-selector'

export default connect(
  state => {
    return {
      selectedHub: state.hubs.selectedHub,
      data: selectorOtherHubs(state),
    }
  },
  {
    selectHub: loadAllHubData,
  }
)(HubLayer)
