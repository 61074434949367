import React, { PureComponent } from 'react'
import { XYPlot, LabelSeries, HorizontalBarSeries } from 'react-vis'
import styled from '@emotion/styled'

const Heading = styled('div')`
  font-weight: 600;
  font-size: 1rem;
  padding-top: 0.75rem;
  padding-bottom: 0.25rem;
  padding-left: 0;
  padding-right: 0;
`

const Subtitle = styled('div')`
  font-weight: 400;
  font-size: 0.75rem;
  padding: 0.125rem 0;
`

const BoldText = styled('span')`
  font-weight: 600;
`

const ItalicSubtitle = styled('div')`
  font-style: italic;
  font-weight: 400;
  font-size: 0.65rem;
  padding: 0.125rem 0;
`

const GenericSpacer = styled('div')`
  padding: 0.5rem 0;
`

class GenericIndicator extends PureComponent {
    render() {
        const { data, title, subtitle, colors } = this.props;
        const plot_height = (data.length * 20) + ((data.length - 1) * 5);
        return (
            <>
                <Heading>{title}</Heading>
                <ItalicSubtitle>{subtitle}.</ItalicSubtitle>
                <XYPlot animation margin={{ left: 90, right: 40, top: 2, 
                    bottom: 2 }} width={250} height={plot_height} 
                    yType="ordinal">
                    <HorizontalBarSeries barWidth={0.8} margin={{ left: 20 }}
                        stroke={'#fff'} data={data} colorType={"literal"} 
                        getColor={d => {return colors[d.y]}} />
                    <LabelSeries getX={d => 0} getY={d => d.y} 
                        getLabel={d => d.y} labelAnchorY={'middle'} 
                        labelAnchorX={'start'} data={data} 
                        style={{ fontSize: 10 }} marginLeft={0} 
                        key={'label-title'} xOffset={0} />
                    <LabelSeries getLabel={d => d.x.toString() + "%"} 
                        labelAnchorY={'middle'} labelAnchorX={'start'} 
                        data={data} style={{ fontSize: 10 }} key={'label-value'}
                        xOffset={20} marginLeft={95} />
                </XYPlot>
            </>
        )
    }
}

class GenericTitle extends PureComponent {
    render() {
        const { data } = this.props;
        return (
            <>
                <Heading>Travel Survey Results</Heading>
                <Subtitle>Viewing Results for <BoldText>{data.area}
                    </BoldText>.</Subtitle>
                <Subtitle>There were <BoldText>{data.sample_size}
                    </BoldText> respondents.</Subtitle>
            </>
        )
    }
}

export {GenericIndicator, GenericTitle, GenericSpacer};