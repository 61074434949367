import React from 'react'
import styled from '@emotion/styled'

const Awards = styled('div')`
  text-align: center;
  padding: 0.75rem;
  padding-top: 0.25rem;
`
const AwardImage = styled('img')`
  height: 60px;
  margin: 0.75rem;
`

const AboutBottom = () => {
  return (
    <Awards>
      <a href="https://smart-cities.com.au/awards/2019-winners/best-integration-of-an-individual-technology/">
        <AwardImage src="https://resources.patrec.org/sca19-winner.png"
          alt="Winner of 'Best Integration of an Individual Technology' at the Smart Cities Awards, 2019" />
      </a>
      <a href="https://www.rtpi.org.uk/knowledge/research/rtpi-awards-for-research-excellence/2019-rtpi-research-awards/#commended">
        <AwardImage src="https://resources.patrec.org/rtpi-commended.png"
          alt="Commended, RTPI Awards for Research Excellence, 2019" />
      </a>
      <a href="https://www.rtpi.org.uk/events-training-and-awards/awards/rtpi-awards-for-planning-excellence/finalists-2020/">
        <AwardImage src="https://resources.patrec.org/rtpi20-finalist.png"
          alt="Finalist, RTPI Awards for Planning Excellence, 2020" />
      </a>
    </Awards>
  )
}

export default AboutBottom;