import { Hubs } from '../constants/actionTypes'
const { SELECT_HUB, ADD_STOPS, LOAD_HUBS } = Hubs

export const initialState = {
  data: null,
  selectedHub: null,
  stops: null,
}

export default function(state = initialState, { type, payload }) {
  switch (type) {
    case SELECT_HUB: {
      return {
        ...state,
        selectedHub: state.data.filter(f => f.ID === payload)[0],
      }
    }
    case ADD_STOPS: {
      return {
        ...state,
        stops: payload,
      }
    }
    case LOAD_HUBS: {
      return {
        ...state,
        data: payload,
      }
    }
    default:
      return state
  }
}
