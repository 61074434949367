import { createSelector } from 'reselect'

const modelsSelector = state => state.modelcontainer.models
const showContainerSelector = state => state.modelcontainer.show
const selectedHubSelector = state => state.hubs.selectedHub

export const selectorModelSize = createSelector(
  modelsSelector,
  models => models.length
)

export const selectorShowAddBtn = createSelector(
  modelsSelector,
  models => models.length < 3
)

export const selectorShowContainer = createSelector(
  showContainerSelector,
  selectedHubSelector,
  (show, selectedHub) => (show && selectedHub ? true : false)
)
