const rootUrl = process.env.REACT_APP_API

export function queryHubPatronage() {
  const url = `${rootUrl}/callmodel`
  const params = {
    results_param: 'accessResults',
    model: 'smartRiderStays',
    req_params: {
      format: 'json',
    },
  }
  return fetch(url, {
    method: 'post',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  })
}
