import { About } from '../constants/actionTypes'
const { TOGGLE_ABOUT } = About

export const initialState = false

export default function(state = initialState, { type, payload }) {
  switch (type) {
    case TOGGLE_ABOUT: {
      return payload ? payload : !state
    }
    default:
      return state
  }
}
