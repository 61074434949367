import React, { PureComponent } from 'react'
import styled from '@emotion/styled'
import { Icon, Tooltip } from 'antd'

const Info = styled(Icon)`
    margin-right:1rem;
    color: ${props => props.theme.darkgrey};
    &:hover {
        color: ${props => props.theme.black};
    }
`
class FormLabelResult extends PureComponent {
    render() {
        const { info } = this.props.data
        return (
            <>
                {info && <Tooltip overlayStyle={{ fontSize: '9pt',
                    padding: '9pt' }} placement="bottom" title={info}>
                    <Info type="info-circle" />
                </Tooltip>}
            </>
        )
    }
}
export default FormLabelResult