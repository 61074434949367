import React, { PureComponent } from 'react'
import { Feature, Layer } from 'react-mapbox-gl'
import PropTypes from 'prop-types'
import { Analytics } from 'aws-amplify'

class HubLayer extends PureComponent {

  onMouseEnter = () => this.props.changeCursor(true)
  onMouseLeave = () => this.props.changeCursor(false)
  onClick = (hubID, hubName) => {
    this.props.selectHub(hubID);
    Analytics.record({name: "SelectHub", attributes: { hubName: hubName }});
  }

  render() {
    const { selectedHub, data } = this.props
    const opacity = selectedHub ? 0.7 : 1

    return (
      <Layer
        id="hublayer"
        type="circle"
        paint={{
          'circle-radius': {
            property: 'patronage',
            stops: [[1000, 4], [5000, 6], [20000, 10], [100000, 15]],
          },
          'circle-color': ['get', 'color'],
          'circle-opacity': opacity,
          'circle-stroke-width': 1.5,
          'circle-stroke-color': ['get', 'stroke'],
        }}
      >
        {data &&
          data.map(hub => (
            <Feature
              properties={{ ...hub }}
              key={hub.ID}
              id={hub.ID}
              onMouseEnter={this.onMouseEnter}
              onMouseLeave={this.onMouseLeave}
              onClick={() => this.onClick(hub.ID, hub.SmartRider_StopName)}
              coordinates={[hub.X, hub.Y]}
            />
          ))}
      </Layer>
    )
  }
}

HubLayer.propTypes = {
  selectedHub: PropTypes.object,
  data: PropTypes.arrayOf(PropTypes.object),
  selectHub: PropTypes.func.isRequired,
}

export default HubLayer
