import React, { PureComponent } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import Slider from '../../common/Slider'
import Subtitle from '../../common/Subtitle'

const TravelTimeContainer = styled('div')`
  padding-top: 1rem;
`
const marks = {
  30: '30',
  60: '60',
  90: '90',
  120: '120',
}
class TravelTime extends PureComponent {
  onChange = value => this.props.change(value)

  render() {
    const { time } = this.props
    return (
      <TravelTimeContainer>
        <Subtitle>Travel time (minutes)</Subtitle>
        <Slider
          min={30}
          max={120}
          step={30}
          value={time}
          marks={marks}
          tipFormatter={null}
          defaultValue={time}
          onChange={this.onChange}
        />
      </TravelTimeContainer>
    )
  }
}

TravelTime.propTypes = {
  change: PropTypes.func.isRequired,
}

export default TravelTime
