import { connect } from 'react-redux'
import Employment from '../components/PrintView/Employment'

export default connect(
  state => {
    return {
      employmentCreated: state.scenarioplanner.employmentCreated,
      baseEmployment: state.scenarioplanner.baseEmployment,
      employmentResult: state.scenarioplanner.employmentResult
    }
  },
  {}
)(Employment)
