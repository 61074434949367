import { connect } from 'react-redux'
import Popup from '../components/Layers/Popup'

import {
  selectorShowPopupLayer,
  selectorPopupLayer,
} from '../selectors/contextLayer-selector'
import { selectFeature } from '../actions/context';

export default connect(
  state => {
    return {
      popup: selectorPopupLayer(state),
      showLayer: selectorShowPopupLayer(state),
    }
  },
  {selectFeature}
)(Popup)
