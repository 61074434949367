import { connect } from 'react-redux'
import SelectedHubLayer from '../components/Layers/SelectedHubLayer'

export default connect(
  state => {
    return {
      hub: state.hubs.selectedHub,
      maxPatronage: state.hubs.maxPatronage,
    }
  },
  {}
)(SelectedHubLayer)
