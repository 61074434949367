import React from 'react'
import styled from '@emotion/styled'
import { HeaderTooltip } from '../ModelContainer/CommonComponents'

const Year = styled('div')`
  color: #333333;
  font-size: 12px;
`

const Price = styled('div')`
  color: #ff9505;
  font-size: 22px;
`

const SubColumn = styled('div')`
  display: flex;
  flex-direction: column;
`

const Row = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const MiniTitle = styled('div')`
  color: #b3b3b3;
  font-size: 0.75rem;
  font-style: italic;
`

const commas = x => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export default ({ housePrices }) => {
  return (
    <>
      <HeaderTooltip heading={"Median house price"}
        description={"Source: Landgate."} />
      <Row>
        {housePrices['2017'] && (
          <SubColumn>
            <Year>2017</Year>
            <Price>${commas((housePrices['2017']).toFixed(0))}</Price>
          </SubColumn>
        )}
        {housePrices['2018'] && (
          <SubColumn>
            <Year>2018</Year>
            <Price>${commas((housePrices['2018']).toFixed(0))}</Price>
          </SubColumn>
        )}
        { (housePrices['2017'] === null && housePrices['2018'] === null) ? (
          <MiniTitle>No Data Available</MiniTitle>
        ) : "" }
      </Row>
    </>
  )
}
