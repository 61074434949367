import React from 'react'
import styled from '@emotion/styled'

const Container = styled('div')`
  padding-bottom: 1rem;
`
const Title = styled('div')`
  font-size: 14px;
`
const Info = styled('div')`
  padding-top: 5px;
  font-size: 12px;
`
const StopsContainer = styled('div')`
  display: flex;
  flex-direction: row;
`
const ItemContainer = styled('div')`
  padding-top: 0.75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const ItemText = styled('div')`
  font-size: 10px;
  padding-bottom: 0.25rem;
`
const ItemColor = styled('div')`
  padding: 0.35rem 1.2rem;
`

const LegendItem = ({ value, color }) => {
  return (
    <ItemContainer key={value}>
      <ItemText>{value}</ItemText>
      <ItemColor style={{ backgroundColor: color }} />
    </ItemContainer>
  )
}
const ContextLegend = ({ data }) => {
  const { title, info, items } = data
  return (
    <Container>
      <Title>{title}</Title>
      <StopsContainer>
        {items && items.map(f => LegendItem({ value: f[0], color: f[1] }))}
      </StopsContainer>
      <Info>{info}</Info>
    </Container>
  )
}

export default ContextLegend
