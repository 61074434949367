import { connect } from 'react-redux'
import LGALayer from '../components/ScenarioPlanner/Layers/LGALayer'

import {
  selectorSelectedLGA,
  selectorCenterLGA
} from '../selectors/lgaLayer-selector'

export default connect(
  state => {
    return {
      data: selectorSelectedLGA(state),
      showLayer: true,
      center: selectorCenterLGA(state)
    }
  },
  {}
)(LGALayer)
