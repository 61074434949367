import { combineReducers } from 'redux'

import loading from './loading'
import map from './map'
import modelcontainer from './modelcontainer'
import accessibility from './accessibility'
import hubs from './hubs'
import search from './search'
import behaviour from './behaviour'
import about from './about'
import menu from './menu'
import employment from './employment'
import sa2layer from './sa2layer'
import nodeplace from './nodeplace'
import travelsurvey from './travelsurvey'
import population from './population'
import scenarioplanner from './scenarioplanner'
import lgalayer from './lgalayer'
import costbenefitanalysis from './costbenefitanalysis'
import context from "./context"
import maptoolbox from './maptoolbox'


export default combineReducers({
  loading,
  map,
  modelcontainer,
  accessibility,
  hubs,
  search,
  behaviour,
  about,
  menu,
  employment,
  sa2layer,
  nodeplace,
  travelsurvey,
  population,
  scenarioplanner,
  lgalayer,
  costbenefitanalysis,
  context,
  maptoolbox
})
