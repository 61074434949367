import { Nodeplace } from '../constants/actionTypes'

const {
  GET_NODEPLACE,
  GET_NODEPLACE_SUCCESS,
  GET_NODEPLACE_FAILURE,
  SET_DISTANCE,
  GET_CLUSTERS,
  GET_CLUSTERS_FAILURE,
  GET_CLUSTERS_SUCCESS
} = Nodeplace

export const initialState = {
  results: [],
  fetching: false,
  error: null,
  distance: '800m',
}

export default function(state = initialState, { type, payload }) {
  switch (type) {
    case SET_DISTANCE: {
      return {
        ...state,
        distance: payload,
      }
    }

    case GET_NODEPLACE: {
      return {
        ...state,
        fetching: true,
      }
    }

    case GET_NODEPLACE_SUCCESS: {
      return {
        ...state,
        results: payload,
        fetching: false,
      }
    }

    case GET_NODEPLACE_FAILURE: {
      return {
        ...state,
        fetching: false,
        error: payload,
      }
    }

    case GET_CLUSTERS: {
      return {
        ...state,
        fetching: true,
      }
    }

    case GET_CLUSTERS_SUCCESS: {
      return {
        ...state,
        clusters: payload,
        fetching: false,
      }
    }

    case GET_CLUSTERS_FAILURE: {
      return {
        ...state,
        fetching: false,
        error: payload,
      }
    }
    default:
      return state
  }
}
